import React from "react";
import { LOCALES } from '../../Providers/i18n'
import LeavingWeb from '../../utils/LeavingWeb'
import { ScrollToTop } from '../../utils/Scroll'
import { AppContext } from '../../Providers/context'
import translate from "../../Providers/i18n/Translate"
import ChatbotComponent from '../../Chatbot/ChatbotComponent'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './footer.css';

class FooterComponent extends React.Component {
  static contextType = AppContext
  constructor() {
    super()
    this.state = {
      addClass: false,
      showLeavePopup: false,
      urlAddress: ""

    };
    this.scrollTop = this.scrollTop.bind(this);
    this.isLogedIn = this.isLogedIn.bind(this);
    this.showLeavePopup = this.showLeavePopup.bind(this);
    this.hideLeavePopup = this.hideLeavePopup.bind(this);
  }

  chatpopBtnClick() {
    this.setState({
      chatpopBtnClick: !this.state.chatpopBtnClick
    })
  }

  scrollTop() {
    ScrollToTop();
  }

  isLogedIn() {
    return localStorage.getItem('accessToken')
  }

  showLeavePopup(address) {
    this.setState({ showLeavePopup: true, urlAddress: address })
  }

  hideLeavePopup() {
    this.setState({ showLeavePopup: false, urlAddress: "" })
  }

  render() {
    const handleDragStart = (e) => e.preventDefault();

    // const items = [
    //   <a onClick={() => this.showLeavePopup("https://alameen.gov.ae/")} href="#!"><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/alameen_logo.png" onDragStart={handleDragStart} role="presentation" /></a>,
    //   <a onClick={() => this.showLeavePopup("https://dubaicareers.ae/en/pages/default.aspx")} href="#!"><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Dubai_Careers.png" onDragStart={handleDragStart} role="presentation" /></a>,
    //   <a onClick={() => this.showLeavePopup("https://www.smartdubai.ae/en/home-page")} href="#!"><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Dubai_Digital.png" onDragStart={handleDragStart} role="presentation" /></a>,
    //   <a onClick={() => this.showLeavePopup("https://www.expo2020dubai.com/")} href="#!"><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Dubai_Expo_2020.png" onDragStart={handleDragStart} role="presentation" /></a>,
    //   <a onClick={() => this.showLeavePopup("https://sheikhmohammed.ae/en-us")} href="#!"><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HH-Logo.png" onDragStart={handleDragStart} role="presentation" /></a>,
    //   <a onClick={() => this.showLeavePopup("https://www.mbrmajlis.ae/en/home")} href="#!"><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Mohammed-bin-rashid-smart-majlis.png" onDragStart={handleDragStart} role="presentation" /></a>,
    //   <a onClick={() => this.showLeavePopup("https://u.ae/en#/")} href="#!"><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/new-logo.png" onDragStart={handleDragStart} role="presentation" /></a>,
    // ];


    // let fcPopup = ['fc_Pop'];
    // if (this.state.chatpopBtnClick) {
    //   fcPopup.push('fcOpen');
    // }

    return (
      <div className="container-fluid footer">
        {this.state.showLeavePopup ? <LeavingWeb url={this.state.urlAddress} hide={this.hideLeavePopup} /> : null}

        <footer id="footer">
          <div className="container-fluid f_section_top">
            <div className="footerSection f_section_links">
              {/* <button>
                <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/happiness.svg" alt="Happiness" />
              </button>
              <button >
                <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/suggestions.svg" alt="Suggestions" />
              </button> */}
              {/* <a href="#" aria-label="See all services" title="See all services">
                    <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/allservices.svg" alt="All Services"/>
                    <p>{translate('services')}</p>
                </a> */}
            </div>

            <div className="footerSection f_soc_links">
              {/* <a data-tip data-for='iskanapp' href="#">
                <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/iskanapp.svg" alt="Iskan" />
                <p>{translate('iskanapp')}</p>
              </a> */}
              <a data-tip data-for='whatsapp' href="#" onClick={() => this.showLeavePopup("https://api.whatsapp.com/send/?phone=+97142029444&text=Hello%20Muftah,%20Could%20I%20know%20the%20Housing%20Application%20Status")}>
                <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/whatsapp_black.svg" alt="Whatsapp" />
                <p>{translate('whatsapp')}</p>
              </a>
              <a data-tip data-for='faqLink' href="https://www.mbrhe.gov.ae/en/InformationPolicy/">
                <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/faqs.svg" alt="FAQs" />
                <p>{translate('faq')}</p>
              </a>
              {/* <a data-tip data-for='polls' href="#">
                <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/polls.svg" alt="Polls"/>
              <p>{translate('polls')}</p>
            </a> */}
            </div>

            <div className="footerSection f_con_links">
              <a data-tip data-for='Contact' href="https://www.mbrhe.gov.ae/en/AboutUs/Contact-Us">
                <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mail_foot.svg" alt="Contact Us" />
                <p>{translate('contactus')}</p>
              </a>
              <a data-tip data-for='AIchat' href="#" onClick={() => this.showLeavePopup("https://chat.dubai.ae/?lang=" + (this.context.state.siteLang === LOCALES.ENGLISH ? LOCALES.CHATBOT_EN : LOCALES.CHATBOT_AR) + "&mode=modal&source=mbrhe")}>
                <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/AI.svg" alt="AI" />
              </a>
              <ChatbotComponent />
            </div>
          </div>
          <div className="container-fluid f_section_bottom">
            <div className="footerSection">
              <span>{translate('copyright')}</span>
            </div>
            <div className="footerSection">
              <ul>
                <li>
                  {this.context.state.siteLang === LOCALES.ENGLISH ? <a data-tip data-for='careerLink' href="#!" onClick={() => this.showLeavePopup("https://dubaicareers.ae/en/pages/default.aspx")} >{translate('careers')}</a> : <a data-tip data-for='careerLink' onClick={() => this.showLeavePopup("https://dubaicareers.ae/ar/pages/default.aspx")} >{translate('careers')}</a>}
                </li>
                {/* <li>
                  {this.context.state.siteLang === LOCALES.ENGLISH ? <a data-tip data-for='faqLink' href="https://www.mbrhe.gov.ae/en/FAQs" >{translate('faq')}</a> : <a data-tip data-for='faqLink' href="https://www.mbrhe.gov.ae/ar/FAQs" >{translate('faq')}</a>}
                </li> */}
                <li>
                  {this.context.state.siteLang === LOCALES.ENGLISH ? <a data-tip data-for='informationPolicyLink' href="https://www.mbrhe.gov.ae/en/InformationPolicy/" >{translate('InformationPolicy')}</a> : <a data-tip data-for='informationPolicyLink' href="https://www.mbrhe.gov.ae/ar/InformationPolicy/" >{translate('InformationPolicy')}</a>}
                </li>
                <li>
                  {this.context.state.siteLang === LOCALES.ENGLISH ? <a data-tip data-for='privacypolicyLink' href="https://www.mbrhe.gov.ae/en/Privacy-Policy" >{translate('privacypolicy')}</a> : <a data-tip data-for='privacypolicyLink' href="https://www.mbrhe.gov.ae/ar/Privacy-Policy" >{translate('privacypolicy')}</a>}
                </li>
                <li>
                  {this.context.state.siteLang === LOCALES.ENGLISH ? <a data-tip data-for='sitemapLink' href="https://www.mbrhe.gov.ae/en/Site-Map" >{translate('sitemap')}</a> : <a data-tip data-for='sitemapLink' href="https://www.mbrhe.gov.ae/ar/Site-Map" >{translate('sitemap')}</a>}
                </li>
                <li>
                  {this.context.state.siteLang === LOCALES.ENGLISH ? <a data-tip data-for='termsofuseLink' href="https://www.mbrhe.gov.ae/en/Terms-of-Use" >{translate('termsofuse')}</a> : <a data-tip data-for='termsofuseLink' href="https://www.mbrhe.gov.ae/ar/Terms-of-Use" >{translate('termsofuse')}</a>}
                </li>
              </ul>
            </div>
          </div>
        </footer >


        <div className="chatMainWrapper">
          {/* <ChatbotComponent /> */}
        </div>
      </div >
    );
  }
}

export default FooterComponent;