import { LOCALES } from '../Locales';

export default {
    [LOCALES.ARABIC]: {
        'lang': 'English',
        'nodata': 'لا يوجد اي بيانات',
        /** Customer */
        /* Dashboard */
        'dashboard-lbl': 'لوحة المعلومات',
        'changeServiceRequest': 'تحويل نوع الخدمة من قرض مسكن جاهز الى قرض بناء',
        'cancelApprovedRequest': 'إلغاء موافقة طلب',
        'cancelRequest': 'إلغاء طلب',
        /* Settings */
        'settings-lbl': 'الاعدادات',
        'communication-language': 'لغة التواصل',
        'please-select': 'الرجاء الاختيار',
        'email-notifications': 'استقبال الاشعارات البريد الالكتروني',
        'mobile-notifications': 'استقبال الاشعارات الهاتف المحمول',
        'sms': 'تفعيل الرسائل القصيره عن تسجيل الدخول',
        /* Widgets */
        'service-widget-heading': 'الخدمات',
        'housing-requests-widget-heading': 'الإستمارات الإسكانية',
        'application-status-widget-heading': 'حالة الطلبات',
        'maintenance-request-widget-heading': 'طلب الصيانة',
        'maintenance-insurance-widget-heading': 'تامين الصيانة',
        'contractor-payments-widget-heading': 'دفعات المقاول',
        'contractor-payments-requests-widget-heading': 'طلب دفعات المقاولين',
        'housing-projects-widget-heading': 'مشاريع الاسكانية',
        'financial-status-widget-heading': 'الحالة المالية',
        'manage-booking-widget-heading': 'إدارة الحجز الخاص بك',
        'awareness-widget-heading': 'ارشادات الصيانه',
        /** ContractorPaymentWidgetItem */
        'contractor': 'مقاول: ',
        'contractor-lbl': 'مقاول ',
        'status-lbl': 'الحالة: ',
        'more': 'المزيد ',
        'Thestagesofprojectcompletion': 'مراحل تنفيذ المشروع',
        /** HousingRequestItem */
        'ticket-number': 'رقم التذكرة : ',
        'deleteconfirmation': 'هل أنت متأكد أنك تريد حذف البيانات المدخلة؟',
        /**  Others */
        'error-occured': 'هناك مشكلة في محاولة للوصول إلى الخدمات، الرجاء المحاولة مرة أخرى لاحقاً',
        'show-more': 'أظهر المزيد',
        /**  Footer */
        'copyright': 'حقوق الطبع والنشر © 2024 مؤسسة محمد بن راشد الإسكان - جميع الحقوق محفوظة',
        'maintain': 'تتم إدارة الموقع من قبل مؤسسة محمد بن راشد للاسكان',
        'resolution': 'يفضل تصفح الموقع من خلال شاشة 768 * 1366',
        'sitemap': 'خريطة الموقع',
        'termsofuse': 'شروط الاستخدام',
        'privacypolicy': 'سياسة الخصوصية',
        'accessibility': 'إمكانية الوصول',
        'contactus': 'اتصل بنا',
        'faq': 'الأسئلة الشائعة',
        'ejobs': 'وظائف',
        /** Contractor Payments */
        'id': 'الرقم:',
        'amount': 'المبلغ : ',
        /** To Whom */
        'to-whom-lbl': 'طلب شهادة لمن يهمه الأمر',
        'idbarahno': 'رقم الإضبارة',
        'unifiedno': 'الرقم الموحد',
        'make-new-request': 'طلب جديد',
        /** Loan Calculator */
        'salary': 'الراتب',
        'others': 'مصدر أخر',
        'gauranter': 'دخل المتضامن',
        'personalloan': 'قسط قرض شخصي',
        'employerloan': 'قسط جهة العمل',
        'rent': 'قسط أيجار سكني',
        'carloan': 'قسط سيارة',
        'calculate': 'احتساب',
        'loancalculator-heading': 'احتساب القرض التجريبي',
        'totalSalary': 'الراتب الإجمالي',
        'totalIncome': 'إجمالي الدخل',
        'totalDeductions': 'مجموع الاستقطاعات الشهرية',
        'deductionmsgone': 'مجموع الاستقطاعات الشهرية (الشخصية، التجارية، قرض سيارة، قرض عمل…الخ) مثال: إذا كان عندك قسط سيارة ٢٠٠٠ درهم + قسط قرض شهري ٢٥٠٠ درهم، مجموع الاستقطاعات الشهرية تساوي ٤٥٠٠ درهم',
        'deductionmsgtwo': 'يجب ان لا يتجاوز اجمالي الاستقطاعات الشهرية نسبة ٦٠٪ من مجموع الدخل الشهري',
        'netIncome': 'صافي الدخل',
        'debtPercentage': 'نسبة الديون %',
        'repaymentPeriod': 'فترة سداد',
        'repaymentInMonths': 'السداد في شهور',
        'monthlyInstallments': 'أقساط شهرية',
        'lastInstallmentAmount': 'مبلغ اخر  قسط',
        'debitPerctAfterInstallment': 'الخصم بعد القسط',
        'twoYearsInsurance': 'تامين سنتين',
        'monthlyPremium': 'القسط الشهري',
        'MonthlyInstalment': 'القسط الشهري   رسوم التأمين الشهرية',
        'Disclimer': 'إخلاء مسؤولية',
        'loanmessage': 'توفر مؤسسة محمد بن راشد للإسكان خدمة احتساب القرض التجريبي لعرض القيمة المقدرة في احتساب القرض وفترة السداد والقسط الشهري. هذا لغرض المعرفة فقط. يرجى زيارة المؤسسة للتحقق من دقة القيمة التي تم الحصول عليها باستخدام هذه الحاسبة.',
        /** Maintenance Requests */
        'MaintenanceRequest': 'طلب الصيانة',
        'requestNo': 'رقم الطلب',
        'requestDate': 'تاريخ الطلب',
        'AppointmentDate': 'تاريخ الموعد',
        'problemType': 'نوع المشكلة',
        'problemRemarks': 'ملاحظات المشكلة',
        'problemDescription': 'وصف الخدمة',
        'endDate': 'تاريخ الانتهاء',
        'contractorName': 'اسم المقاول',
        'contractorRemarks': 'ملاحظات المقاول',
        'requestRelativeDate': 'التاريخ المرتبط بالطلب',
        /** Maintenance Insurance */
        'ContactPersonName': 'اسم المسؤول',
        'supplier': 'مورد',
        'granteePeriod': 'فترة الضمان',
        /** Maintenance Requests Service*/
        'Category': 'التصنيف',
        'Selectfiles': 'اختر الملف',
        'requiredErrorMessage': 'جميع الحقول مع علامة (*) مطلوبة, الرجاء تعبئتها بالقيم الصحيحة',
        'MaintenanceDescription': 'وصف الصيانة',
        'MaintenanceNote1': 'يمكن للمستخدم تقديم طلب صيانة فيلا أكثر من مرة.',
        'MaintenanceNote2': 'في حالة انقطاع كامل للمياه والكهرباء، يرجى الاتصال بقسم خدمات منزلية 050-7030308',
        /** Construction Cost Calculator */
        'ConstructionCalculator': 'حاسبة تكلفة البناء',
        'Budget': 'الميزانية',
        'Area': 'مساحة الأرض',
        'AverageCost': 'متوسط التكلفة',
        'OutDoorConstructionCost': 'تكلفة البناء الخارجي',
        'EstimatedBuildupArea': 'تقدير مساحة البناء',
        /** Profile */
        'POBox': 'صندوق البريد',
        /** Notifications */
        'Notifications': 'استقبال الاشعارات',
        'Review': 'استعراض',
        'Delete': 'حذف',
        'Logout': 'تسجيل خروج',
        /** New Housing Service Request */
        'newHousingRequest': 'التقديم على طلب اسكاني جديد',
        'PersonInformation': 'معلومات شخصية',
        'NationalID': 'رقم الهوية',
        'PrimaryMobileNo': 'رقم الهاتف الأساسي',
        'SecondaryMobileNo': 'رقم هاتف اّخر',
        'PassportNo': 'رقم الجواز',
        'IsFromHomatAlWatan': 'هل أنت من حماة الوطن في القوات المسلحة؟',
        'isApplyingForSecondWife': 'هل تقدم الطلب للزوجة الثانية؟',
        'IsFromSpecialNeeds': 'هل انت من اصحاب الهمم؟',
        'SocialStatus': 'الحالة الاجتماعية',
        'Continue': 'استمرار',
        'IAcceptingThe': 'انا اوافق على الشروط و الاحكام',
        'IAgreeToTheWebsite': 'I agree to the MBRHE website',
        'Elligibleservices': 'الخدمات المتاحة',
        'TermsOfUse': 'شروط الاستخدام',
        'Agree': 'موافق',
        'SpecialNeedType': 'الرجاء قم باختيار النوع',
        /** Early Settlement */
        'EarlySettlement': 'السداد المبكر',
        /** New Housing Service Request */
        'StepsHousingServiceRequest': 'خطوات طلب خدمة اسكان',
        'HousingRequest': 'طلب اسكاني',
        'clicktoadd': 'انقر لإضافة طلب جديد',
        'selectedService': 'نوع الخدمة المختارة',
        'changeServiceType': 'تغيير نوع الخدمة',
        'selecteService': 'حدد نوع الخدمة',
        'DidyouhaveChildcustody': 'هل لديك حضانه الأطفال ؟',
        'deleterequestconfirm': 'هل أنت متأكد أنك تريد حذف ؟',
        'selectservice': 'يرجى تحديد خدمة للمتابعة',
        'eligiblenote': 'اختيارك لاي خدمة من الخدمات الاسكانية لا يعني أنك مؤهل للحصول على هذه الخدمة.',
        'eligibleterms': 'أقر بأن جميع البيانات المسجلة في طلب الخدمة الإسكانية وكذلك المستندات الدالة عليها صحيحة ولمؤسسة محمد من راشد للإسكان الحق بمطالبتي بأية مستندات إضافية أو الإفصاح عن المعلومات قد تطلب لاحقاً، واتعهد بإبلاغ المؤسسة بأية تغييرات قد تطرأ على حالتي الإجتماعية أو المالية، وفي حال ثبوت عدم صحة البيانات والمستندات أو عدم التزامي بذلك فإن للمؤسسة الحق في إتخاذ الإجراءات المناسبة ضدي حسب الأنظمة والقوانين المتبعة، كما يحق للمؤسسة الغاء الطلب.',
        'eligibleInfoIconMsg': 'لمعرفة المزيد عن تفاصيل الخدمة, كذلك الصفات المؤهّلة والوثائق المطلوبة اضغط هنا ',
        /** Person Information */
        'PersonalInf': 'معلومات شخصية',
        'TownNo': 'رقم البلدة',
        'KholasaID': 'رقم الخلاصة',
        'IdbarahNo': 'رقم الإضبارة',
        'FamilyNo': 'رقم العائلة',
        'spouseName': 'اسم الزوج',
        'SpouseSalary': 'راتب الزوج',
        'RelationwithSpouse': 'صفة الزوج',
        'WeddingDate': 'تاريخ الزواج',
        'NoOfFamilyMembers': 'عدد افراد العائلة',
        'SpouseIdbarah': 'رقم اضباره الزوج',
        'IsyourspousealocalUAEcitizen': 'هل الزوجه مواطنة؟',
        'IsyourspousealocalUAEcitizenWife': 'هل الزوج مواطن؟',
        /** Employement Information */
        'Employment': 'البيانات الوظيفة',
        'JobStatus': 'الحالة الوظيفية',
        'grantor': 'الجهة المانحة',
        'governmentSupportTypeId': 'نوع الدعم الحكومي',
        'hasgovernmentsupport': 'هل لديك دعم حكومي؟',
        'govtsupportamount': 'مبلغ دعم الحكومه',
        'currentemployer': 'جهة العمل',
        'OfficePhoneNo': 'هاتف العمل',
        'JobTitle': 'المسمى الوظيفي',
        'OfficePOBox': 'رقم صندوق البريد',
        'JobEmirate': 'إمارة العمل',
        /** Finance Information */
        'FinanceInformation': 'معلومات مالية',
        'SocialAffairsIncome': 'دخل الشؤون الاجتماعية',
        'MonthlyDeductions': 'الخصومات الشهريه',
        'MonthlyRent': 'الاجار الشهري',
        'FinanceAmount': 'المقدرة المالية',
        'FinanceSource': 'مصدر التمويل',
        /** Documents */
        'Documents': 'المستندات',
        'uploaddocumentmessage': 'في هذه المرحلة يتم حفظ طلبك لمزيد من التحديثات، يرجى مراجعة الطلب قبل إكمال الطلب. بمجرد النقر على "إكمال الطلب" لا يمكنك تحرير هذا الطلب.',
        'uploaddocumentsignedmessage': 'يجب توقيع جميع المستندات المرفقة وختمها من قبل جميع الأطراف ذات الصلة.',
        'ResendPinCode': 'اعادة إرسال رمز التفعيل',
        'PleaseenteryourOTP': 'الرجاء ادخال الرمز السري المرسل اليكم عبر الموبايل',
        'docdeletesuccess': 'تم حذف المستند بنجاح.',
        'Obligationtobuild': 'تعهد بالبناء خلال المدة محددة',
        'ObligationtobuildBody': ' أتعهد أنا الموقع أدناه، بأن ألتزم بمباشرة البناء على الأرض السكنية الممنوحة لي خلال ثلاث سنوات من تاريخ حصولي عليها، كما أقر بأني على علم ودراية كاملة بأن للجهة المختصة الحق في سحب الأرض وتخصيصها لمستفيد آخر في حال لم ألتزم بالمدة المذكورة أعلاه، وأنه لا يحق لي التقدم بطلب منحة أرض جديدة إلا بعد فوات ثلاث سنوات من تاريخ سحب الأرض السابقة.',
        'documentobligationnote': ' في حالة رغبتكم بالتقدم على خدمة قرض بناء من المؤسسة مستقبلاً وللحصول على الموافقة يجب التقيد بشروط المؤسسة والمحددة بأن لا تزيد مساحة البناء الاجمالية عن 8,000 قدم مربع للفلا والملاحق الخارجية.',
        /** Input Error Messages */
        'emptyvalidation': 'الحقل مطلوب',
        'numberValidation': 'الحقل يجب ان يكون رقم',
        'emailValidation': 'البريد الاكتروني غير صحيح',
        'mobileValidation': 'رقم الهاتف غير صحيح',
        'eidValidation': 'رقم الهوية المدخل غير صحيح',
        /** Link Account */
        'LinkYourAccountWithMBRHE': 'ربط هويتي مع الطلب الاسكاني',
        'MyIDLinkAccountMessage': 'إذا كان لديك طلبات سابقة معنا، يرجى ربط حسابك، حتى تتمكن من الوصول الى هذه الطلبات.',
        'LinkYourAccountNow': 'اربط حسابك الان',
        'MyIDLinkAccount': 'ربط الحساب',
        'IsBenefitingMRHE': 'هل انت مستفيد من خدمات المؤسسة؟',
        'yes': 'نعم',
        'no': 'لا',
        'EnterPinCode': 'ادخل رمز التفعيل',
        'EnterApplicationNoAndServiceType': 'أدخل رقم طلب السكن الخاص بك ونوع الخدمات',
        'acceptterms': 'يجب عليك الموافقة على الشروط والاحكام',
        'nolinkingtoken': 'No linking token',
        'linksuccess': 'تم ربط حسابك بنجاح',
        /** House Booking */
        'HouseBooking': 'حجز المساكن',
        'NumberofFloors': 'طابق',
        'NumberofBedrooms': 'عدد غرف النوم',
        'Formoreinfo': 'لمزيد من التوضيح حول رحلة العميل لحجز المسكن الخاص به, الرجاء الضغط على',
        'clickhere': 'الرابط التالي!',
        'All': 'الكل',
        'Style': 'الواجهة',
        'CalculateTheValueOfTheHouse': 'إحتساب قيمة المسكن',
        'PriceAllocation': 'المبلغ الإجمالي',
        'BookingPrice': 'الدفعة المبدئية',
        'RemainingAmount': 'المبلغ المتبقي على المتعامل',
        'TermsConditions': 'الشروط و الاحكام',
        'Viewdetails': 'عرض التفاصيل',
        'Viewdesigns': 'عرض التصاميم',
        'NumberofUnitsAvailable': 'عدد المساكن المتاحة:',
        /** Old Booking */
        'Landarea': 'مساحة الارض',
        'BuildingArea': 'مساحة البناء',
        'LandNo': 'رقم قطعة الأرض',
        'VillaNo': 'رقم الفيلا',
        'Zone': 'المنطقة',
        'projectName': 'اسم المشروع',
        'BookedDate': 'تاريخ الحجز',
        'BookingExpiryDate': 'تاريخ انتهاء',
        'Print': 'طباعة',
        'exceedbooking': 'عفوا، لقد استنفذت عدد المحاولات المسموحة للحجز',
        'BookingReferenceNumber': 'الرقم المرجعي للحجز',
        'directions': 'الاتجاه',
        'yourBookingDetails': 'تفاصيل الحجز الخاص بك',
        'Message': 'Message',
        /** Related Houses */
        'RelatedHouses': 'تفاصيل المنازل ذات الصلة',
        'RelatedHousesPayment': 'الدفعة',
        'housedetails': 'تفاصيل المسكن',
        'mybookings': 'حجزي',
        'PurchaseReadyhouse': 'شراء مسكن جاهز',
        '3DView': 'موذج ثلاثي الابعاد',
        'Locationonthemap': 'الموقع على الخريطة',
        'Price': 'السعر',
        'OrderBy': 'الترتيب حسب',
        'confirm_booking': 'تأكيد الحجز',
        'booking_details': 'تفاصيل الحجز',
        'cash_deposit': 'الإيداع النقدي في المؤسسة',
        'pay_by_E_Payment': 'الدفع الإلكتروني',
        'booking_payment_heading': 'الإيداع النقدي لدى مؤسسة محمد بن راشد للإسكان.',
        'booking_fee_heading': 'رسوم حجز المسكن ',
        'booking_fee_note1': 'لديك يومان عمل لإيداع مبلغ ',
        'booking_fee_note11': ' في موقع المؤسسة كمبلغ لتأكيد حجزك لمدة شهر واحد. يرجى العلم في حالة التأخر في الدفع سيتم إلغاء الحجز الخاص بك تلقائيا.',
        'booking_note': 'تنبيه! الرجاء العلم بأن لديك محاولة واحدة فقط لحجز مسكن عن طريق الموقع',
        'booking_note_two': 'تنبيه! الرجاء العلم بأن لديك محاولة واحدة فقط لحجز مسكن عن طريق الموقع, ولا يمكن الغاء هذا الحجز بعد استكمال عملية الدفع الإلكتروني',
        'calculate_house_amount': 'Calculate the value of the house',
        'choose_payment_method': 'اختيار طريقة الدفع',
        'price_allocation': 'المبلغ الإجمالي',
        'booking_price': 'الدفعة المبدئية',
        'loan_amount': 'القرض',
        'remaining_amount': 'المبلغ المتبقي على المتعامل',
        'dirham': 'درهم',
        /** Contractor Payments Requests*/
        'Reject': 'رفض',
        'Accept': 'موافقة',
        /** Financial Status */
        'overduepayment': 'طلب السداد المتأخر',
        'ApplicationName': 'اسم الطلب',
        'ApplicationNumber': 'رقم الطلب',
        'startingBalance': 'الرصيد الابتدائي',
        'DateofLastBill': 'تاريخ اخر فاتورة',
        'RemainingLoan': 'القيمة المتبقية من القرض',
        'MaturityDate': 'تاريخ الاستحقاق',
        'MonthlyPayment': 'القسط الشهري',
        'NextPaymentDate': 'تاريخ الدفعة القادمة',
        'OverdueAmount': 'الدفعة المستحقة',
        'Discount': 'قيمة الخصم',
        /** Early Settlement */
        'EarlySettlementHeading': 'السداد المبكر',
        'settlement_term1': 'أن يتم سداد الرصيد المتبقي من القرض بعد خصم الإعفاء المقرر وذلك دفعة واحدة.',
        'settlement_term2': 'أن يكون المقترض قد أنجز بناء المسكن أو تملك المسكن الذي حصل على القرض لأجله.',
        'settlement_term3': 'ألا يزيد مبلغ الإعفاء (300,000) ثلاثمائة ألف درھم.',
        'settlement_term4': 'في حالة الموافقة على طلب السداد المبكر سيتم طلب سداد قيمة القرض بعد الخصم حسب ما ھو منصوص عليه في القانون رقم (22) لسنة (2011).',
        'settlement_term5': 'الطلب صالح لمدة أسبوع من تاريخ التقديم.',
        'conditions': 'الشروط:',
        'requireddocs': 'الوثائق المطلوبة:',
        'doc1': 'شھادة إنجاز المسكن معتمدة من بلدية دبي في حالة إذا كان القرض بناء.',
        'doc2': 'شھادة استلام مسكن + خارطة المسكن محولة بإسم المستفيد في حالة إذا كان القرض لمسكن جاھز.',
        'Loanfilenumber': 'رقم القرض',
        'Discountpercentage': 'نسبة الخصم %',
        'Discountedamount': 'قيمة الخصم',
        'Earlypaymentamount': 'قيمة السداد المبكر',
        'Applicationnumber': 'رقم الطلب',
        'Remainingmonths': 'الأشهر المتبقية',
        'Amounttopay': 'القرض المتبقي',
        'Completioncertificate': ' شھادة إنجاز المسكن معتمدة',
        'ApplicationsStatus': 'حالة الطلب',
        'ApplicationStatus': 'حالة الطلب',
        /** Oevr Due Payment */
        'Overduepayment': 'السداد المتأخر',
        'mPayAccount': 'حساب الدفع',
        'Epayment': 'الدفع الإلكتروني',
        'Amount': "المبلغ",
        'Epayment_message': 'بوابة الدفع الإلكتروني المركزية لحكومة دبي الذكية تمكن العملاء لتسوية رسوم الخدمات الحكومية عبر الإنترنت على مدار الساعة طوال أيام الأسبوع في بيئة آمنة..',
        /** Captcha */
        'click_captcha': 'الرجاء ادخال الصورة',
        'enter_captcha': 'اكتب الاجابة حسب ما تسمع. كلمات او حروف: ',
        'invalid_picture': 'الرجاء اختيار الصورة للمتابعة',
        /** Contractor */
        'contractorProjects': 'ملخص مشاريع المقاول',
        'services': 'الخدمات',
        'retentionPayment': 'محجوزات الصيانة',
        'contractorProjectPayment': 'طلب دفعة للمشروع',
        'openNewProjectFile': 'طلب فتح ملف مشروع',
        'constructionLoanProjects': 'مشاريع قروض البناء',
        'fileNumber': 'رقم الملف',
        'ownerName': 'اسم المالك',
        'contractAmount': 'قيمة العقد',
        'currentPercentCompleted': 'نسبة الإنجاز الحالية %',
        'status': 'الحالة : ',
        'serviceType': 'نوع الخدمة',
        'paymentDate': 'تاريخ الدفعة',
        'totalBillAmount': 'قيمة الدفعة',
        'paymentToOwner': 'الدفع للمالك',
        'paymentToContractor': 'الدفع للمقاول',
        'billRetention': 'محجوزات الدفعة',
        'maintenanceGrantProject': 'مشروع منحة الصيانة',
        'projectSummary': 'ملخص نسبة انجاز المشاريع',
        'financialStatus': 'الحالة المالية',
        'dashboard': 'لوحة المعلومات',
        'projectFileRequests': 'طلبات ملف المشروع',
        'projectPaymentRequests': 'طلبات دفع المشروع',
        'openNewProjectFileConstructionloan': 'طلب فتح ملف مشروع قرض بناء',
        'contractorProjectPaymentConstructionloan': 'طلبات دفعات لمشاريع قروض البناء',
        'retentionPaymentconstructionloan': 'محجوزات الصيانة لمشاريع قروض البناء',
        'applicationNo': 'رقم الطلب',
        'retentionPaymentRequest': 'طلبات محجوزات الصيانة',
        'applicationDate': 'تاريخ الطلب',
        'modify': ' تعديل',
        'openNewProjectFileMaintenanceGrant': 'طلب فتح ملف مشروع منح صيانة',
        'contractorProjectPaymentMaintenanceGrant': 'طلبات دفعات لمشاريع منح الصيانة',
        'retentionPaymentmaintenancegrant': 'محجوزات الصيانة لمشاريع منح الصيانة',
        'noData': 'لا يوجد اي بيانات',
        'srNo': 'رقم التسلسل',
        'descriptionOfwork': 'وصف العمل',
        'contractPercent': 'نسبة العقد %',
        'viewComment': 'الملاحظات',
        'addComment': 'اضافة تعليق',
        'totalPercentage': 'اجمالي النسبة',
        'projectInformation': 'معلومات المشروع',
        'contractNo': 'رقم العقد',
        'contractStatus': 'حالة العقد',
        'ownerShare': 'حصة المالك',
        'breakDownInformation': 'جدول نسب الأعمال',
        'requiredDoc': 'الوثائق المطلوبة',
        'mobilisation': 'أعمال التحضيرات',
        'excavationBFilling': 'أعمال الحفر والدفان',
        'subStructure': 'أعمال الأساسات',
        'superStructure': 'أعمال الخرسانة',
        'blockWork': 'أعمال الطابوق',
        'plasterWork': 'أعمال البلاستر',
        'electricWork': 'الأعمال الكهربائية',
        'plumbingWork': 'أعمال التمديدات الصحية الداخلية',
        'plumbingWorkExt': 'أعمال التمديدات الصحية الخارجية',
        'internalFinishes': 'أعمال التشطيبات الداخلية',
        'externalFinishes': 'أعمال التشطيبات الخارجية',
        'waterProofing': 'أعمال الطبقات العازلة',
        'aluminiumWorks': 'أعمال الالمنيوم',
        'gatesMetalWorks': 'البوابات والأعمال المعدنية',
        'joineryWork': 'الأعمال الخشبية',
        'airConditioningWork': 'أعمال التكييف',
        'cleaning': 'أعمال النظافة والتسليم الابتدائي',
        'grantBoq': 'جدول كميات المنح',
        'totalPaymentPercentage': 'يجب أن تكون نسبة الإجمالية تساوي %100',
        'mobSiteOfficeServices': 'تجهيز الموقع/ مكتب /توصيل الخدمات',
        'excavation': 'الحفر',
        'BackFilling': 'الدفان',
        'villa': 'الفيلا',
        'villaCompundWall': 'السور',
        'VillaServantBlock': 'الملاحق',
        'villaGfSlab': 'أعمدة وسقف الطابق الأرضي',
        'villaFfSlab': 'أعمدة وسقف الطابق الأول',
        'compoundWall': 'بلاستر السور',
        'servantBlock1': 'الملاحق',
        'servantBlock': 'البلاستر الداخلي والخارجي للملاحق',
        'solidBlockVillaCw': 'الطابوق المصمت للفيلا والسور والملاحق',
        'villaGfWalls': 'الطابق الأرضي للفيل',
        'villaFfWalls': 'الطابق الأول للفيلا',
        'villaInteralPlaster': 'البلاستر الداخلي للفيلا',
        'villaExternalPlaster': 'البلاستر الخارجي للفيلا',
        'conduits': 'المواسير الكهربائية',
        'wiring': 'الأسلاك والكابلات',
        'accessories': 'لوحات الكهرباء والمفاتيح و...الخ',
        'lightFittingsFans': 'المعلقات الكهربائية والمراوح',
        'waterSupplyPipes': 'تمديدات تغذية المياه الداخلية',
        'drainagePipes': 'تمديدات الصرف الصحي',
        'sanitaryWaresWaterHeaters': 'الأطقم الصحية وسخانات المياه',
        'drainagePipesManholes': 'أعمال الصرف الخارجي وغرف التفتيش',
        'waterTanksPumps': 'خزانات المياه والمضخات والتمديدات',
        'septicTankAndSoakaway': 'المرشح وخزان التحليل',
        'floorTiles': '(الأرضيات (جميع أنواعها',
        'wallTiles': 'جدران الحمامات والمطابخ',
        'paints': 'الدهانات الداخلية',
        'marble': 'الرخام (السلالم + أعتاب الأبواب)',
        'RoofClayTiles': 'أعمال القرميد',
        'falseCeiling': '(الأسقف المستعارة (الحمامات + المطابخ',
        'compoundWallPaints': 'الدهانات الخارجية للسور',
        'interlock': 'الأنترلوك',
        'footingsVilla': 'عزل أساسات الفيلا والسور والملاحق',
        'roof': 'الطبقات العازلة للفيلا والملاحق',
        'bathrooms': 'عزل الحمامات',
        'doors': 'الأبواب',
        'windows': 'الشبابيك',
        'handrail': 'الدرابزين',
        'gates': 'بوابات السور',
        'spiralStair': 'السلم الحلزوني',
        'CarShed': 'مظلة السيارات',
        'kitchenCabinetsWardrobes': 'خزائن المطبخ وخزائن الملابس',
        'Pergola': 'البرجولا',
        'machinesUnit': 'ماكينات التكييف',
        'ductsDiffusers': 'دكتات التكييف ومخارج الهواء',
        'CivilWork': 'أعمال مدنية',
        'cleaningLevelingHandingOver': 'أعمال تنظيف الموقع والتسليم للبلدية',
        'processSuccess': 'اكتملت العملية بنجاح',
        'atThisStageApplication': 'في هذه المرحلة يتم حفظ طلبك لمزيد من التحديثات، يرجى مراجعة الطلب قبل إكمال الطلب. بمجرد النقر على "إكمال الطلب" لا يمكنك تحرير هذا الطلب.',
        'allAttachedDoc': 'يجب أن تكون جميع المستندات المرفقة موقعة ومختومة من جميع الاطراف',
        'viewModify': 'عرض / تعديل',
        'edit': 'تعديل',
        'delete': 'حذف',
        'view': 'عرض',
        'finish': 'إكمال الطلب',
        'documentCount': 'عدد المستندات المرفقه',
        'add': 'اضافة',
        'next': 'التالي',
        'previous': 'السابق',
        'fileSize': 'يجب ان لا يتجاوز حجم الملف/ الملفات المراد ارفاقها (تحميلها) 4 ميغابايت. الملفات المسموح ارفاقها pdf, jpg, jpeg,png فقط',
        'customerLogin': 'تسجيل دخول المتعاملين',
        'contractorLogin': 'تسجيل دخول المقاولين',
        'toApplyLoanGrant': 'للتقديم على خدمات المنح والقروض او الخدمات المالية',
        'toOpenNewFile': 'لفتح ملف او التقديم على الدفعات',
        'loginWith': 'تسجيل الدخول بالهوية الرقمية',
        'uaePass': 'الهوية الرقمية',
        'or': 'أو',
        'loginWithEmiratesId': 'لدي ملف اسكاني',
        'loginWithEmiratesIdText': 'الدخول بإستخدام الهوية الإماراتية سيمكن المستخدم من الدخول بواسطة كلمة المرور المؤقتة التي سيتم ارسالها لرقم الهاتف المرتبط برقم الهوية المدخل فقط في حال وجود ملف اسكاني سابق للمستخدم. **كلمة المرور المؤقتة صالحة لمدة 5 دقائق',
        'back': 'عودة',
        'emiratesId': 'الهوية الاماراتية',
        'requiredField': 'الحقل مطلوب',
        'submit': 'إرسال',
        'signIn': 'تسجيل الدخول',
        'loginByOtp': 'كلمة المرور لمرة واحدة',
        'pwd': 'كلمة المرور',
        'userName': 'اسم المستخدم',
        'rememberMe': 'تذكر اسم المستخدم',
        'mobileNo': 'رقم الموبايل',
        'loginOtpText': 'هذا يستخدم لتسجيل الدخول OTP (كلمة مرور المرة الواحدة) التي يرسلها إلى SMS الخاص بك ، استخدم كلمة المرور هذه للمصادقة. ستكون كلمة المرور هذه صالحة لمدة 5 دقائق ، يرجى تغيير كلمة المرور بمجرد تسجيل الدخول',
        'totalPercent': 'اجمالي النسبة',
        'approvedPercent': 'النسبة المنجزة مسبقا %',
        'totalPaidAmount': 'إجمالي المبلغ المدفوع',
        'sendAmountOwner': 'هل تريد أن ترسل دفعة للمالك؟',
        'kindlySpecify': 'يرجى تحديد المبلغ الذي سيتم دفعه للمالك، إذا لم يكن هناك حصة للمالك، يرجى إلغاء تحديد حصة المالك',
        'lessThanEqualContractPer': 'ملحوظة! يجب أن تكون القيمة أقل من أو تساوي النسبة المئوية للمقابل',
        'lessThanEqualActualDone': 'ملحوظة! يجب أن تكون القيمة أكبر من النسبة المئوية الفعلية أو تساويها',
        'totalPercentageMsg': 'يجب أن تكون النسبة الإجمالية للدفع في الطلب أكبر من أو تساوي 10% من قيمة القرض',
        'grantPaymentMsg': 'قيمة الدفعة يجب ان تكون اكبر من قيمة العمل المنجز',
        'actual%Done': 'العمل المنجز %',
        'payment': 'الدفع %',
        'done': 'العمل المنجز %',
        'kitchenCabinaets': 'خزائن المطبخ وخزائن الملابس',
        'siteLeveling': 'أعمال تنظيف الموقع والتسليم للبلدية',
        'note': 'ملحوظة! ',
        'retentionAmount': 'كمية المحجوزات',
        'profile': 'الملف الشخصي',
        'name': 'الاسم',
        'companyName': 'إسم الشركة',
        'email': 'البريد الالكتروني',
        'bankName': 'اسم البنك',
        'accountNo': 'رقم البنك الدولي -IBAN',
        'emirate': 'الامارة',
        'contactPerson': 'اسم المسؤول',
        'mobileNumber': 'رقم الموبايل',
        'officePhone': 'هاتف العمل',
        'faxNo': 'رقم الفاكس',
        'address': 'العنوان',
        'pOBox': 'صندوق البريد',
        'changePwd': 'تغيير كلمة المرور',
        'save': 'حفظ',
        'newPwd': 'كلمة سر جديدة',
        'confPwd': 'تاكيد كلمة المرور',
        'update': 'تحديث',
        'cancle': 'الغاء',
        'passwordarenotsame': '.كلمة المرور والتأكيد كلمة المرور الجديدة لا تتطابق',
        'dragAndDrop': 'اختر ملف / سحب وإسقاط',
        'cancleAuthentication': 'تم الغاء طلب تسجيل الدخول',
        'kioskLocation': 'مواقع الاكشاك',
        'iWilldolater': 'سأكمل لاحقاً',
        'ok': 'حسنا',
        'photoStage': 'صور للمرحلة الحالية',
        'attachmentImage': 'الصور المرفقة',
        'viewImage': 'عرض الصور',
        'notCompleted': 'غير مكتمل',
        'completedWaiting': 'مكتملة - لاعتماد المالك',
        'completedDocumentsChecking': 'مكتملة - تدقيق على المستندات',
        'expiredNotReceived': 'انتهت الصلاحية - لم يتم استلام موافقة المالك',
        'documentsReceived': 'تم استلام المستندات',
        'new': 'طلب جديد',
        'approved': 'موافقة نهائية',
        'onHold': 'في الانتظار',
        'inProgress': 'قيد الاجراء',
        'preApproval': 'مواقفة مبدئية',
        'received': 'جديد',
        'rejected': 'مرفوض',
        'suspended': 'معلق',
        //ChatBot Translation
        'talk_with_moftah': ' تحدث مع مفتاح',
        'chat_with_whatsapp': 'تحدث مع مفتاح (واتس اب)',
        'our_location': 'موقعنا',
        'call_us': ' اتصل بنا',
        'chatbotWelcomeMsg1': 'مرحبا! اسمي مفتاح',
        'chatbotWelcomeMsg2': 'مساعدك الافتراضي من مؤسسة محمد بن راشد للإسكان أنا هنا لأجيب عن أسئلتك حول الخدمات الإسكانية.',
        'letsChat': "دعنا نتحدث",
        'chatbot_heading': "مساعدك الافتراضي - مؤسسة محمد بن راشد للإسكان",
        'cahtbot_back': "عودة",
        'cahtbot_refresh': "ابدأ من جديد",
        'cahtbot_chage_lang': "English",
        'cahtbot_top': "أذهب للأعلى",
        'cahtbot_bottom': "أذهب للأسفل",
        'cahtbot_name': "مفتاح",
        'cahtbot_customer_name': "زائر",
        'cahtbot_invlaid_selection': 'الرجاء الاختيار احد الخيارات المطروحة التالية:',
        'cahtbot_continue_search': 'او واصل البحث باستخدام القيمة المدخلة! مع العلم بأنك ستوقف طلب الخدمة الحالي!',
        'back_to_main': "قائمة الخدمات الرئيسية",
        'applucationStatus_FileNo': 'رقم الملف',
        'applucationStatus_Status': 'الحالة',
        'applucationStatus_ServiceType': 'اسم الخدمات',
        'HousingStatus_ReqNo': 'رقم الطلب',
        'HousingStatus_ReqDate': 'تاريخ الطلب',
        'HousingStatus_AppointmentDate': 'تاريخ الموعد',
        'PDF_ViewFull': "مشاهدة  في وضع ملء الشاشة",
        'PDF_Download': "تحميل",
        "agent": "موظف خدمة العملاء",
        'payment_id': 'رقم الدفعة',
        "payment_current_percent_completed": 'نسبة الإنجاز',
        'payment_total_bill_amount': 'قيمة الدفعة',
        'payment_status': 'حالة الدفعة',
        'theSatgesOfProject': 'مراحل تنفيذ المشروع (رسم بياني)/مراحل تنفيذ المشروع (جدول)',
        'noPaymentsAvailable': 'المدفوعات غير متوفرة',
        'pleaseUploadDoc': 'الرجاء إرفاق الملفات المطلوبة.',
        "contactInformation": " بيانات الاتصال",
        'ContactInfoText1': " قبل بدء محادثتنا ، أود التعرف عليك بشكل أفضل.",
        'ContactInfoText2': " من فضلك, هل يمكنك ملء النموذج التالي",
        'ContactInfoEmail': "البريد الإلكتروني (اختياري)",
        'grantAmount': 'قيمة المنحة',
        'viewBOQComment': 'عرض الملاحظات',
        'boqSummary': 'ملخص جدول الكميات',
        'viewBOQAndPrint': 'عرض وطباعة ملخص جدول الكميات',
        'remarks': 'ملاحظات هندسية',
        'breakDown': 'جدول الكميات',
        'mrheFileNo': 'رقم الملف',
        'date': 'التاريخ',
        'villaFor': 'الفيلا للسيد/السيدة',
        'contValue': 'قيمة العقد',
        'consultant': 'الاستشاري',
        'ownerSign': 'توقيع المالك',
        'consultantSign': 'توقيع وختم الاستشاري',
        'contractorSign': 'توقيع وختم المقاول',
        'ownerScope': 'جدول أعمال للمالك',
        'savedSucc': 'تم حفظ البيانات بنجاح',
        'saveAndClose': 'احفظ وأغلق',
        'estimatedValue': 'القيمة بالدرهم',
        'airConditioning': 'نظام تكييف الهواء',
        'lightFitiing': 'تجهيزات الإضاءة',
        'kitchenCab': 'خزائن المطبخ',
        'wardrobes': 'خزائن',
        'internalWooden': 'أبواب خشبية داخلية (أوراق)',
        'antenna': 'هوائي',
        'interncom': 'نظام الاتصال الداخلي',
        'falseCelling': 'سقف معلق',
        'carShed': 'سقيفة السيارات',
        'sweetSoil': 'التربة القلوية',
        'leveling': 'التسوية',
        'water&Electricity': 'توصيلات المياه والكهرباء',
        'clear': 'مسح',
        'oops': 'عذرا',
        'gobacktodashboard': 'الرجوع إلى الصفحة الرئيسية',
        'pagenotfound': 'عذرًا ، تلك الصفحة غير موجودة !',
        'maxlength': 'تم تجاوز الحد الأقصى للأرقام المسموح بها',
        'total': 'مجموع:',
        'totalValue': 'مجموع',
        'printOwnerScope': 'عرض الملخص وطباعة نطاق عمل المالك',
        'ownerScopeSummary': 'نطاق عمل المالك',
        'dhs': 'درهم',
        'mostusedservices': 'استعراض كل الخدمات',
        // uaeSignature
        'myInbox': 'صندوق الوارد الخاص بي',
        'addDoc': 'اضافة مستند',
        'uploadedDoc': 'المستندات المحملة',
        'noDoc': 'ليس لديك أي مستندات',
        'docDetail': 'تفاصيل المستند',
        'docType': 'نوع المستند',
        'docValidity': 'مدة الصلاحية',
        'docName': 'اسم المستند',
        'docDesc': 'تفاصيل المستند',
        'validationLimit': 'يجب ان لا يتجاوز الاسم عن ٢٥ حرفا. لا يجوز استخدام الرموز الخاصة',
        'choseFile': 'اختر ملف',
        'uploadDocument': 'تحميل المستند',
        'documentsList': 'قائمة المستندات',
        'fileTypePdf': 'يجب ان لا يتجاوز حجم الملف/ الملفات المراد ارفاقها (تحميلها) 4 ميغابايت. الملفات المسموح ارفاقها pdf فقط',
        'pleaseEnterDocName': 'أدخل اسم المستند',
        'pleaseEnterDocDesc': 'أدخل تفاصيل المستند',
        'pleaseSelectDocType': 'يرجى اختيار نوع المستند',
        'pleaseselectFile': 'اختر ملف',
        'signDoc': 'وقّع',
        'signView': 'عرض',
        'viewDocumemnt': 'عرض المستند',
        'page': 'الصفحة',
        'downloadDoc': 'تم تحميل الملف بنجا',
        'signDocument': 'توقيع المستند',
        'responsibleforsign': 'اتحمل كامل المسؤولية للتوقيع المدرج في هذا المستند',
        'signedBy': 'تم التوقيع بواسط',
        'docValidityStatus': 'الصلاحية',
        'download': 'تحميل الملف',
        'sign': 'وقّع',
        'signed': 'تم التوقيع',
        'validText': 'صالح',
        'invalidText': 'غير صالح',
        'docSignSuccessUAE': 'تم توقيع المستند بنجاح',
        'cancleSignProcess': 'تم إلغاء عملية التوقيع',
        'docSignatureFailed': 'حدث خطأ في توقيع المستند',
        'signADoc': 'توقيع المستند',
        'signHere': 'وقع هنا',
        'editDocDetail': 'تعديل تفاصيل المستند',
        'docValidityUntill': 'مدة صلاحية المستند',
        'docSize': 'حجم المستند',
        'deletePopUp': 'هل أنت متأكد أنك تريد حذف البيانات المدخلة',
        'signNow': 'التوقيع الآن',
        'signLater': 'التوقيع لاحقاً',
        'docSavedSuccess': 'تم حفظه بنجاح في المستندات الخاصة بك',
        'applicantName': 'اسم مقدم الطلب',
        'servicesType': ' نوع الخدمة',
        'ShowAll': 'إظهار الكل',
        'HideAll': 'إخفاء الكل',
        'complementryServices': 'الخدمات التكميلية',
        'availableApplications': 'الطلبات المتوفرة',
        'reasonsForCancelation': 'اسباب إلغاء الطلب',
        'changeService-Request': 'طلبات يمكن تحويلها',
        'EservicesUserManual': 'دليل مستخدم الخدمات الإلكترونية',
        'QuickServices': 'خدمات سريعة',
        'Enterthereason': ' ادخل سبب إلغاء الطلب',
        'allowedToCancleTitle': 'الطلبات المسموح إلغاءها',
        'allowedToCancleTicketsTitle': 'الطلبات الممكن إلغائها',
        'GetHousingApplicationStatus': 'الاستعلام عن حالة الطلب الإسكاني',
        'GetMaintenanceRequestStatusForReadyHouse': 'الاستعلام عن حالة طلب صيانة مسكن جاهز',
        'GetPaymentsStatus': 'الاستعلام عن حالة الدفعات',
        'Apply': 'تقديم الطلب',
        'TermsAndConditions': 'الشروط والأحكام',
        'DocumentsUploadedMBRHE': 'مستندات من المؤسسة',
        'DocumentsUploadedCustomer': 'مستندات من المتعامل',
        'viewTicket': 'قائمة الاستمارات',
        'DescrMessage': 'الرجاء ادخال وصف لا يقل عن 20 او يزيد عن 200 حرف',
        'housing-remarks': 'ملاحظة',
        'HowyourateWebsite': 'كيف تقيم التصميم العام لهذا الموقع؟',
        'Excellent': 'ممتاز',
        'VeryGood': 'جيدجدا',
        'Good': 'جيد',
        'Acceptable': 'مقبول',
        'ThankYouFeedback': 'شكرا لك على ملاحظاتك',
        'Home': 'الصفحة الرئيسية',
        'themeSwitch': 'مفتاح تغيير اللون',
        'useSwitchChangeTheme': 'استخدم مفتاح التغيير لتحديد اللون المناسب',
        'MBRHETheme': 'اللون الاساسي',
        'redWeakness': 'ضعف البصر في اللون  أحمر',
        'greenWeekness': 'ضعف البصر في اللون الأخضر',
        'ColourBlind': 'اللون الاسود',
        'textResize': 'حجم الخط',
        'ifYouHaveTroubleReadingText': 'إذا كنت تواجه مشكلة في قراءة النص ، يمكنك تكبير أو تصغير حجم الخط',
        'readSpeaker': 'قارئ النصوص',
        'listenPage': 'قم بالاستماع إلى محتوى الصفحة بالضغط على مشغل الصوت',
        'downloadApp': 'تحميل التطبيقات',
        'ThisExternalLink': 'هذا رابط خارجي وسوف يتم الانتقال بك اللى خارج موقع المؤسسة.',
        'doYouWantToContinue': 'هل تريد الاستمرار؟',
        'PrintPage': 'اطبع الصفحة',
        'search': 'بحث',
        'TotalResults': 'النتائج الكلية',
        'changeLanguage': 'تغيير اللغة',
        'whatsapp': 'واتس اب',
        'facebook': 'فيسبوك',
        'twitter': 'تويتر',
        'instagram': 'انستجرام',
        'youtube': 'يوتيوب',
        'siteTour': 'جولة في الموقع',
        'FindUs': 'قنوات التواصل الإجتماعية',
        'BrowserSupport': 'متصفحات الويب المعتمدة',
        'LastUpdated': 'آخر تحديث 19/05/2023',
        'CustomerCharter': 'ميثاق خدمة المتعاملين',
        'InformationPolicy': 'سياسة المعلومات',
        'appStore': 'قم بتحميل تطبيق اسكان من متجر ابل',
        'playStore': 'قم بتحميل تطبيق اسكان من متجر جوجل',
        'InternetExplorer': 'متصفح اكسبلورير',
        'GoogleChrome': 'متصفح جوجل كروم',
        'Safari': 'متصفح سفاري',
        'Firefox': 'متصفح فيرفوكس',
        'Chat': 'الدردشة',
        'valuableFeedback': 'ملاحظاتك القيمة حول هذه الصفحة',
        'confiden;tiality': 'ميثاق سرية بيانات المتعاملين',
        'search_title': 'نتائج البحث الخاصة ب ',
        'tele': 'رقم الاتصال',
        'monFri': ' الاثنين حتى الجمعة (7:30-22:00)',
        'OfficialWorkingHours': 'أوقات الدوام',
        'MonThu': 'الاثنين حتى الخميس: 7:30ص-3:30م',
        'Friday': 'الجمعة: 7:30ص-12:00 ظهرا',
        'SundayToThursday': 'الأحد إلى الخميس: 07:30 ص إلى 02:30 م',
        'CustomerService': 'إستقبال المتعاملين من',
        'CustomerServiceTime': 'الأحد إلى الخميس: 07:30 ص إلى 02:30 م',
        'constructionDisbursementContractRequests': ' طلب إصدار عقد صرف قرض البناء',
        'maintenanceDisbursementContractRequests': ' طلبات عقد صرف قرض الصيانة',
        'constructionDisbursementContractHeading': ' إصدار عقد صرف قرض البناء',
        'maintenanceDisbursementContractHeading': ' عقد صرف قرض الصيانة',
        'requestform': 'نموذج الخدمة',
        'viewdocument': 'اظهار المستندات',
        'requestcompletion': 'تأكيد اكمال الطلب',
        'constructionDisbursementContract': ' طلب إصدار عقد صرف قرض البناء',
        'requestStatus': 'حالة الطلب',
        'moreDetails': 'المزيد من التفاصيل',
        'step': 'الخطوة',
        'fundType': 'نوع التمويل',
        'city': 'المدينة',
        'area': 'المنطقة',
        'municipalityNumber': 'رقم البلدية',
        'contractor_licence_number': 'رقم رخصة المقاول',
        'contractor_name': 'اسم المقاول',
        'consultant_licence_number': 'رقم رخصة الاستشاري',
        'consultant_name': 'اسم الاستشاري',
        'landDetails': 'بيانات الارض',
        'tripleContractDetails': 'تفاصيل العقد الثلاثي',
        'contractDate': 'تاريخ العقد',
        'demarcationDate': 'تاريخ علائم البناء',
        'contractDurationMonths': 'مدة العقد بالشهور',
        'preparationMobilizationDuration': 'مدة التحضير بالشهور',
        'Emirates-id-lbl': 'الهوية الاماراتية',
        'delegator': 'بيانات الوكيل',
        'delegation': 'رقم الوكالة',
        'delegatorName': 'اسم الوكيل',
        'edbarahFileNo': 'رقم الاضبارة',
        'delegationDate': 'تاريخ الوكالة',
        'delegationPlace': 'مكان اصدار الوكالة',
        'joint': 'بيانات الضامن',
        'jointName': 'اسم الضامن',
        'bankAccountNo': 'رقم الحساب البنكي',
        'relative': 'صلة الضامن',
        'addFile': 'اضافة مستند',
        'applicantDocuments': 'مستندات المتعامل',
        'numberOfFiles': 'عدد الملفات المرفقة',
        'passportCopy': 'صورة جواز السفر',
        'idCopy': 'صورة الهوية',
        'familyBookCopy': 'صورة خلاصة القيد',
        'latestSalaryCertificate': 'شهادة اخر راتب',
        'housingLandMap': 'صورة خارطة الارض',
        'certificateZayedHousingProgram': 'شهادة من برنامج الشيخ زايد للاسكان',
        'aStatementHousingSupport': 'a statement about housing support',
        'ProofMaritalStatusCustodySupport': 'شهادة الحضانة من المحكمة',
        'inCaseApplicantStatusWidowDivorced': 'لفئة المطلقات والارامل',
        'proofContinuityMarriage': 'شهادة استمررارية الزواج',
        'incaseApplicantFemaleStatusMarriedForeigner': 'لفئة المواطنات المتزوجات غير المواطنين',
        'nationalDefenseCard': 'بطاقة حماة الوطن',
        'ifAvailable': 'if available',
        'contract': 'مستندات العقد',
        'triplContractConsultantApplicant': 'العقد الثلاثي',
        'licenseBuild': 'شهادة رخصة البناء',
        'demarcation': 'شهادة علائم البناء',
        'percentageConstructionProjectConsultantLetter': 'رسالة من الاستشاري توضح نسبة الانجاز',
        'billMaterials': 'جدول الكميات',
        'validCommercialLicenseDED': 'صورة من الرخصة التجارية',
        'addressLetter': 'بيان عنوان المقاول',
        'federalTaxAuthorityLetter': 'رسالة من الضريبة',
        'IBANBankAccountLetter': 'رسالة من البنك IBAN',
        'validProfessionalLicenseDED': 'مستندات الاستشاري',
        'documentTitle': 'اسم المستند',
        'submittedSuccessfully': 'تم إرسال طلبك بنجاح…!! طلبك الان قيد التدقيق.',
        'backDashboard': 'الرجوع للصفحة الرئيسية',
        'loanAmount': 'قيمة القرض',
        'installmentAmount': 'قيمة القسط',
        'numberInstallments': 'عدد الاقساط',
        'repaymentInstallmentdurationYears': 'مدة السداد بالسنوات',
        'repaymentInstallmentDurationMonths': 'مدة السداد بالأشهر',
        'insuranceAmount': 'قيمة التأمين',
        'advancedInsuranceAmount': 'مبلغ التأمين المقدم',
        'needYourSignature': 'تتطلب هذه الوثيقة توقيعك',
        'reSignature': 'إعادة التوقيع',
        'UAEPassEsignature': 'UAE Pass for e-signature',
        'DocumentStatus': 'حالة المستند',
        'InsurancePayment': 'دفع مبلغ التأمين',
        'PayNow': 'ادفع الان',
        'YourPaymentSuccessfully': 'تمت عملية الدفع بنجاح',
        'MortgageFirstDegreeContractDetails': 'تفاصيل عقد الرهن من الدرجة الاولى',
        'MortgageStartDate': 'تاريخ بداية الرهن العقاري',
        'MortgageEndDate': 'تاريخ نهاية الرهن العقاري',
        'MortgageDuration': 'مدة الرهن العقاري',
        'employmentFinanceInformation': 'البيانات الوظيفية و المالية',
        'applicationReview': 'مراجعة الطلب',
        'editPersonalInformation': 'تعديل المعلومات الرئيسية',
        'editEmploymentFinanceInformation': 'تعديل البيانات الوظيفية و المالية',
        'editDocuments': 'تعديل المستندات',
        'monthlySalary': 'مجموع الدخل الشهري',
        'financialCapacity': 'المقدرة المالية',
        'incomeMessage': '.في حال كان الدخل الشهري أقل من أو يساوي 15000 درهم، يجب اثبات مقدرة مالية لا تقل عن 500,000 درهم',
        'landNumberMessage': 'ادخل رقم الارض الموجود في خارطة الارض السكنية.',
        'financialCapabilityMessage': 'المقدرة المالية يجب ان لا تقل عن 500000 درهم.',
        'complemantryRequests': 'طلبات الخدمات التكميلية',
        'liveChatSupport': 'التحدث مع خدمة المتعاملين',
        'donothaveaccount': 'ليس لديك حساب في الهوية الرقمية؟',
        'createnew': 'إنشاء حساب جديد',
        'recoveraccount': 'كيفية استعادة الحساب؟',
        'contractoraccountlogininfo': 'سيتم تفعيل المستخدم في حال انشاء عقد المشروع من قبل قسم الإشراف الهندسي في مؤسسة محمد بن راشد للإسكان',
        'typicalvillas': 'تصاميم النماذج الموحّدة',
        'startingprice': "ابتداء من",
        'confidentiality': 'ميثاق سرية بيانات المتعاملين',
        'floors': 'طابق',
        'bedrooms': 'غرف النوم',
        'areyousick': "هل تعاني من أي مرض؟",
        'typeofdisability': 'نوع الاعاقة',
        'typeofdisease': 'نوع المرض',
        'typicalvillasnoteone': 'الاسعار تزيد بناء على مساحة وطبيعة الأرض ونوع التكييف وشكل النموذج مع العلم أن الأسعار لا تشمل أتعاب الإستشاري ودواليب غرف الملابس والمطبخ وأعمال الجبس ومظلة السيارات والمعلقات الكهربائية ورسوم البلدية ورسوم توصيل الكهرباء والمياه دبي.',
        'typicalvillasnotetwo': 'السعر يشمل ضريبة القيمة المضافة 5% (المبلغ مسترد بعد صدور شهادة الإنجاز عن طريق الهيئة الاتحادية للضرائب).',
        'typicalvillasnotethree': 'السعر يشمل مبلغ احتياطي بقيمة 50,000 درهم (المبلغ مستردإذا لم يستجد أمر يستدعي استخدامه).',
        'typicalvillasnotefour': 'سيتم البدء بالمشروع فور توفر المقاول المناسب.',
        'typicalvillasnotefive': 'المؤسسة غير مسؤولة في حال تغير سعر البناء بناء على تغير الأسعار في السوق المحلي.',
        'amountpaidby': 'كيف سأتمم عملية الدفع للمبلغ المتبقي',
        'cash': 'نقداً',
        'bank': 'عن طريق البنك (يسر2)',
        'viewRequest': 'عرض تفاصيل الطلب',
        'backToHome': 'الرجوع للصفحة الرئيسية',
        'requestSuccess': 'تم إرسال طلبك بنجاح...!! طلبك الان قيد التدقيق.',
        'sqft': 'قدم مربع',
        'applyforservice': 'التقديم على الخدمة',
        'villadetails': 'تفاصيل التصميم',
        'villatype': 'نموذج رقم',
        'engineername': 'اسم المهندس',
        'consultantcompany': 'الشركة الاستشارية',
        'serviceform': 'نموذج الطلب',
        'confirmation': 'تأكيد الطلب',
        'livechat': 'المحادثة الفورية',
        'servicesteps': 'مراحل الحصول على الخدمة',
        'applyforrequest': 'التقديم على طلب النماذج الموحّدة والتنسيق مع الاستشاري لمعرفة اي تفاصيل اضافية',
        'requestunderprocess': 'مراجعة الطلب من قبل المؤسسة',
        'amountpayment': 'توفير مبلغ الفرقية',
        'establishmentstep': ' تتولى المؤسسة دور المالك في متابعة البناء',
        'submitrequest': 'ارسال الطلب',
        'homatalwatn': 'هل انت من حماة الوطن؟',
        'typeofsickness': 'نوع المرض',
        'SocialInformation': 'البيانات الاجتماعية',
        'females': 'الإناث',
        'males': 'الذكور',
        'NumberOfSingleFamilyMembersInHouse': 'عدد الأبناء العزاب المقيمين بالمسكن',
        'NumbeFfMarriedFamilyMembersInHouse': 'عدد الأبناء المتزوجين المقيمين بالمسكن',
        'NumberOfElderyFamilyMembers60Plus': 'عدد أفراد الأسرة من كبار  السن (60 عاما )',
        'NumberOfPeopleDeterminationInFamily': 'عدد أفراد الأسرة من أصحاب الهمم',
        'IsHouseWellequippedForPeopleOfDeterminationNeed': 'هل المسكن مهيأ لتلبية احتياجات أصحاب الهمم؟',
        'HouseAdditionComponents': 'مكونات الإضافة المطلوبة للمسكن',
        'ServiceExtension': 'ملحق خدمي',
        'FirstFloor': 'طابق أول',
        'MasterBedroom': 'غرفة مع  حمام',
        'MasterMajilis': 'مجلس مع  حمام',
        'PreperationalKitchen': 'مطبخ تحضيري',
        'Salle': 'صالة',
        'ExtensionAdditionComponents': 'مكونات الإضافة المطلوبة للملحق',
        'ExternalKitchen': 'مطبخ خارجي ',
        'StorageRoom': 'مخزن',
        'LaundryRoom': 'غرفة غسيل',
        'HelpingCrewsMasterBedroom': 'غرفة مع  حمام للفئة المساعدة',
        'DriversMasterBedroom': 'غرفة مع حمام للسائق',
        'NursesMasterBedroom': 'غرفة مع حمام للممرض',
        'Parking': 'مرآب للسيارات',
        'ModelVideo': 'فيديو للنموذج',
        'share': 'مشاركة',
        'consultantInformation': 'بيانات الاستشاري',
        'remarks': 'ملاحظات',
        'step1': 'اكمال نموذج الخدمة',
        'step2': 'أرفق المستندات المطلوبة',
        'step3': 'وقع المستندات المطلوبة',
        'step4': 'دفع مبلغ التأمين',
        'complete': 'تقديم الطلب',
        'transactionNumber': 'الرقم المرجعي',
        'doYouHaveMonthlyDeductions': 'هل لديك استقطاعات شهرية؟',
        'toWhomItMayConcernInfo': 'سيتم اصدار شهادة لمن يهمه الأمر فور التقديم على الطلب.',
        'whomItMayConcernCertificate': 'شهادة لمن يهمه الأمر',
        'printCertificate': 'طباعة الشهادة',
        'DownloadTheCertificate': 'تحميل الشهادة',
        'exit': 'خروج',
        'enterTheOneTimePasscode': 'ادخل رمز المرور لمرة واحدة المرسل للرقم الذي ينتهي',
        'tohomeOtpInfo': 'في حال عدم تمكنك من الوصول إلى رقم الهاتف المرسل اليه يمكنك التقديم على طلب تحديث بياناتك كمتعامل عن طريق',
        'OTPsendYourRegisteredNumber': 'تم إرسال رمز المرور لمرة واحدة للرقم الذي ينتهي ب.',
        'certicateWillBeIssuedOnceYouSubmit': 'سيتم اصدار شهادة لمن يهمه الأمر فور التقديم على الطلب.',
        'towhominfomsg': 'سيتم اصدار شهادة لمن يهمه الأمر فور التقديم على الطلب.',
        'nodataavaialble': 'لا يوجد بيانات',
        'complementaryservices': 'طلبات الخدمات التكميلية',
        'downloadapprovalletter': 'تحميل رسالة الموافقة',
        'downloadliabilitycertificate': 'تحمل شهادة المديونية',
        'morefinancialdetails': 'المزيد من التفاصيل المالية / دفعات المشروع',
        'discountamount': 'مبلغ الخصم للسداد المبكر',
        'totaldiscountamount': 'مبلغ السداد المبكر',
        'approvedvalue': 'قيمة الخدمة',
        'lastinstallmentdate': 'تايخ أخر قسط',
        'arrears': 'المتأخرات',
        'applicantname': 'اسم المتعامل',
        'monthlyinstallment': 'القسط الشهرى شامل التأمين',
        'outstandingamount': 'إجمالى المطلوب من المتعامل ',
        'totalpaidamount': 'المبالغ المقدمة من المتعامل',
        'loantype': 'نوع القرض',
        'totalpaidarrears': 'إجمالى السدادات',
        'remainingamount': 'الرصيد المتبقى',
        'issuedate': 'تاريخ المنح',
        'firstinstalmentdate': 'تاريخ اول قسط',
        'financialdetails': 'التفاصيل المالية',
        'paymentofarrears': 'دفع المتأخرات',
        'earlysettlementheading': 'تفاصيل السداد المبكر',
        'earlysettlement': 'السداد المبكر',
        'projectpaymentdetails': 'تفاصيل دفعات المشروع',
        'tryagain': "إعادة المحاولة",
        'description': "تفاصيل",
        'mbrhe': "(مؤسسة محمد بن راشد للاسكان)",
        'referencenumber': 'الرقم المرجعي',
        'dsg': '(حكومة دبي الذكية)',
        'applicationservicename': 'اسم الخدمة الطلب',
        'typicalvillasrequestnumber': 'رقم طلب النماذج الموحدة',
        'typicalvillasrequeststatus': 'حالة طلب النماذج الموحدة',
        'typicalvillasrequestdate': 'تاريخ طلب النماذج الموحدة',
        'secondwifeEID': 'الهوية الاماراتية للزوجة الثانية',
        'contactUS': 'اتصل بنا',
        'opendata': 'البيانات المفتوحة',
        'openData': 'البيانات المفتوحة',
        'services': 'الخدمات',
        'loanTerms': 'أقر بأن جميع البيانات المسجلة في طلب الخدمة الإسكانية وكذلك المستندات الدالة عليها صحيحة ولمؤسسة محمد من راشد للإسكان الحق بمطالبتي بأية مستندات إضافية أو الإفصاح عن المعلومات قد تطلب لاحقاً، واتعهد بإبلاغ المؤسسة بأية تغييرات قد تطرأ على حالتي الإجتماعية أو المالية، وفي حال ثبوت عدم صحة البيانات والمستندات أو عدم التزامي بذلك فإن للمؤسسة الحق في إتخاذ الإجراءات المناسبة ضدي حسب الأنظمة والقوانين المتبعة، كما يحق للمؤسسة الغاء الطلب. أقر بعدم الممانعة من مشاركة بياناتي ومعلوماتي المقدمة مع الجهات الحكومية المعنية والجهات التمويلية المعتمدة لاستكمال اجراءات التقديم على الخدمة. أقر بالموافقة وعدم الممانعة بالإفصاح عن معلومات الاتحاد للمعلومات الائتمانية وبالموافقة وعدم الممانعة لإصدار كشف الحساب من خلال المصرف المركزي.',
        'monthlyIncome': 'الدخل الشهري',
        'housecompletiondate': 'تاريخ إنجاز المسكن',
        'msg30years':'عذراً، لا يمكنك الحصول على هذه الخدمة لأن تاريخ اكتمال بناء منزلك أقل من 30 سنة.',
        'msg10years':'عذراً، لا يمكنك الحصول على هذه الخدمة لأن تاريخ اكتمال بناء منزلك أقل من 10 سنة.',
        'careers' : 'الوظائف',
        'iskanapp' : 'تطبيق إسكان',
        'polls' : 'الاستطلاعات',
        'AIchat' : 'المساعد الرقمي لإمارة دبي',
        'peopleofdetermination' : 'أصحاب الهمم',
        'screenreader' : 'قارئ النصوص',
        'peopleOfDeterminationServices' : 'خدمات أصحاب الهمم',
        'facilitiesandprivileges' : 'التسهيلات والامتيازات',
        'peopleOfDeterminationCharter' : 'ميثاق أصحاب الهمم',
        'initiatives' : 'المبادرات',
        'visitoffice': 'Please visit MBRHE office'
    }
}