import { LOCALES } from '../Locales';

export default {
    [LOCALES.ENGLISH]: {
        'lang': 'عربي',
        'nodata': 'There is no data to show',
        /** Customer */
        /** Customer Login */
        'back': 'Back',
        'customer-login-heading': 'Customer Login',
        'login-with': 'Login With UAE PASS',
        'uaepass': 'UAE PASS',
        'please-select': 'Please Select',
        'login-with-EID': 'Login with emirates Id card',
        'login-with-EID-description': 'Login using the Emirate’s ID will allow the user to login using the OTP code sent to the mobile number associated to the submitted ID only in case the user have already approved housing application. **This OTP code is valid for 5 minutes only',
        'Emirates-id-lbl': 'Emirates ID',
        /** Dashboard */
        'dashboard-lbl': ' Dashboard',
        'changeServiceRequest': 'Change Service from Ready-Made House Loan to Construction Loan',
        'cancelApprovedRequest': 'Cancel Approved Housing Service Application',
        'cancelRequest': 'Cancel In-Progress Housing Service Application',
        /** Settings */
        'settings-lbl': 'Settings',
        'communication-language': 'Communication Language',
        'email-notifications': 'Receive emails notification',
        'mobile-notifications': 'Mobile push notifications',
        'sms': 'Enable SMS on login',
        /* Widgets */
        'service-widget-heading': 'Services',
        'housing-requests-widget-heading': 'Housing Requests',
        'application-status-widget-heading': 'Applications Status',
        'maintenance-request-widget-heading': 'Maintenance Requests',
        'maintenance-insurance-widget-heading': 'Maintenance Insurance',
        'contractor-payments-widget-heading': 'Contractor Payments',
        'contractor-payments-requests-widget-heading': 'Contractor Payment Requests',
        'housing-projects-widget-heading': 'Housing Projects',
        'financial-status-widget-heading': 'Financial Status',
        'manage-booking-widget-heading': 'Manage House Booking',
        'awareness-widget-heading': 'Maintenance Awareness',
        /** ContractorPaymentWidgetItem */
        'contractor': 'Contractor:',
        'contractor-lbl': 'Contractor',
        'status-lbl': 'Status: ',
        'status': 'Status : ',
        'more': 'More',
        /** HousingRequestItem */
        'ticket-number': 'Ticket Number : ',
        'delete': ' Delete',
        'modify': ' Modify',
        'deleteconfirmation': 'Are you sure you want to DELETE this?',
        /**  Others */
        'error-occured': 'An error occured while trying to process your request. Please try again later! ...',
        'show-more': 'Show More',
        /**  Footer */
        'copyright': 'Copyright © 2024 Mohammed Bin Rashid Housing Est',
        'maintain': 'Website maintained by Mohammed Bin Rashid Housing Est',
        'resolution': 'Screen Resolution 1366 x 768',
        'sitemap': 'Site Map',
        'termsofuse': 'Terms Of Use',
        'privacypolicy': 'Privacy Policy',
        'accessibility': 'Accessibility',
        'contactus': 'Contact Us',
        'faq': 'FAQs',
        'ejobs': 'Career',
        /** Contractor Payments */
        'id': 'ID: ',
        'amount': 'Amount : ',
        'Thestagesofprojectcompletion': 'Project Completion Stages',
        /** To Whom */
        'to-whom-lbl': 'To Whom It May Concern',
        'idbarahno': 'Idbarah number',
        'unifiedno': 'Unified number',
        'make-new-request': 'Make new request',
        /** Loan Calculator */
        'salary': 'Salary',
        'others': 'Others',
        'gauranter': 'Guarantor',
        'personalloan': 'Personal loan',
        'employerloan': 'Employer loan',
        'rent': 'Rent',
        'carloan': 'Car loan',
        'loanAmount': 'Loan amount',
        'calculate': 'Calculate',
        'loancalculator-heading': 'Trial Loan Calculator',
        'totalSalary': 'Total salary',
        'totalIncome': 'Total income',
        'totalDeductions': 'Total deductions',
        'deductionmsgone': 'Total monthly deductions (personal, commercial, business, or car loan installments, etc.), e.g., if you have a car installment of 2000 AED + a personal loan installment of 2500 AED, your total monthly deduction will be 4,500 AED.',
        'deductionmsgtwo': 'Installments should not exceed 60% of your total gross salary.',
        'netIncome': 'Net income',
        'debtPercentage': 'Debt percentage % ',
        'repaymentPeriod': 'Repayment period',
        'repaymentInMonths': 'Repayment in months',
        'monthlyInstallments': 'Monthly installment',
        'lastInstallmentAmount': 'Last installment amount',
        'debitPerctAfterInstallment': 'Debit percent after installment',
        'twoYearsInsurance': 'Two years insurance',
        'monthlyPremium': 'Monthly premium',
        'MonthlyInstalment': 'Monthly Instalment + Momthly Insurence Fees',
        'Disclimer': 'Disclimer',
        'loanmessage': 'Mohammed Bin Rashid Housing Establishment provides the use of a calculator to view the estimated values in the calculation of the loan and the repayment period and monthly installment. This is for your information purpose only. Kindly visit Mohammed bin Rashid Housing Establishment to verify the accuracy of the value obtained using the calcuator.',
        /** Maintenance Requests */
        'MaintenanceRequest': 'Maintenance Requests',
        'requestNo': 'Request Number',
        'requestDate': 'Request Date',
        'AppointmentDate': 'Appointment date',
        'problemType': 'Problem type',
        'problemRemarks': 'Problem remarks',
        'problemDescription': 'Problem description',
        'endDate': 'End date',
        'contractorName': 'Contractor name',
        'ContactPersonName': 'Contact person name',
        'contractorRemarks': 'Contractor remarks',
        'requestRelativeDate': 'request relative date',
        /** Maintenance Requests Service*/
        'Category': 'Category',
        'Selectfiles': 'Select files',
        'MaintenanceDescription': 'Maintenance description',
        'MaintenanceNote1': 'User can submit the villa maintenance request form more than once.',
        'MaintenanceNote2': 'In case of complete disruption of water and electricity, kindly contact the Home Services Section 050-7030308',
        'requiredErrorMessage': 'Fields with * are required, please fill them with proper value',
        /** Maintenance Insurance */
        'ContactPersonName': 'Contact person',
        'supplier': 'Supplier',
        'granteePeriod': 'Guarantee period',
        /** Construction Cost Calculator */
        'ConstructionCalculator': 'Construction Cost Calculator',
        'Budget': 'Budget',
        'Area': 'Land Area ',
        'AverageCost': 'Average cost',
        'OutDoorConstructionCost': 'OutDoor construction cost',
        'EstimatedBuildupArea': 'Estimated buildup area',
        /** Profile */
        'POBox': 'POBox',
        /** Notifications */
        'Notifications': 'Notifications',
        'Review': 'Review',
        'Delete': 'Delete',
        'Logout': 'Logout',
        /** New Housing Service Request */
        'newHousingRequest': 'New Housing Service Request',
        'PersonInformation': 'Person Information',
        'NationalID': 'National ID',
        'PrimaryMobileNo': 'Primary mobile number',
        'SecondaryMobileNo': 'Secondary mobile number',
        'PassportNo': 'Passport number',
        'IsFromHomatAlWatan': 'Are you one of Armed Forces Homat Al Watan?',
        'isApplyingForSecondWife': 'Are you applying for the Second wife?',
        'IsFromSpecialNeeds': 'Are you one of People of Determination?',
        'SocialStatus': 'Social status',
        'Continue': 'Continue',
        'IAcceptingThe': 'I accept the terms and conditions',
        'IAgreeToTheWebsite': 'I agree to the MBRHE website',
        'Elligibleservices': 'Eligible Services',
        'TermsOfUse': 'Terms of use',
        'Agree': 'Agree',
        'SpecialNeedType': 'Please select the type',
        /** New Housing Service Request */
        'StepsHousingServiceRequest': 'Steps Housing Service Request',
        'HousingRequest': 'Housing Request',
        'selectedService': 'The Selected Service Type',
        'changeServiceType': 'Change The Service Type',
        'selecteService': 'Select The Service Type',
        'DidyouhaveChildcustody': 'Do you have a child custody?',
        'clicktoadd': 'Click to add new request',
        'deleterequestconfirm': 'Are you sure you want to delete?',
        'selectservice': 'Please select a service to continue',
        'eligiblenote': 'Submitting a housing request with any of the below services does not mean that you are eligible for it.',
        'eligibleterms': 'It acknowledged that the information provided in the request for the housing service as well as the documents are valid and that Mohammed Bin Rashid Housing Establishment has the right to request any additional documents or information declaration may be required later, and I promise to inform the organization of any changes in my marital or financial status, in case it is proved the invalidity of the information or documents, the institution has the right to take appropriate measures are against me by law. In addition, MBRHE has the right to cancel the request.',
        'eligibleInfoIconMsg': 'To know more about the service description, eligibility criteria and required documents ',
        /** Person Information */
        'PersonalInf': 'Personal Information',
        'TownNo': 'Town number',
        'KholasaID': 'Kholasa number',
        'IdbarahNo': 'Idbarah number',
        'FamilyNo': 'Family number',
        'spouseName': 'spouse name',
        'SpouseSalary': 'Spouse salary',
        'RelationwithSpouse': 'Relation with spouse',
        'WeddingDate': 'Wedding date',
        'NoOfFamilyMembers': 'No of family members',
        'SpouseIdbarah': 'Spouse idbarah number',
        'IsyourspousealocalUAEcitizen': 'Is your spouse a local UAE citizen?',
        'IsyourspousealocalUAEcitizenWife': 'Is your spouse a local UAE citizen?',
        /** Employment Information */
        'Employment': 'Employment',
        'JobStatus': 'Job status',
        'hasgovernmentsupport': 'Do you have a government support?',
        'governmentSupportTypeId': 'Government support type',
        'govtsupportamount': 'Government support amount',
        'grantor': 'Grantors',
        'currentemployer': 'Current employer',
        'OfficePhoneNo': 'Office phone number',
        'JobTitle': 'Job title',
        'OfficePOBox': 'Office POBox',
        'JobEmirate': 'Job emirate',
        /** Finance Information*/
        'FinanceInformation': 'Finance Information',
        'SocialAffairsIncome': 'Social affairs income',
        'MonthlyDeductions': 'Monthly deductions',
        'MonthlyRent': 'Monthly rent',
        'FinanceAmount': 'Finance amount',
        'FinanceSource': 'Finance source',
        /** Documents */
        'dragAndDrop': 'Choose File / Drag and Drop',
        'Documents': 'Documents',
        'uploaddocumentmessage': 'At this stage your application is saved for further updates, please review your application before submission. Once you click on "Finish" button you can\'t edit this application.',
        'uploaddocumentsignedmessage': 'All attached documents should be signed and stamped by all relevant parties.',
        'ResendPinCode': 'Resend pin code',
        'docdeletesuccess': 'The document has been deleted successfully.',
        'PleaseenteryourOTP': 'Please enter your OTP',
        'Obligationtobuild': 'Obligation to build',
        'ObligationtobuildBody': 'I, the undersigned, pledge that I will undertake to begin construction on the residential land granted to me within three years of the date I obtained it, and I acknowledge that I am aware you have the right to withdraw the land and allocate it to another beneficiary in the event of not being adhered to the period mentioned above, and that I am not entitled to apply for a new land grant until three years passage from the date of the previous land withdrawal.',
        'documentobligationnote': 'In the event that you want to apply for a construction loan service from MBRHE in the future and to obtain approval, you must adhere to the conditions of MBRHE, and specifically that you do not exceed the total construction area of ​​8,000 square feet for the villa and external annexes.',
        /** Input Error Messages */
        'emptyvalidation': 'This field can not be empty.',
        'numberValidation': 'Input must be numbers',
        'emailValidation': 'Input must be a valid email address',
        'mobileValidation': 'Input must be a valid phone number',
        'eidValidation': 'The Emirates ID value is invalid',
        /** Link Account */
        'LinkYourAccountWithMBRHE': 'Link Your Account With MBRH',
        'MyIDLinkAccountMessage': 'If you have any previous applications with us, please link your account, so that you can view your existing applications.',
        'LinkYourAccountNow': 'Link Your Account Now',
        'MyIDLinkAccount': 'Link account',
        'IsBenefitingMRHE': 'Are you benefiting from MBRHE services?',
        'yes': 'Yes',
        'no': 'No',
        'EnterPinCode': 'Enter pin code',
        'EnterApplicationNoAndServiceType': 'Enter your housing application number and the services type ',
        'acceptterms': 'Accept terms and conditions',
        'nolinkingtoken': 'No linking token',
        'linksuccess': 'Account linked successfully.',
        /** House Booking */
        'HouseBooking': 'House Booking',
        'NumberofFloors': 'Number of floors',
        'NumberofBedrooms': 'Number of bedroom',
        'Formoreinfo': 'For More info about the House Booking user journey, please',
        'clickhere': 'Click here!',
        'All': 'All',
        'Style': 'Style',
        'CalculateTheValueOfTheHouse': 'Calculate the value of the house',
        'PriceAllocation': 'Price allocation',
        'BookingPrice': 'Booking price',
        'RemainingAmount': 'Remaining amount',
        'TermsConditions': 'Terms & Conditions',
        'Viewdetails': 'View details',
        'Viewdesigns': 'View designs',
        'NumberofUnitsAvailable': 'Number of Units Available',
        /** Old Booking */
        'Landarea': 'Land area',
        'BuildingArea': 'Building area',
        'LandNo': 'Land number',
        'VillaNo': 'Villa number',
        'Zone': 'Zone',
        'projectName': 'Project name',
        'BookedDate': 'Booked date',
        'BookingExpiryDate': 'Expiry date',
        'Print': 'Print',
        'exceedbooking': 'Sorry, you have exceeded the allowed number of house booking.',
        'BookingReferenceNumber': 'Booking reference number',
        'directions': 'Directions',
        'yourBookingDetails': 'Your booking details',
        'Message': 'Message',
        /** Related Houses */
        'RelatedHouses': 'Relate Houses',
        'housedetails': 'House details',
        'mybookings': 'My Booking',
        'PurchaseReadyhouse': 'Purchase Ready House',
        '3DView': '3D View',
        'RelatedHousesPayment': 'Payment',
        'Locationonthemap': 'Location On Map',
        'Price': 'Price',
        'OrderBy': 'Order By',
        'confirm_booking': 'Confirm booking',
        'booking_details': 'Booking details',
        'cash_deposit': 'Cash deposit',
        'pay_by_E_Payment': 'Pay by E-Payment',
        'booking_payment_heading': 'Cash deposit with Mohammed Bin Rashid Housing Establishment, Collection Section.',
        'booking_fee_heading': 'The house booking fee is ',
        'booking_fee_note1': 'You have 2 business days to deposit ',
        'booking_fee_note11': ' as a booking amount to reserve your booking for one month. Please note! any delay in delivering the payment will result in booking cancellation.',
        'booking_note': 'Please note that you can book a unit online only 1 time',
        'booking_note_two': 'Please note that you can book a unit online only one time, and will not be able to cancel once the online payment confirmed',
        'calculate_house_amount': 'Calculate the value of the house',
        'choose_payment_method': 'Choose your payment method',
        'price_allocation': 'Price allocation',
        'booking_price': 'Booking price',
        'loan_amount': 'Loan amount',
        'remaining_amount': 'Remaining amount',
        'dirham': 'AED',
        /** Contractor Payments Requests */
        'Reject': 'Reject',
        'Accept': 'Accept',
        /** Financial Status */
        'overduepayment': 'Over due payment',
        'ApplicationName': 'Application name',
        'ApplicationNumber': 'Application number',
        'startingBalance': 'Starting balance',
        'DateofLastBill': 'Date of last bill',
        'RemainingLoan': 'Remaining loan',
        'MaturityDate': 'Maturity date',
        'MonthlyPayment': 'Monthly payment',
        'NextPaymentDate': 'Next payment date',
        'OverdueAmount': 'Overdue amount',
        'EarlySettlement': 'Early settlement',
        'Discount': 'Discount',
        /** Early Settlement */
        'EarlySettlementHeading': 'Early Settlement',
        'settlement_term1': 'Agree to immediately pay the remaining loan balance after the deduction.',
        'settlement_term2': 'That the beneficiary has completed the housing construction for which the loan was availed.',
        'settlement_term3': 'Exemption amount to not exceed (300,000) three hundred thousand dirhams.',
        'settlement_term4': 'In case of approval of early repayment request, the borrower will be asked to repay the loan value after discount according to what is stipulated in Law No 22 for the year (2011).',
        'settlement_term5': 'The request valid for one week from the date of application.',
        'conditions': 'Conditions:',
        'requireddocs': 'Required documents:',
        'doc1': 'Certificate of housing completion certified by dubai municipality in case of housing loan.',
        'doc2': 'Receipt of the Residence Certificate + Site Map transferred in the name of the beneficiary in the case of Housing Loan.',
        'Loanfilenumber': 'Loan file number',
        'Discountpercentage': 'Discount percentage %',
        'Discountedamount': 'Discounted amount',
        'Earlypaymentamount': 'Early payment amount',
        'Applicationnumber': 'Application number',
        'Remainingmonths': 'Remaining months',
        'Amounttopay': 'Amount to pay',
        'Completioncertificate': 'Completion certificate',
        'ApplicationsStatus': 'Application Status',
        'ApplicationStatus': 'Application Status',
        /** Oevr Due Payment */
        'Overduepayment': 'Over Due Payment',
        'mPayAccount': 'Over Due Payment',
        'Epayment': 'E-payment',
        'Amount': "Amount",
        'Epayment_message': 'The central ePay gateway of Dubai Smart Govment enables customer to settle the fees of government services online on a 24/7 basis in a secure environment.',
        /** Captcha */
        'click_captcha': 'Click or touch the',
        'enter_captcha': 'Type below the answer to what you hear. Numbers or words: ',
        'invalid_picture': 'Please select the correct image',
        /** Contractor */
        'contractorProjects': 'Contractor Projects Summary',
        'services': 'Services',
        'retentionPayment': 'Retention Payment',
        'retentionPaymentRequest': 'Retention Payment Requests',
        'contractorProjectPayment': 'Contractor Project Payment',
        'openNewProjectFile': 'Open New Project File',
        'constructionLoanProjects': 'Construction Loan Projects',
        'fileNumber': 'File Number',
        'ownerName': 'Owner Name',
        'contractAmount': 'Contract amount',
        'currentPercentCompleted': 'Current Percentage Completed %',
        'documentCount': 'Document attachment count',
        'serviceType': 'Service type',
        'paymentDate': 'Payment date',
        'paymentToOwner': 'Payment To Owner',
        'paymentToContractor': 'Payment to Contractor',
        'billRetention': 'Bill Retention',
        'maintenanceGrantProject': 'Maintenance Grant Projects',
        'projectSummary': 'Project Summary',
        'financialStatus': 'Financial Status',
        'dashboard': 'Dashboard',
        'projectFileRequests': 'Project File Requests',
        'projectPaymentRequests': 'Project Payment Requests',
        'openNewProjectFileConstructionloan': 'Open New Project File For Construction Loan',
        'contractorProjectPaymentConstructionloan': 'Contractor Project Payment For Construction Loan',
        'retentionPaymentconstructionloan': 'Retention Payment For Construction Loan',
        'applicationNo': 'Application No',
        'applicationDate': 'Application Date',
        'openNewProjectFileMaintenanceGrant': 'Open New Project File For Maintenance Grant',
        'contractorProjectPaymentMaintenanceGrant': 'Contractor Project Payment For Maintenance Grant',
        'retentionPaymentmaintenancegrant': 'Retention Payment Construction For Maintenance Grant',
        'noData': 'There is no data to show',
        'srNo': 'Sr. No.',
        'descriptionOfwork': 'Description of work',
        'contractPercent': 'Contract Percentage %',
        'viewComment': 'Comments',
        'addComment': 'Add Comment',
        'totalPercentage': 'Total Percentage',
        'projectInformation': 'Project Information',
        'contractNo': 'Contract Number',
        'contractStatus': 'Contract Status',
        'ownerShare': 'Owner Share',
        'breakDownInformation': 'Break Down Information',
        'requiredDoc': 'Required Documents',
        'mobilisation': 'Mobilisation',
        'excavationBFilling': 'Excavation & Back Filling',
        'subStructure': 'Sub Structure',
        'superStructure': 'Super Structure',
        'blockWork': 'Block Works',
        'plasterWork': 'Plaster Works',
        'electricWork': 'Electrical works',
        'plumbingWork': 'Plumbing Works (int.)',
        'plumbingWorkExt': 'Plumbing Works (ext.)',
        'internalFinishes': 'Internal Finishes',
        'externalFinishes': 'External Finishes',
        'waterProofing': 'Water Proofing',
        'aluminiumWorks': 'Aluminium Works',
        'gatesMetalWorks': 'Gates & Metal Works',
        'joineryWork': 'Joinery Works',
        'airConditioningWork': 'Air Conditioning Works',
        'cleaning': 'Cleaning',
        'grantBoq': 'Grant BOQ',
        'totalPaymentPercentage': 'Total bill of quantity percentage should be 100%',
        'mobSiteOfficeServices': 'Mob.,Site Office & Services',
        'excavation': 'Excavation',
        'BackFilling': 'Back Filling',
        'villa': 'Villa',
        'villaCompundWall': 'Villa Compound Wall',
        'VillaServantBlock': 'Villa Servant Block',
        'villaGfSlab': 'Villa GF Slab',
        'villaFfSlab': 'Villa FF Slab',
        'compoundWall': 'Compound Wall',
        'servantBlock1': 'Servant block',
        'servantBlock': 'Servant block (Int.&Ext.)',
        'solidBlockVillaCw': 'Solid block ( Villa & Compound wall )',
        'villaGfWalls': 'Villa GF Walls',
        'villaFfWalls': 'Villa FF Walls',
        'villaInteralPlaster': 'Villa Internal Plaster',
        'villaExternalPlaster': 'Villa External Plaster',
        'conduits': 'Conduits (Int.&Ext.)',
        'wiring': 'Wiring & Cable',
        'accessories': 'Accessories & DB',
        'lightFittingsFans': 'Light Fittings & Fans',
        'waterSupplyPipes': 'Water Supply Pipes',
        'drainagePipes': 'Drainage Pipes',
        'sanitaryWaresWaterHeaters': 'Sanitary Wares & Water Heaters',
        'drainagePipesManholes': 'Drainage Pipes & Manholes',
        'waterTanksPumps': 'Water Tanks & Pumps',
        'septicTankAndSoakaway': 'Septic Tank & Soak Away',
        'floorTiles': 'Floor Tiles',
        'wallTiles': 'Wall Tiles',
        'paints': 'Paints',
        'marble': 'Marble (staircase+threshold)',
        'RoofClayTiles': 'Roof Clay Tiles',
        'falseCeiling': 'False Ceiling (wet area)',
        'compoundWallPaints': 'Compound Wall Paints',
        'interlock': 'Interlock',
        'footingsVilla': 'Footings (Villa, C.W., S.B.)',
        'roof': 'Roof',
        'bathrooms': 'Bathrooms',
        'doors': 'Doors',
        'windows': 'Windows',
        'handrail': 'Handrail',
        'gates': 'Gates',
        'spiralStair': 'Spiral Stair',
        'CarShed': 'Car Shed',
        'kitchenCabinetsWardrobes': 'Kitchen Cabinets & Wardrobes',
        'Pergola': 'Pergola',
        'machinesUnit': 'Machines/Unit',
        'ductsDiffusers': 'Ducts/Diffusers',
        'CivilWork': 'Civil Work',
        'cleaningLevelingHandingOver': 'Cleaning, Leveling & Handing Over',
        'processSuccess': 'The process has been completed successfully',
        'atThisStageApplication': 'At this stage your application is save for further updates, please review your application before submission. Once you click Submit you cannot edit this application',
        'allAttachedDoc': 'All attached documents should be signed and stamped by all relevant parties.',
        'viewModify': 'View/Modify',
        'edit': 'Edit',
        'view': 'View',
        'finish': 'Finish',
        'add': 'Add',
        'next': 'Next',
        'previous': 'Previous',
        'fileSize': 'Size of each file uploaded should not exceed 4MB, file types allowed are pdf, jpg, jpeg,png',
        'customerLogin': 'Customer Login',
        'contractorLogin': 'Contractor Login',
        'toApplyLoanGrant': 'To apply for Grant And Loan services or Financial services',
        'toOpenNewFile': 'To open a File or apply for Payments',
        'loginWith': 'Sign in with UAE PASS',
        'uaePass': 'UAE PASS',
        'or': 'OR',
        'loginWithEmiratesId': 'I have Housing Application',
        'loginWithEmiratesIdText': 'Login using the Emirate’s ID will allow the user to login using the OTP code sent to the mobile number associated to the submitted ID only in case the user have already approved housing application. **This OTP code is valid for 5 minutes only',
        'emiratesId': 'Emirates Id',
        'requiredField': 'This field is required, kindly fill it',
        'submit': 'Submit',
        'signIn': 'Sign In',
        'loginByOtp': 'Login by OTP',
        'userName': 'User Name',
        'pwd': 'Password',
        'rememberMe': 'Remember username',
        'mobileNo': 'Mobile number',
        'loginOtpText': 'This is using for login OTP (One Time Password) which one send to your SMS, use this password to authenticate. This password will be valid for 5 mins, kindly change your password once logged in',
        'totalBillAmount': 'Total Bill Amount',
        'totalPercent': 'Total Percentage',
        'approvedPercent': 'Percentage Completed & Approved %',
        'totalPaidAmount': 'Total Paid Amount',
        'sendAmountOwner': 'Do you want to send amount Owner?',
        'kindlySpecify': 'Kindly specify the amount to be paid to the owner, if no payment to owner, please uncheck',
        'lessThanEqualContractPer': 'Note! The value must be less than or equal to the contract percentage',
        'lessThanEqualActualDone': 'Note! The value must be greater than or equal to the actual done percentage',
        'totalPercentageMsg': 'The total payment percentage in the request should be greater than or equal to 10 % of the loan amount',
        'grantPaymentMsg': 'Payment percentage value should be greater than the Actual done percentage value',
        'actual%Done': 'Actual Done %',
        'payment': 'Payment %',
        'done': 'Done',
        'kitchenCabinaets': 'Kitchen Cabinets and Wardrobes',
        'siteLeveling': 'Site levelling,cleaning and H.Over.',
        'note': 'Note! ',
        'retentionAmount': 'Retention Amount',
        'profile': 'Profile',
        'name': 'Name',
        'companyName': 'Company Name',
        'email': 'Email',
        'bankName': 'Bank Name',
        'accountNo': 'IBAN /Acc No',
        'emirate': 'Emirate',
        'contactPerson': 'Contact Person',
        'mobileNumber': 'Mobile Number',
        'officePhone': 'Office Phone',
        'faxNo': 'Fax No',
        'address': 'Address',
        'pOBox': 'PO Box',
        'changePwd': 'Change Password',
        'save': 'Save',
        'newPwd': 'New Password',
        'confPwd': 'Confirm Password',
        'update': 'Update',
        'cancle': 'Cancel',
        'passwordarenotsame': 'New password and confirm password are not same',
        'cancleAuthentication': 'User has Cancelled the Authentication',
        'kioskLocation': 'Kiosks Location',
        'iWilldolater': 'I\' ll do it later',
        'ok': 'Ok',
        'photoStage': 'Photos For Current Stage',
        'attachmentImage': 'Attachment Images',
        'viewImage': 'View Image',
        'notCompleted': 'Not Complete',
        'completedWaiting': 'Completed - Waiting Owner Approval',
        'completedDocumentsChecking': 'Completed - Documents Checking',
        'expiredNotReceived': 'Expired - Owner Approval Not Received',
        'documentsReceived': 'Documents Received',
        'new': 'New',
        'approved': 'Approved',
        'onHold': 'On Hold',
        'inProgress': 'In Progress',
        'preApproval': 'Pre Approval',
        'received': 'Received',
        'rejected': 'Rejected',
        'suspended': 'Suspended',
        //ChatBot Translation
        'talk_with_moftah': 'Chat With Moftah',
        'chat_with_whatsapp': 'Chat With Moftah(WhatsApp)',
        'our_location': 'Our Location',
        'call_us': 'Call Us',
        'chatbotWelcomeMsg1': "Hello ! My name is Moftaah,",
        'chatbotWelcomeMsg2': "MBRHE`s virtual assistant. I am here to help answer your questions on Housing Services.",
        'letsChat': "Let's Chat",
        'chatbot_heading': "Virtual Assistant - Mohammed Bin Rashid Housing Est.",
        'cahtbot_back': "Back",
        'cahtbot_refresh': "Start Over",
        'cahtbot_chage_lang': "عربي",
        'cahtbot_top': "Go to top",
        'cahtbot_bottom': "Go to bottom",
        'cahtbot_name': "Muftah",
        'cahtbot_customer_name': "Guest",
        'cahtbot_invlaid_selection': 'Please select one of the related options below:',
        'cahtbot_continue_search': 'Or continue searching with the entered value! knowing that you will exist the current service form!',
        'back_to_main': "Back to main menu",
        'applucationStatus_FileNo': 'File No',
        'applucationStatus_Status': 'Status',
        'applucationStatus_ServiceType': 'Service Type',
        'HousingStatus_ReqNo': 'Request Number',
        'HousingStatus_ReqDate': 'Request Date',
        'HousingStatus_AppointmentDate': 'Appointment Date',
        'PDF_ViewFull': "View in Full Screen",
        'PDF_Download': "Download",
        "agent": "Customer Service Agent",
        'payment_id': 'Payment Id',
        "payment_current_percent_completed": 'Percentage Completed',
        'payment_total_bill_amount': 'Total Bill Payment',
        'payment_status': 'Status',
        'theSatgesOfProject': 'The Stages of Project Completion (Grid View) / The Stages of Project Completion (Chart View)',
        'noPaymentsAvailable': 'Payments Not Available',
        'pleaseUploadDoc': 'Please upload the required documents.',
        //Contact Information
        "contactInformation": "Contact Information",
        'ContactInfoText1': "Before beginning our conversation, I'd like to know you better.",
        'ContactInfoText2': "Can you please let me know the following:",
        'ContactInfoEmail': "E-Mail (Optional)",
        'grantAmount': 'Grant Amount',
        'viewBOQComment': 'View Comment',
        'boqSummary': 'BOQ Summary',
        'viewBOQAndPrint': 'View Summary And Print BOQ',
        'remarks': 'Engineering Remarks: ',
        'breakDown': 'BREAK DOWN',
        'mrheFileNo': 'MBRHE File No.',
        'date': 'Date',
        'villaFor': 'Villa for Mr.',
        'contValue': 'Cont. Value',
        'consultant': 'Consultant',
        'ownerSign': 'Owner’s Signature',
        'consultantSign': 'Consultant’s Stamp and Signature',
        'contractorSign': 'Contractor’s Stamp and Signature',
        'ownerScope': 'Owner Scope',
        'savedSucc': 'Saved successfully',
        'saveAndClose': 'Save and Close',
        'estimatedValue': 'Estimated Value Dhs.',
        'airConditioning': 'Air Conditioning System',
        'lightFitiing': 'Light Fittings',
        'kitchenCab': 'Kitchen Cabinets',
        'wardrobes': 'Wardrobes',
        'internalWooden': 'Internal Wooden Doors ( Leaves )',
        'antenna': 'Antenna',
        'interncom': 'Intercom System',
        'falseCelling': 'False Ceiling',
        'carShed': 'Car Shed',
        'sweetSoil': 'Sweet Soil',
        'leveling': 'Leveling',
        'water&Electricity': 'Water & Electricity Connection',
        'clear': 'Clear',
        'oops': 'Ooops!!',
        'gobacktodashboard': 'Go Back to Dashboard',
        'pagenotfound': 'THAT PAGE DOESN’T EXIST OR IS UNAVAILABLE.',
        'maxlength': 'maximum allowed digits exceeded',
        'total': 'Total:',
        'totalValue': 'Total',
        'printOwnerScope': 'View Summary And Print Owner Scope',
        'ownerScopeSummary': 'Owner Scope Summary',
        'dhs': 'Dhs',
        'mostusedservices': 'View All Services',
        // uaeSignature
        'myInbox': 'My Inbox',
        'addDoc': 'Add Document',
        'uploadedDoc': 'Uploaded Documents',
        'noDoc': 'You do not have any Documents',
        'docDetail': 'Document Details',
        'docType': 'Document Type',
        'docValidity': 'Validity Until',
        'docName': 'Document Name',
        'docDesc': 'Document Description',
        'validationLimit': 'Name should not exceed 25 characters. Special characters not allowed',
        'choseFile': 'Choose File',
        'uploadDocument': 'Upload Document',
        'documentsList': 'Documents List',
        'fileTypePdf': 'Size of each file uploaded should not exceed 4MB, file types allowed only pdf',
        'pleaseEnterDocName': 'Please enter document name',
        'pleaseEnterDocDesc': 'Please enter document description',
        'pleaseSelectDocType': 'Please select document type',
        'pleaseselectFile': 'Please select file',
        'signDoc': 'Sign',
        'signView': 'View',
        'viewDocumemnt': 'View Document',
        'page': 'Page',
        'downloadDoc': 'The document has been downloaded successfully.',
        'signDocument': 'Sign Document',
        'responsibleforsign': 'I’m fully responsible for the signed document',
        'signedBy': 'Signed By',
        'docValidityStatus': 'Validity',
        'download': 'Download',
        'sign': 'Sign',
        'signed': 'Signed',
        're-sign': 'Resign Document',
        'validText': 'Valid',
        'invalidText': 'Invalid',
        'docSignSuccessUAE': 'Document is signed successfully',
        'cancleSignProcess': 'User cancelled the signature process',
        'docSignatureFailed': 'Document signature failed',
        'signADoc': 'Sign a Document',
        'signHere': 'Sign Here',
        'editDocDetail': 'Edit Document Details',
        'docValidityUntill': 'Document Validity Until',
        'docSize': 'Document Size',
        'deletePopUp': 'Are you sure you want to DELETE this',
        'signNow': 'Sign Now',
        'signLater': 'I will sign it later',
        'docSavedSuccess': 'has been successfully saved to your documents list',
        'applicantName': 'Applicant name',
        'servicesType': 'Services Type',
        'ShowAll': 'Show All',
        'HideAll': 'Hide All',
        'complementryServices': 'Complementary services',
        'availableApplications': 'Available applications',
        'reasonsForCancelation': 'Reasons for cancelation',
        'changeService-Request': 'Applications to be Changed',
        'EservicesUserManual': 'User Manual',
        'Enterthereason': 'Enter the reason',
        'allowedToCancleTitle': 'Applications allowed to cancel',
        'allowedToCancleTicketsTitle': 'Allowed to cancel application tickets',
        'QuickServices': 'Quick Services',
        'GetHousingApplicationStatus': 'Get Housing Application Status',
        'GetMaintenanceRequestStatusForReadyHouse': 'Get Maintenance Request Status For Ready House',
        'GetPaymentsStatus': 'Get Payments Status',
        'Apply': 'Apply',
        'TermsAndConditions': 'Terms and conditions',
        'DocumentsUploadedMBRHE': 'Documents uploaded by MBRHE',
        'DocumentsUploadedCustomer': 'Documents uploaded by Customer',
        'viewTicket': 'View Tickets',
        'DescrMessage': 'Please enter minimum of 20 and maximum 200 characters description',
        'housing-remarks': 'Remark',
        'HowyourateWebsite': 'How do you rate the overall design of this website?',
        'Excellent': 'Excellent',
        'VeryGood': 'Very Good',
        'Good': 'Good',
        'Acceptable': 'Acceptable',
        'ThankYouFeedback': 'Thank You for feedback',
        'Home': 'Home',
        'themeSwitch': 'Theme switch',
        'useSwitchChangeTheme': 'Select the theme you would like to have',
        'MBRHETheme': 'MBRHE Theme',
        'redWeakness': 'Red Weakness',
        'greenWeekness': 'Green Weakness',
        'ColourBlind': 'Colour Blind',
        'textResize': 'Text resize',
        'ifYouHaveTroubleReadingText': 'If you have trouble reading text, you may enlarge or reduce the size',
        'readSpeaker': 'Read Speaker',
        'listenPage': 'Listen to the content of the page by clicking play on ReadSpeaker.',
        'downloadApp': 'Download App',
        'ThisExternalLink': 'This is an external link and it will take you outside the establishment website.',
        'doYouWantToContinue': 'Do you want to continue?',
        'PrintPage': 'Print page',
        'search': 'Search',
        'TotalResults': 'Total Results',
        'changeLanguage': 'Change language',
        'whatsapp': 'WhatsApp',
        'facebook': 'Facebook',
        'twitter': 'Twitter',
        'instagram': 'Instagram',
        'youtube': 'YouTube',
        'siteTour': 'Site Tour',
        'FindUs': 'Find Us',
        'BrowserSupport': 'Browser Support',
        'LastUpdated': 'Last Updated 19/05/2023',
        'CustomerCharter': 'Customer Charter',
        'InformationPolicy': 'Information Policy',
        'appStore': 'Download the Iskan app from App Store',
        'playStore': 'Download the Iskan app from Play Store',
        'InternetExplorer': 'Internet Explorer',
        'GoogleChrome': 'Google Chrome',
        'Safari': 'Safari',
        'Firefox': 'Firefox',
        'Chat': 'Chat',
        'valuableFeedback': 'Your valuable feedback regarding this page',
        'confidentiality': 'Confidentiality of Customers Information Charter',
        'search_title': 'Below you can refer to the search results for ',
        'tele': 'Toll Free',
        'OfficialWorkingHours': 'working hours',
        'tele': 'Call',
        'monFri': 'Monday to Friday: (7:30-22:00)',
        'OfficialWorkingHours': 'Working Hours',
        'MonThu': 'Monday to Thursday: 7:30-3:30',
        'Friday': 'Friday: 7:30-12:00',
        'SundayToThursday': 'Mon-Thu: 07:30am​​ to 02:30pm Fri: 07:30 to 12:00pm',
        'CustomerService': 'Customer service',
        'CustomerServiceTime': 'Mon-Thu: 07:30am​​ to 02:30pm Fri: 07:30 to 12:00pm',
        'constructionDisbursementContractRequests': 'Construction Loan Disbursement Contract Requests',
        'maintenanceDisbursementContractRequests': 'Maintenance Loan Disbursement Contract Requests',
        'constructionDisbursementContractHeading': 'Construction Loan Disbursement Contract',
        'maintenanceDisbursementContractHeading': 'Maintenance Loan Disbursement Contract',
        'requestform': 'Request Form',
        'viewdocument': 'View Document',
        'requestcompletion': 'Request Confirmation',
        'constructionDisbursementContract': 'Construction Loan Disbursement Contract Requests',
        'requestStatus': 'Request Status',
        'moreDetails': 'More Details',
        'step': 'Step',
        'fundType': 'Fund type',
        'city': 'City',
        'area': 'Area',
        'contractor_licence_number': 'Contractor license number',
        'contractor_name': 'Contractor name',
        'consultant_licence_number': 'Consultant license number',
        'consultant_name': 'Consultant name',
        'municipalityNumber': 'Municipality number',
        'landDetails': 'Land details',
        'tripleContractDetails': 'Triple contract details',
        'contractDate': 'Contract date',
        'demarcationDate': 'Demarcation date',
        'contractDurationMonths': 'Contract duration in months',
        'preparationMobilizationDuration': 'Preparation and mobilization duration',
        'delegator': 'Delegator',
        'delegation': 'Delegation',
        'delegatorName': 'Delegator name',
        'edbarahFileNo': 'Edbarah file no.',
        'delegationDate': 'Delegation date',
        'delegationPlace': 'Delegation place',
        'joint': 'Joint',
        'jointName': 'Joint name',
        'bankAccountNo': 'Bank account no',
        'relative': 'Relative',
        'addFile': 'Add File',
        'applicantDocuments': 'Applicant Documents',
        'numberOfFiles': 'Number Of Files',
        'passportCopy': 'Passport Copy',
        'idCopy': 'ID Copy',
        'familyBookCopy': 'Family Book Copy',
        'latestSalaryCertificate': 'Latest Salary Certificate',
        'housingLandMap': 'Housing Land map',
        'certificateZayedHousingProgram': 'Certificate from Zayed Housing Program',
        'aStatementHousingSupport': 'a statement about housing support',
        'ProofMaritalStatusCustodySupport': 'Proof of marital status with proof of custody and support',
        'inCaseApplicantStatusWidowDivorced': 'in case applicant is female with status widow or divorced',
        'proofContinuityMarriage': 'Proof of continuity of marriage',
        'incaseApplicantFemaleStatusMarriedForeigner': 'in case applicant is female with status married foreigner',
        'nationalDefenseCard': 'National Defense Card',
        'ifAvailable': 'if available',
        'contract': 'Contract',
        'triplContractConsultantApplicant': 'Triple Contract Contractor, Consultant and Applicant',
        'licenseBuild': 'License to Build',
        'demarcation': 'Demarcation',
        'percentageConstructionProjectConsultantLetter': 'Percentage Construction Project from Consultant Letter',
        'billMaterials': 'Bill of Materials',
        'validCommercialLicenseDED': 'Valid Commercial License from DED',
        'addressLetter': 'Address Letter',
        'federalTaxAuthorityLetter': 'Federal Tax Authority Letter',
        'IBANBankAccountLetter': 'IBAN or Bank Account Letter',
        'validProfessionalLicenseDED': 'Valid Professional License from DED',
        'documentTitle': 'Document Title',
        'submittedSuccessfully': 'Thank you your request has been submitted successfully. Your request is currently under review.',
        'backDashboard': 'Back to Dashboard',
        'installmentAmount': 'Installment amount',
        'numberInstallments': 'Number of installments',
        'repaymentInstallmentdurationYears': 'Repayment installment duration in years',
        'repaymentInstallmentDurationMonths': 'Repayment installment duration in months',
        'insuranceAmount': 'Insurance amount',
        'advancedInsuranceAmount': 'Advanced insurance amount',
        'needYourSignature': 'Need your Signature',
        'reSignature': 'Re-Sign Document',
        'UAEPassEsignature': 'UAEPass for e-signature',
        'DocumentStatus': 'Document status',
        'InsurancePayment': 'Insurance payment',
        'PayNow': 'Pay Now',
        'YourPaymentSuccessfully': 'Your payment has been done successfully',
        'MortgageFirstDegreeContractDetails': 'Mortgage first degree contract detail',
        'MortgageStartDate': 'Mortgage start date',
        'MortgageEndDate': 'Mortgage end date',
        'MortgageDuration': 'Mortgage duration',
        'applicationReview': 'Application review',
        'employmentFinanceInformation': 'Employment And Finance Information',
        'editPersonalInformation': 'Edit The Personal Information',
        'editEmploymentFinanceInformation': 'Edit The Employment And Finance Information',
        'editDocuments': 'Edit The Documents',
        'monthlySalary': 'Total gross salary',
        'financialCapacity': 'Financial Capability',
        'incomeMessage': 'If the monthly income is less than or equal 15,000 AED, a financial ability of no less than 500,000 AED must be proven.',
        'landNumberMessage': 'Enter the land number that exists on the Dubai Municipality map.',
        'financialCapabilityMessage': 'The Financial capability should be minimum of 500000 AED.',
        'complemantryRequests': 'Complementary Services Requests',
        'liveChatSupport': 'live chat Support',
        'donothaveaccount': 'Don\'t have UAEPASS account?',
        'createnew': 'Create new account',
        'recoveraccount': 'Recover your account',
        'contractoraccountlogininfo': 'The Contractor user will be activated once the project file/ contract is created by the Engineering department',
        'typicalvillas': 'Typical Villa Templates',
        'startingprice': "Starting price",
        'floors': 'Floors',
        'bedrooms': 'Bedrooms',
        'areyousick': "Do you have any type of illness?",
        'typeofdisability': 'Type of Disability',
        'typeofdisease': 'The type of disease',
        'typicalvillasnoteone': 'Prices rise in accordance with the location and nature of the land, the type of air conditioning, and the models shape, with the caveat that the prices do not include consultant fees, wardrobes for dressing rooms and kitchens, gypsum work, car awnings, electrical pendants, municipality fees, electricity and water connection fees, from DEWA.',
        'typicalvillasnotetwo': 'The fee includes a 5% value added tax which is refundable if the Federal Tax Authority issues a completion certificate) and a 50,000 dirham reserve that is refundable if it is not used.',
        'typicalvillasnotethree': 'The project will begin as soon as a suitable contractor is available.',
        'typicalvillasnotefour': 'The MBRHE is not liable if the cost of construction varies as a result of price fluctuations in the local market.',
        'typicalvillasnotefive': 'Receipt and acceptance of an application does not always imply approval.',
        'typicalvillasnotesix': 'استلام وقبول الطلب لا يعني بالضرورة الموافقة عليه.',
        'amountpaidby': 'How I will pay the balance amount',
        'cash': 'Cash',
        'bank': 'Through the Bank (Yusor 2)',
        'viewRequest': 'View request details',
        'backToHome': 'Back to home page',
        'requestSuccess': 'Your request has been sent successfully...!! Your request is now under review',
        'sqft': 'SQ,FT',
        'applyforservice': 'Apply for the service',
        'villadetails': 'Villa details',
        'engineername': 'Engineer Name',
        'consultantcompany': 'Consultant Company',
        'villatype': 'Villa type',
        'serviceform': 'Service Form',
        'confirmation': 'Request Confirmation',
        'livechat': 'Live Chat',
        'servicesteps': 'Service Steps',
        'applyforrequest': 'Apply for Typical Villa request and communicate with the consultant for further details',
        'requestunderprocess': 'Request under process from the Establishment',
        'amountpayment': 'Balance amount payment',
        'establishmentstep': 'The Establishment will follow up with the building activities',
        'submitrequest': 'Submit Request',
        'homatalwatn': 'Are you one of Humat Al Wattan?',
        'typeofsickness': 'Type of sickness',
        'SocialInformation': 'Social Information',
        'females': 'Females',
        'males': 'Males',
        'NumberOfSingleFamilyMembersInHouse': 'Number of single family members in the house',
        'NumbeFfMarriedFamilyMembersInHouse': 'Number of married family members in the house',
        'NumberOfElderyFamilyMembers60Plus': 'Number of elderly members (+60)',
        'NumberOfPeopleDeterminationInFamily': 'Number of people of determination in family',
        'IsHouseWellequippedForPeopleOfDeterminationNeed': 'Is the house well-equipped for people of determination?',
        'HouseAdditionComponents': 'House Addition Components',
        'ServiceExtension': 'Service Extension',
        'FirstFloor': 'First Floor',
        'MasterBedroom': 'Master Bedroom',
        'MasterMajilis': 'Master Majilis',
        'PreperationalKitchen': 'Preperational Kitchen',
        'Salle': 'Salle',
        'ExtensionAdditionComponents': 'Extension Addition Components',
        'ExternalKitchen': 'External Kitchen',
        'StorageRoom': 'Storage Room',
        'LaundryRoom': 'Laundry Room',
        'HelpingCrewsMasterBedroom': 'Helping Crews Master Bedroom',
        'DriversMasterBedroom': 'Drivers Master Bedroom',
        'NursesMasterBedroom': 'Nurses Master Bedroom',
        'Parking': 'Parking',
        'ModelVideo': 'Model video',
        'share': 'Share',
        'consultantInformation': 'Consultant Information',
        'remarks': 'Remarks',
        'step1': 'Complete the Service Form',
        'step2': 'Attach Required Documents',
        'step3': 'Sign Required Documents',
        'step4': 'Insurance Payment',
        'complete': 'Complete',
        'transactionNumber': 'Transaction Number',
        'amountPaid': '',
        'doYouHaveMonthlyDeductions': 'Do You Have Monthly Deductions?',
        'toWhomItMayConcernInfo': 'سيتم اصدار شهادة لمن يهمه الأمر فور التقديم على الطلب.',
        'whomItMayConcernCertificate': 'Whom it may concern certificate',
        'printCertificate': 'Print certificate',
        'DownloadTheCertificate': 'Download certificate',
        'exit': 'Exit',
        'enterTheOneTimePasscode': 'An OTP has been sent to your mobile number ending',
        'tohomeOtpInfo': 'In case the mobile was not accessible by you, send a request to update your information saved in your profile here',
        'OTPsendYourRegisteredNumber': 'An OTP has been sent to your mobile no.',
        'certicateWillBeIssuedOnceYouSubmit': 'The "To Whom It May Concerns" certicate will be issued once you submit the application',
        'towhominfomsg': 'The "To Whom It May Concerns" certificate will be issued once you submit the application',
        'nodataavaialble': 'No data available',
        'complementaryservices': 'Complementary Services',
        'downloadapprovalletter': 'Approval Letter',
        'downloadliabilitycertificate': 'Liability Certificate',
        'morefinancialdetails': 'More Financial Details / Project Payments',
        'discountamount': 'Early Settlement Discount Amount',
        'totaldiscountamount': 'Total Payment Including Early Settlement Discount',
        'approvedvalue': 'Service Amount',
        'lastinstallmentdate': 'Last Installment Date',
        'arrears': 'Arrears',
        'applicantname': 'Applicant Name',
        'monthlyinstallment': 'Monthly Installment with Insurance',
        'outstandingamount': 'Outstanding Amount',
        'totalpaidamount': 'Total paid amount',
        'loantype': 'Loan Type',
        'totalpaidarrears': 'Total Paid Arrears',
        'remainingamount': 'Remaining Amount',
        'issuedate': 'Issue Date',
        'firstinstalmentdate': 'First Installment Date',
        'financialdetails': 'Financial Details',
        'paymentofarrears': 'Payment Of Arrears',
        'earlysettlementheading': 'Early Settlement Details',
        'earlysettlement': 'Early Settlement',
        'projectpaymentdetails': "Project Payment Details",
        'tryagain': "Try Again",
        'description': "Description",
        'mbrhe': "(MBRHE)",
        'referencenumber': 'Reference number',
        'dsg': '(DSG)',
        'applicationservicename': 'Application service name',
        'typicalvillasrequestnumber': 'Request number',
        'typicalvillasrequeststatus': 'Request status',
        'typicalvillasrequestdate': 'Request date',
        'secondwifeEID': 'Second wife Emirates ID',
        'contactUS': 'CONTACT US',
        'opendata': 'OPEN DATA',
        'openData': 'Open Data',
        'Services': 'SERVICES',
        'loanTerms': 'It acknowledged that the information provided in the request for the housing service as well as the documents are valid and that Mohammed Bin Rashid Housing Establishment has the right to request any additional documents or information declaration may be required later, and I promise to inform the organization of any changes in my marital or financial status, in case it is proved the invalidity of the information or documents, the institution has the right to take appropriate measures are against me by law. In addition, MBRHE has the right to cancel the request. I have no objection for sharing my submitted data and information with the concerned government authorities and approved financing entities to complete the service application procedures, I pledge that I have no objection to the disclosure of Information of AECB. I pledge that I have no objection to the disclosure of the account statement issued by the Central Bank.',
        'monthlyIncome': 'Monthly income',
        'housecompletiondate': 'House Completion Date',
        'msg30years': 'you are not eligible for this service, your house completion date is less than 30 years',
        'msg10years': 'you are not eligible for this service, your house completion date is less than 10 years',
        'careers': 'Careers',
        'iskanapp': 'Iskan App',
        'polls': 'Polls',
        'AIchat': 'Dubai AI',
        'peopleofdetermination': 'People Of Determination',
        'screenreader': 'Screen Reader',

        'peopleOfDeterminationServices': 'People of Determination Services',
        'facilitiesandprivileges': 'Facilities and privileges',
        'peopleOfDeterminationCharter': 'People Of Determination Charter',
        'initiatives': 'Initiatives',
        'visitoffice': 'Please visit MBRHE office'

    }
}