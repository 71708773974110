var BASE_URL = process.env.API_BASE_URL
var PROXY_HOST = process.env.PROXY_HOST
var E_KEY = process.env.E_KEY
export default {
  BASE_URL: BASE_URL,
  LOGIN_URL: BASE_URL + 'oauth2/token?local=',
  CONTRACTOR_LOGIN_URL: PROXY_HOST + '/eservices/contractor/login?locale=',
  LOGIN_USER_PASSWORD_OR_OTP: 'LOGIN_USER_PWD_OR_OTP',
  CONTRACTOR_LOGIN_OTP_URL: BASE_URL + 'contractor/v1/otp?userName=',
  CUSTOMER_LOGIN_OTP_URL: BASE_URL + 'customer/account/v1/otp?idn=',
  LOGIN_USER_OTP: 'LOGIN_USER_OTP',
  E_KEY: E_KEY
}