import React from "react";
import History from "../../History";
import Loader from "../../Customer/utils/LoaderBars";
import CommonUtils from '../../utils/CommonUtils';
import ContractorProjectPaymentsStore from '../ContractorProjectPayments/ContractorProjectPaymentsStore';
import ContractorProjectPaymentsService from '../ContractorProjectPayments/ContractorProjectPaymentsService';
import { ToastContainer, toast } from 'react-toastify';
import '../../reactToastify.css';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import ContractorProjectEnum from "../../constants/ContractorProjectEnum";

class RequiredDocumentsComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.state = {
            uploadBg: false,
            viewBg: false,
            showBg: false,
            isPaymentOwner: false,
            dashboardResponse: this.props.dashboardResponse,
            attachmentList: [],
            docCategoryHeader: '',
            isViewAttachmentSuccess: '',
            isDeleteAttachmentSuccess: false,
            isRefresh: false,
            file: '',
            showLoading: false,
            isClickFinished: false,
            viewAttachmentResponse: '',
            apiStatus: Number(localStorage.getItem('statusId'))
        }
        this.getAttachmentState();
        this._onChange = this._onChange.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.onFinished = this.onFinished.bind(this);
        this.hidebg = this.hidebg.bind(this);
        // console.log(props)
    }

    setCheckBoxOnSecondStep() {
        this.props.setCheckBoxOnSecondStep();
    }

    getLatestResponse() {
        var response = this.props.getLatestValue();
        return response.paymentOwner;
    }

    componentDidMount() {
        if (this.state.apiStatus == 6) {
            this.setCheckBoxOnSecondStep();
        }
        if (!this.state.response) {
            this.getAllAttchments();
        }
        ContractorProjectPaymentsStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        ContractorProjectPaymentsStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState(this.getAttachmentState());
        this.setState(this.getViewDocument());
        this.setState(this.getDeleteDocument());
        this.setState(this.uploadDocState());
        this.setState(this.submitStatus())
        if (this.state.isRefresh) {
            if (this.state.file) {
                if (this.state.isUploadSuccess) {
                    toast.success(this.state.uploadMessage);
                } else {
                    this.setResponseText(this.state.submitMessage);
                }
                this.setState(({ file: '' }));
            } else {
                toast.success(translate('processSuccess'));
            }
            this.getAllAttchments();
            this.setState({ isRefresh: false });
        }
        if (this.state.isClickFinished) {
            if (this.state.isSubmitSuccess) {
                toast.success(this.state.submitMessage);
            } else {
                this.setResponseText(this.state.submitMessage);
            }
            this.setState({ isClickFinished: false });
        }
    }

    setResponseText(submitMessage) {
        if (submitMessage.includes('<br >')) {
            toast.error(<div>{translate('pleaseUploadDoc')}<br /> {this.splitText(submitMessage)}</div>);
        } else {
            toast.error(<div>{submitMessage}</div>);
        }
    }
    splitText(str) {
        return str.split('<br >').pop();
    }

    addClick(value) {
        this.setState({
            uploadBg: !this.state.uploadBg,
            addFile: value
        })
    }

    viewClick(data) {
        this.setState({
            attachmentList: data.attachments,
            docCategoryHeader: data,
            viewBg: !this.state.viewBg
        })
    }

    showClick(id) {
        const attachmentId = id;
        this.setState({
            showLoading: true,
            showBg: !this.state.showBg,
            viewAttachmentResponse: ''
        })
        ContractorProjectPaymentsService.contractorViewDocumemnt(this.getContractorEntryId, attachmentId, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    hidebg() {
        this.setState({
            showBg: !this.state.showBg
        })
    }

    deleteClick(id) {
        var attachmentId = id;
        this.setState({
            showLoading: true,
            viewBg: !this.state.viewBg,
            isRefresh: true
        })
        ContractorProjectPaymentsService.contractorDeleteDocumemnt(this.getContractorEntryId, attachmentId, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    getViewDocument() {
        return {
            showLoading: false,
            isViewAttachmentSuccess: ContractorProjectPaymentsStore.isViewDocumentSuccess(),
            viewAttachmentResponse: ContractorProjectPaymentsStore.getViewDocumentResponse,
        };
    }

    getDeleteDocument() {
        return {
            showLoading: false,
            isDeleteAttachmentSuccess: ContractorProjectPaymentsStore.isDeleteDocumentSuccess()
        };
    }

    backPress() {
        History.push('/')
    }
    getAttachmentState() {
        return {
            showLoading: false,
            response: ContractorProjectPaymentsStore.getAllDocumentsResponse,
            isSuccess: ContractorProjectPaymentsStore.isGetAllDocumentsSuccess()
        };
    }

    get getContractorEntryId() {
        var entryStatusId;
        if (this.state.dashboardResponse.contractorEntryId) {
            entryStatusId = this.state.dashboardResponse.contractorEntryId
        } else {
            entryStatusId = this.props.requestID ? this.props.requestID : this.state.dashboardResponse.requestId
        }
        return entryStatusId;
    }

    getAllAttchments() {
        this.setState({ showLoading: true });
        ContractorProjectPaymentsService.contractorGetAllDocumemnts(this.getContractorEntryId, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    catchBody(error) {
        var parseError = JSON.parse(error.response);
        var errorDescription = parseError.error_description;
        console.log("Error logging in : ", errorDescription);
    }

    submitHandler = () => {
        this.props.handlerFromParant(this.state.message, this.state.response);
    }

    uploadFile(e) {
        const files = e.target.files[0]
        if (CommonUtils.isValidFileType(files) && CommonUtils.isValidFileSize(files)) {
            this.setState({
                showLoading: true,
                file: files,
                isRefresh: true,
                uploadBg: !this.state.uploadBg,
            })
            ContractorProjectPaymentsService.contractorUploadDocumemnts(this.getContractorEntryId, files, this.state.addFile.id, this.context.state.siteLang)
                .catch(function (error) {
                    this.catchBody(error);
                }.bind(this));
        } else {
            toast.error(translate('fileSize'))
        }
    }

    uploadDocState() {
        return {
            // showLoading: false,
            uploadMessage: ContractorProjectPaymentsStore.uploadDocumentMessage,
            isUploadSuccess: ContractorProjectPaymentsStore.isUploadDocumentSuccess()
        };
    }

    submitStatus() {
        return {
            submitMessage: ContractorProjectPaymentsStore.getSubmitMessage,
            isSubmitSuccess: ContractorProjectPaymentsStore.isSubmitSuccess()
        };
    }

    get getContractId() {
        var contractId;
        if (this.state.dashboardResponse.contractId) {
            contractId = this.state.dashboardResponse.contractId
        } else {
            contractId = this.state.dashboardResponse.id
        }
        return contractId;
    }

    alertCloseSecond = () => {
        this.setState({
            isPaymentOwner: !this.state.isPaymentOwner
        })
    }

    setSelectedInput = () => {
        this.props.selectInput();
    }


    getCheckBoxState = () => {
        var isCheckBoxChecked = this.props.checkCheckBoxState();
        return isCheckBoxChecked;
    }

    onFinished() {
        if (this.state.apiStatus == 3 || this.state.apiStatus == 6) {
            this.sendApiCAll();
            this.setState({
                isClickFinished: true
            })
        } else {
            if (this.getCheckBoxState()) {
                if (this.getLatestResponse() > 0) {
                    this.setStateAndSendCall();

                } else {
                    this.setState({
                        isPaymentOwner: true
                    })
                    this.setSelectedInput();
                }
            } else {
                this.setStateAndSendCall();
            }
        }
    }

    setStateAndSendCall() {
        this.setState({
            isClickFinished: true,
            isPaymentOwner: false
        })
        this.sendApiCAll();

    }

    sendApiCAll() {
        ContractorProjectPaymentsService.contractorSubmitStatus(this.getContractorEntryId,
            this.state.dashboardResponse.contractorId, this.getContractId, ContractorProjectEnum.SubmitTransactionType, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    render() {
        const { showLoading, isClickFinished, apiStatus, isSuccess, response, attachmentList, docCategoryHeader, viewAttachmentResponse, addFile } = this.state;
        if (viewAttachmentResponse != '') {
            var str = viewAttachmentResponse;
            // var viewDoc = str.fileendsWith(".pdf");
        }
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={5000} position={toast.POSITION.TOP_CENTER} />
                {/* LOADER */}
                {isClickFinished ? <div className="PopupBk"></div> : null}
                {isClickFinished ? <div className="loader">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <div className="bar4"></div>
                    <div className="bar5"></div>
                    <div className="bar6"></div>
                </div> : null}
                {/* END-LOADER */}
                {/* Upload File */}
                {this.state.uploadBg ? <div className="PopupBk" onClick={this.addClick.bind(this)}></div> : null}
                {this.state.uploadBg ? <div className="uploadPopup">
                    <button className="popupClose" onClick={this.addClick.bind(this)}>X</button>
                    <h2>{addFile.name}</h2>
                    <input type="file" id="fileUpload" onChange={this.uploadFile}></input>
                    <label htmlFor="fileUpload">{translate('dragAndDrop')}</label>
                    <p>{translate('fileSize')}</p>
                </div> : null}
                {/* End Upload File */}

                {/* View File */}
                {this.state.viewBg ? <div className="PopupBk" onClick={this.viewClick.bind(this)}></div> : null}
                {this.state.viewBg ? <div className="viewPopup">
                    <button className="popupClose" onClick={this.viewClick.bind(this)}>X</button>
                    <h2>{docCategoryHeader.name}</h2>
                    {attachmentList ? attachmentList.map((attachments, index) => {
                        return (
                            <div key={index}>
                                <div className="row viewPopupContant">
                                    <div className="col-md-7">{attachments.attachmentName}</div>
                                    <div className="col-md-5 project_step_upload_btns">
                                        <button onClick={this.showClick.bind(this, attachments.id)}><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/eye_icon.svg" alt="view" /> {translate('view')}</button>
                                        <button onClick={this.deleteClick.bind(this, attachments.id)}><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/delete.svg" alt="delete" /> {translate('delete')}</button>
                                    </div>
                                </div>
                            </div>
                        );
                    }) : null}
                </div> : null}
                {/* End View File */}

                {/* Show File */}
                {this.state.showBg && str ? <div className="uploadPopup">
                    <button className="popupClose" onClick={this.hidebg}>X</button>
                    {CommonUtils.getFileComponent(str)}
                </div> : null}
                {/* {this.state.showBg ? <div className="PopupBk" ></div> : null}
                {this.state.showBg ? <div className="uploadPopup">
                    <button className="popupClose" onClick={this.hidebg.bind(this)}>X</button>
                    <div className="popupShowFile">
                        {viewDoc ? <iframe src={viewAttachmentResponse} title="PDF"></iframe> :
                            <img src={viewAttachmentResponse} alt="" />}
                    </div>
                </div> : null} */}
                {/* End Show File */}
                {showLoading ? <Loader /> : null}
                {isSuccess && response ? response.map((value, index) => {
                    return (
                        <div key={index}>
                            <div className="row project_step_upload_Section">
                                <div className="col-lg-5">
                                    <span>{value.name}</span>
                                </div>
                                <div className="col-lg-3">
                                    <span>{translate('documentCount')} : </span>
                                    <span>{value.addedCount}</span>
                                </div>
                                <div className="col-lg-4 project_step_upload_btns">
                                    <button onClick={this.addClick.bind(this, value)}><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/add_blue.svg" alt="add" /> {translate('add')}</button>
                                    {value.addedCount ? <button className="modifiBtn" onClick={this.viewClick.bind(this, value)}><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/edit.svg" alt="view Modify" /> {translate('viewModify')}</button> : null}
                                </div>
                            </div>
                        </div>
                    );
                }) : null}

                <div className="upload_bot_text">{translate('allAttachedDoc')}</div>
                <br />
                <p>{translate('atThisStageApplication')}</p>
                {this.state.isPaymentOwner && apiStatus == 1 ?
                    <div className="alert alert alert-danger" role="alert">
                        <button onClick={this.alertCloseSecond.bind(this)} type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        {translate('kindlySpecify')}
                    </div> : null}
                {this.redirect()}
                {apiStatus == 1 ? <div className="row project_step_upload_finishBtn singleStepBtn">
                    <div className="col">
                        <button onClick={this.onFinished}>{translate('finish')}</button>
                    </div>
                </div> : <div className="row project_step_upload_finishBtn">
                    <div className="col">
                        <button onClick={this.onFinished}>{translate('finish')}</button>
                    </div>
                </div>}
            </div>
        )
    }

    redirect() {
        return (
            <div>
                {this.state.isSubmitSuccess ? <Redirect
                    to={{
                        pathname: "/projectsList"
                    }}
                /> : null}
            </div>
        )
    }
}

export default RequiredDocumentsComponent;