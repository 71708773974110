var BASE_URL = process.env.API_BASE_URL
var GOOGLE_MAP_KEY = process.env.GOOGLE_MAP_KEY
var BASE_URL_DC = 'https://apis.mbrhe.gov.ae/sitecore/api/v1/DSC'
const CONSTANTS = {
    requiredErrorMessage: 'Fields with * are required, please fill them with proper value',
    authToken: 'bearer ' + localStorage.getItem('accessToken'),
    dashboardEndpoint: BASE_URL + 'customer/account/v1/dashboard',
    applicationStatusEndpoint: BASE_URL + 'customer/account/v1/applications',
    liabilityCertificateEndpoint: BASE_URL + 'Financial/v1/liability/download',
    approvedLetterEndpoint: BASE_URL + 'Financial/v1/ApprovalLatter/download',
    typicalVillasApplicationsEndpoint: BASE_URL + 'typicalvillas/v1/applications',
    housingrequestsEndpoint: BASE_URL + 'iskan/v1/service-request/list',
    awarnessesEndpoint: BASE_URL + 'soun/v1/awareness/v1/list',
    housingprojectEndpoint: BASE_URL + 'soun/v1/projects/list',
    financialStatusEndpoint: BASE_URL + 'iskan/v1/financial/status',
    notificationsEndpoint: BASE_URL + 'customer/account/v1/notifications',
    deleteNotificationsEndpoint: BASE_URL + 'customer/account/v1/notifications/delete/',
    contractorPaymentsEndpoint: BASE_URL + 'iskan/v1/contractor/payments',
    contractorPaymentsRequestsEndpoint: BASE_URL + 'iskan/v1/contractor/paynding-payments/list',
    updateProfileEndpoint: BASE_URL + 'customer/account/v1/contact/update',
    updateContractorProfileEndpoint: BASE_URL + 'contractor/v1/contact/update?local=',
    contractorProfilePasswordEndpoint: BASE_URL + 'contractor/v1/changepassword?local=',
    calculateLoanEndpoint: BASE_URL + 'public/v1/calculator/trialloan',
    calculateConstructionCostEndpoint: BASE_URL + 'public/v1/calculate/housebuildcost',
    getEligibleServicesEndPoint: BASE_URL + 'iskan/v1/service-request/init/services',
    getNewServicesEndPoint: BASE_URL + 'iskan/v1/Dashboard/services',
    generateTowhomCertificateEndPoint: BASE_URL + 'iskan/v1/certificates/towhomitmayconcern',
    generateTowhomCertificateEndPointNew: BASE_URL + 'customer/account/v1/chatbot/otp',
    generateToWhomNewCertificateEndPoint: BASE_URL + 'certificate/GeneratePdf',
    // generateTowhomCertificatePublicEndPoint: BASE_URL + 'certificate/GenerateCertificateAsPdf',
    // generateHousingRequestEndPoint: BASE_URL + 'iskan/v1/service-request/submitinsertupdate/step/',
    generateHousingRequestEndPoint: BASE_URL + 'iskan/v1/service-request/submitinsertupdate',
    // generateHousingRequestEndPoint: BASE_URL + 'iskan/v1/service-request/book',
    // viewHousingRequestEndPoint: BASE_URL + 'iskan/v1/service-request/view',
    viewHousingRequestEndPoint: BASE_URL + 'iskan/v1/service-request/viewrequest',
    postHousingRequestStepEndPoint: BASE_URL + 'iskan/v1/service-request/submit/step',
    deleteHousingRequestTicketEndPoint: BASE_URL + 'iskan/v1/service-request/delete',
    housingTicketRequiredDocsEndPoint: BASE_URL + 'iskan/v1/service-request/list/attachments',
    housingTicketPostDocsEndPoint: BASE_URL + 'iskan/v1/service-request/attach',
    housingTicketViewDocsEndPoint: BASE_URL + 'iskan/v1/service-request/attachment/view',
    housingTicketDeleteDocsEndPoint: BASE_URL + 'iskan/v1/service-request/remove/attachment',
    getLinkAccountRequestTokenEndPoint: BASE_URL + 'customer/account/v1/register/requestToken',
    linkGuestUserAccountEndPoint: BASE_URL + 'customer/account/v1/register/as-guest',
    linkCustomerAccountEndPoint: BASE_URL + 'customer/account/v1/register/requestpin',
    linkCustomerAccountOTPEndPoint: BASE_URL + 'customer/account/v1/register/complete-as-customer',
    updateSettingsEndPoint: BASE_URL + 'customer/account/v1/settings/update',
    maintananceRequestsEndPoint: BASE_URL + 'iskan/v1/maintenance/myrequests',
    maintananceProblemsRequestEndPoint: BASE_URL + 'soun/v1/problems/list',
    maintananceAppointmentsRequestEndPoint: BASE_URL + 'iskan/v1/maintenance/calendar/appointments/list',
    maintananceRequestEndPoint: BASE_URL + 'iskan/v1/maintenance/request',
    documentEndPoint: 'https://apiqa.mrhecloud.com/documentVerification/verifyPdfByQR?referenceNo=',
    obligationOTPEndPoint: BASE_URL + 'iskan/v1/service-request/land-grant/sign/request-otp',
    obligationVerifyEndPoint: BASE_URL + 'iskan/v1/service-request/land-grant/sign/validate-otp',
    houseBookingFiltersEndPoint: BASE_URL + 'housebooking/v1/filter',
    houseBookingVillasGroupsEndPoint: BASE_URL + 'housebooking/v1/properties/groups',
    houseBookingPropertyDetailsEndPoint: BASE_URL + 'housebooking/v1/property',
    houseBookingRelatedPropertyDetailsEndPoint: BASE_URL + 'housebooking/v1/RelatedProperties',
    houseBookingMyBookingsEndPoint: BASE_URL + 'housebooking/v1/property/mybooking',
    houseBookingPrintMyBookingsEndPoint: BASE_URL + 'housebooking/v1/property/initialbookingPDF',
    maintenanceInsuranceEndpoint: BASE_URL + 'soun/v1/insurance/list',
    earlySettlementRequestEndpoint: BASE_URL + 'iskan/v1/request/discount/',
    deleteMaintenanceRequestEndpoint: BASE_URL + 'iskan/v1/maintenance/cancel/',
    customerContractorPayments: BASE_URL + 'customer/account/v1/contractor/payments',
    fetchContractorPaymentImages: BASE_URL + 'customer/account/v1/contractor/Paymentattachment/list',
    fetchContractorPaymentImagesUrl: BASE_URL + 'contractor/v1/service-request/Paymentattachment/view',
    pendingPaymentProcessEndpoint: BASE_URL + 'iskan/v1/contractor/pending-payment/process',
    generateCaptchaEndpoint: BASE_URL + 'vcaptcha/v1/generate',
    validateCaptchaEndpoint: BASE_URL + 'vcaptcha/v1/image/try',
    generateAudioCaptchaEndpoint: BASE_URL + 'vcaptcha/v1/audio/listen',
    validateAudioCaptchaEndpoint: BASE_URL + 'vcaptcha/v1/audio/try',
    houseBookingBookHouseEndpoint: BASE_URL + 'housebooking/v1/property/book',
    houseBookingePayEndpoint: BASE_URL + 'housebooking/v1/epay/register',
    houseBookingCompleteePayEndpoint: BASE_URL + 'housebooking/v1/epay/complete',//?local={0}&spTrn={1}
    overduePaymentsRegisterEndpoint: BASE_URL + 'iskan/v1/paymentdues/epay/register',
    overduePaymentsCompleteEndpoint: BASE_URL + 'iskan/v1/paymentdues/epay/complete?sptrn={0}&local={1}',

    complementaryServicesEndpoint: BASE_URL + 'iskan/v1/SmartApp/GetComplementaryService/List',
    ticketsListingEndpoint: BASE_URL + 'iskan/v1/SmartApp/HouseServiceTicket/List',

    // changeServiceTicketsListingEndpoint: BASE_URL + 'iskan/v1/SmartApp/ChangeService/list',
    changeServiceTicketsListingEndpoint: BASE_URL + 'iskan/v1/SmartApp/ChangeService/AllowToChangeList',
    changeServiceSubmitEndpoint: BASE_URL + 'iskan/v1/SmartApp/ChangeService/Submit',
    changeServiceCompleteEndpoint: BASE_URL + 'iskan/v1/SmartApp/ChangeService/Complete',

    changeServiceViewTicketEndpoint: BASE_URL + 'iskan/v1/SmartApp/ChangeService/View',

    changeServiceRequiredDocumentListEndpoint: BASE_URL + 'iskan/v1/SmartApp/ChangeService/RquiredAttachments',
    changeServiceUploadDocumentEndpoint: BASE_URL + 'iskan/v1/service-request/attach',
    changeServiceViewDocumentEndpoint: BASE_URL + 'iskan/v1/service-request/attachment/view',
    changeServiceDeleteDocumentEndpoint: BASE_URL + 'iskan/v1/service-request/remove/attachment',

    // listApplicationsAllowedToChangeEndpoint: BASE_URL + 'iskan/v1/SmartApp/ChangeService/AllowToChangeList',
    cancelServiceTicketsListingEndpoint: BASE_URL + 'iskan/v1/SmartApp/CancelApplication/Request/list',
    cancelRequestViewTicketEndpoint: BASE_URL + 'iskan/v1/SmartApp/CancelApplication/View',
    cancelRequestCompleteEndpoint: BASE_URL + 'iskan/v1/SmartApp/CancelApplication/Complete',

    listApplicationsAllowedToCancelEndpoint: BASE_URL + 'iskan/v1/SmartApp/CancelApplication/List',
    applicationReasonsListEndpoint: BASE_URL + 'iskan/v1/SmartApp/ReasonList',

    applicationCancelSubmitEndpoint: BASE_URL + 'iskan/v1/SmartApp/CancelApplication/Submit',
    happinessMeterSettingsEndpoint: BASE_URL + '/public/v1/HappinessMeter/settings',
    //Housing Request Lookups
    jobStatusLookupsEndpoint: BASE_URL + 'iskan/v1/lookup/JobStatus',
    govHelpTypesLookupsEndpoint: BASE_URL + 'iskan/v1/lookup/govHelpTypes',
    relationTypesLookupsEndpoint: BASE_URL + 'iskan/v1/lookup/relationTypes',
    grantorsLookupsEndpoint: BASE_URL + 'iskan/v1/lookup/grantors',
    emiratesLookupsEndpoint: BASE_URL + 'iskan/v1/lookup/emirates',
    socialStatusLookupsEndpoint: BASE_URL + 'iskan/v1/lookup/SocialStatus',
    searchApiEndpoint: BASE_URL + 'public/v1/search/details',
    typicalVillasApiEndpoint: BASE_URL + 'typicalvillas/v1/TypicalVillas',
    typicalVillasFiltersApiEndpoint: BASE_URL + 'typicalvillas/v1/filter',
    typicalVillasConsultantsApiEndpoint: BASE_URL + 'typicalvillas/v1/consultant/list',
    typicalVillasSubmitApiEndpoint: BASE_URL + 'typicalvillas/v1/submit',
    typicalVillasRequestsListApiEndpoint: BASE_URL + 'typicalvillas/v1/list',
    typicalVillasViewRequestEndpoint: BASE_URL + 'typicalvillas/v1/view',
    typicalVillasApplicationsEndpoint: BASE_URL + 'typicalvillas/v1/applications',

    //Loan disbursement
    getApplicationEndpoint: BASE_URL + 'iskan/v1/smartApp/constructionLoan/getapplicationallowtoloandisbusrsement',
    getRelationTypesEndpoint: BASE_URL + 'iskan/v1/lookup/getrelationtypes',
    getRegionsEndpoint: BASE_URL + 'iskan/v1/lookup/getregions',
    getFundTypesEndpoint: BASE_URL + 'iskan/v1/lookup/getfundtypes',
    getBanksEndpoint: BASE_URL + 'iskan/v1/lookup/getbanks',
    postDisbursementDataEndpoint: BASE_URL + 'iskan/v1/smartApp/constructionLoan/submit',
    getDisbursementTicketDataEndpoint: BASE_URL + 'iskan/v1/smartApp/constructionLoan/View',
    getPaymentInformationEndpoint: BASE_URL + 'iskan/v1/smartApp/constructionLoan/epay/getpaymentdetails',
    getDisbursementRequiredDocumentsEndpoint: BASE_URL + 'iskan/v1/smartApp/constructionLoan/attachments/list',
    uploadDisbursementDocumentEndpoint: BASE_URL + 'iskan/v1/service-request/attach',
    viewDisbursementDocumentEndpoint: BASE_URL + 'iskan/v1/service-request/attachment/view',
    deleteDisbursementDocumentEndpoint: BASE_URL + 'iskan/v1/service-request/remove/attachment',
    finishLoanDisbursementEndpoint: BASE_URL + 'iskan/v1/smartApp/constructionLoan/complete',
    finishSignLoanDisbursementEndpoint: BASE_URL + 'iskan/v1/smartApp/constructionLoan/completeSign',
    changeStatusLoanDisbursementEndpoint: BASE_URL + 'iskan/v1/smartApp/constructionLoan/requestdocument',
    validateDocumentsSignatureEndpoint: BASE_URL + 'iskan/v1/smartApp/constructionLoan/completesigned',
    getDocumentsToSignListEndpoint: BASE_URL + 'iskan/v1/smartApp/constructionLoan/attachments/requiresign/list',
    // getDocumentsToSignEndpoint: BASE_URL + 'iskan/v1/smartApp/constructionLoan/attachments/download',
    downloadDocumentFileEndpoint: BASE_URL + 'iskan/v1/smartApp/constructionLoan/attachments/download',
    unifiedVillasImageURL: "https://mbrhe-cdn.azureedge.net/eservices-portal/unifiedvillas/villa_type_images/",
    unifiedVillasImageNewURL: "https://mbrhe-cdn.azureedge.net/eservices-portal/unifiedvillas/new_villa_types/villa/",
    unifiedVillasPdfURL: "https://mbrhe-cdn.azureedge.net/eservices-portal/unifiedvillas/villa_details_pdf/",
    requestLoanDisbursementPaymentURL: BASE_URL + "iskan/v1/smartApp/constructionLoan/epay/register",
    completeRequestLoanDisbursementPaymentURL: BASE_URL + "iskan/v1/smartApp/constructionLoan/epay/complete",
    googleMapKey: GOOGLE_MAP_KEY,
    socialTechnicalStudyPostEndpoint: BASE_URL + "iskan/v1/smartApp/socialtechnicalstudy/submit/",
    socialTechnicalStudyGetEndpoint: BASE_URL + "iskan/v1/smartApp/socialtechnicalstudy/View/",
    updateSignedDocStatusEndpoint: BASE_URL + "iskan/v1/smartApp/constructionLoan/attachments/signattachment",
    financialStatusDetailsEndpoint: BASE_URL + "Financial/v1/status",
    overdueCompletePaymentEndpoint: BASE_URL + "iskan/v1/paymentdues/epay/complete",
    applicationsWithTicketsEndpoint: BASE_URL + "iskan/v1/applications",

    serviceCardsEndpoint: BASE_URL_DC + "/getCategories",
    serviceCardsSubEndpoint: BASE_URL_DC + "/servicesList",
    serviceCardDetailEndpoint: BASE_URL_DC + "/serviceDetails",
    serviceCardFileEndpoint: BASE_URL_DC + "/getFile?FileRef=",

    // _requestLiabilityCertificate = 2574,
    // _nquireOnRemainingLoanAmount = 2575,
    // _payHousingLoanOverDues = 2576,
    // _requestEarlySettlementDiscount = 2579,
    // _requestToWhomItConcernsLetter = 2584,
    // _openingNewProjectFile = 2585,
    // _requestProjectFirstPayment = 2586,
    // _requestRetentionPayment = 2587,
    // _requestReadymadeHouseLoan = 2588,
    // _requestHouseMaintenanceAdditionLoan = 2589,
    // _requestHouseConstructionLoan = 2590,
    // _calculateHousingLoan = 2595,
    // _requestHousingLandGrant = 2596,
    // _requestGovernmentHouseGrant = 2597,
    // _requestMaintenance_AdditionGrant = 2598,
    // _requestToPurchaseGovernmentHouse = 2602,
    // _updateHousingApplicationRequest = 2603,
    // _requestProjectInterimPayment = 2663,
    // _requestProjectFinalPayment = 2664,
    // _readyHousingAllocationRequest = 2665,
    // _requestMaintenanceOfYearWarranty = 2669,
    // _requestContractSettlement = 2682,
    // _contractorPaymentDisplay = 3244,
    // _houseGranteeeComponents = 3246,
    // _guidenaceAndAwarnessForReadyHouses = 3248,
    // _question_SuggestionAndComplains = 3249,
    // _updatePersonalCustomersData = 3250,
    // _housingGuru = 3252,
    // _onlineNotificationWhenReadyFavoriateHouseAvailable = 3253
}

export default CONSTANTS