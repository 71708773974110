import React from 'react'
import '../.././bootstrap.min.css'
import '../.././main.css'
import cookie from 'react-cookies'
import HISTORY from '../../History'
// import { Steps } from "intro.js-react"
import { Link } from 'react-router-dom'
import HeaderStore from "./HeaderStore"
import ReactTooltip from 'react-tooltip'
import HeaderService from "./HeaderService"
import LoadingBar from 'react-top-loading-bar'
import { LOCALES } from '../../Providers/i18n'
import LeavingWeb from '../../utils/LeavingWeb'
import LangSwitcher from '../Header/LangSwitcher'
import { AppContext } from '../../Providers/context'
import SwitchTextSize from 'react-font-size-resizer'
import translate from '../../Providers/i18n/Translate'
import CONSTANTS from '../../Customer/constants/dashboard-constants'
import Settings from '../../Customer/dashboard/settings/settings-component'
import NotificationsComponent from '../../Customer/dashboard/notifications/notifications-component'

var local
class Nav extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = {
      response: '',
      notificationBg: false,
      accBg: false,
      serviceEditClick: false,
      isLogout: false,
      isLoading: false,
      language: '',
      payload: localStorage.dashboard && JSON.parse(localStorage.getItem('dashboard')).payload,
      userType: localStorage.getItem('userType'),
      ISUAEPASS: '',
      showLeavePopup: false,
      urlAddress: "",
      query: "",
      stepsEnabled: false,
      initialStep: 0
      // steps: localStorage.getItem('userType') === "1" ? [
      //   // CUSTOMER DASHBOARD
      //   {
      //     element: ".mrhe img",
      //     title: 'Hello',
      //     intro: "We are taking you through this tour to introduce you to the website’s main content and help you getting the best of our services.",
      //     tooltipClass: 'custumTourTooltipLogo',
      //     highlightClass: 'highlightTour',
      //     position: 'left'
      //   },
      //   {
      //     element: ".homeBtn",
      //     title: 'Main Dashboard',
      //     intro: "Contains everything the customer needs starting from services to details to their current housing and maintenance applications",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: "#ChangeLanguag",
      //     title: 'Change Language',
      //     intro: "Available languages: Arabic and English",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".userManualNav",
      //     title: 'User Guide',
      //     intro: "To help you learn how to use the website",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".navSerchBtn",
      //     title: 'Search',
      //     intro: "Quick reach for the website’s services and news",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".user",
      //     title: 'User Profile',
      //     intro: "Contains all information related to the customers with ability to edit",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".logoutBtn",
      //     title: 'Logout',
      //     intro: "Log Out",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".notificationTop",
      //     title: 'Notifications',
      //     intro: "Receive notifications to all changes happening on the personal account, data updates as well as projects payments.",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".settingsBtn",
      //     title: 'Settings',
      //     intro: "To manage notifications and text messages",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".accBtn",
      //     title: 'Accessibility ',
      //     intro: "Ability to control text size and colors according to (WCAG) AA/AAA website guidelines and standards ",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".social-side-menu",
      //     title: 'Social Media',
      //     intro: "To see our latest social media posts",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".customerServicesWidget .widgetWrapper",
      //     title: 'Services',
      //     intro: "Displays the customer’s favorite services with the option of displaying all available housing and financial services",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".fab .msgIco",
      //     title: 'Chatbot',
      //     intro: "Chat with Muftah, your virtual assistant to inquire about any issue related to the website",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".complementaryServicesWidget .widgetWrapper",
      //     title: 'Complementary Services',
      //     intro: "Which includes change service request, cancel approved service request, and cancel in-progress service request",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".contractorPaymentRequestWrapper .widgetWrapper",
      //     title: 'Contractor Payment Requests',
      //     intro: "Displays the contractor’s projects and their requested payments",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".applicationStatusWidgetWrapper .widgetWrapper",
      //     title: 'Applications Status',
      //     intro: "Customers can know their housing/ maintenance applications status",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".housingRequestsWidgetWrapper .widgetWrapper",
      //     title: 'Housing Requests',
      //     intro: "Display all housing requests done by the customer in all their status",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".financialStatusGraphWidgetWrapper .widgetWrapper",
      //     title: 'The Stages of Project Completion',
      //     intro: "Enable the customer to view the active constructions activities and approved payments for both “Housing construction loan” and/ or “Housing Maintenance, Addition or Replacement (partial / whole) grant”",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".stagesProjectCompletionWidgetWrapper .widgetWrapper",
      //     title: 'The Stages of Project Completion',
      //     intro: "Enable the customer to view the active constructions activities and approved payments for both “Housing construction loan” and/ or “Housing Maintenance, Addition or Replacement (partial / whole) grant”",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".manageBookingWidgetWrapper .widgetWrapper",
      //     title: 'Manage House Booking',
      //     intro: "Manage House Booking",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".financialStatusWidgetWrapper .widgetWrapper",
      //     title: 'Financial Status',
      //     intro: "Displays the financial status to customers so they know their credits and debts",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".housingProjectsWidgetWrapper .widgetWrapper",
      //     title: 'Housing Projects',
      //     intro: "Displays all the ready houses projects locations built or being built by MBRHE",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".maintenanceRequestWidgetWrapper .widgetWrapper",
      //     title: 'Maintenance Request',
      //     intro: "Displays all maintenance requests with their completion percentage",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".maintenanceInsuranceWidgetWrapper .widgetWrapper",
      //     title: 'Maintenance Insurance',
      //     intro: "Maintenance Insurance",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".awarenessWidgetWrapper .widgetWrapper",
      //     title: 'Maintenance Awareness',
      //     intro: "A set of guidelines related to water and electricity issues, and how to deal when facing any malfunctions",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   }
      // ] : [
      //   // CONTRACTOR DASHBOARD
      //   {
      //     element: ".mrhe img",
      //     title: 'Hello',
      //     intro: "We are taking you through this tour to introduce you to the website’s main content and help you getting the best of our services.",
      //     tooltipClass: 'custumTourTooltipLogo',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".homeBtn",
      //     title: 'Main Dashboard',
      //     intro: "Contains everything related to contractors’ services and their current projects details",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: "#ChangeLanguag",
      //     title: 'Change Language',
      //     intro: "Available languages: Arabic and English",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".userManualNav",
      //     title: 'User Guide',
      //     intro: "To help you learn how to use the website",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".navSerchBtn",
      //     title: 'Search',
      //     intro: "Quick reach for the website’s services and news",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".user",
      //     title: 'User Profile',
      //     intro: "Contains all information related to the contractor with ability to edit the password",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".logoutBtn",
      //     title: 'Logout',
      //     intro: "Log Out",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".notificationTop",
      //     title: 'Notifications',
      //     intro: "Receive notifications to all changes happening on the personal account, data updates as well as projects payments.",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".settingsBtn",
      //     title: 'Settings',
      //     intro: "To manage notifications and text messages",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".accBtn",
      //     title: 'Accessibility ',
      //     intro: "Ability to control text size and colors according to (WCAG) AA/AAA website guidelines and standards",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".social-side-menu",
      //     title: 'Social Media',
      //     intro: "To see our latest social media posts",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".contractorServicesWidget .widgetWrapper",
      //     title: 'Services',
      //     intro: "Which contains open project profile, request project payment and retention payments",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".contractorProjectsWidgetWrapper .widgetWrapper",
      //     title: 'Contractor’s Projects Summary',
      //     intro: "Displays summary to all contractors’ projects",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".fab .msgIco",
      //     title: 'Chatbot',
      //     intro: "Chat with Muftah, your virtual assistant to inquire about any issue related to the website",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".constructionLoanProjectsWidgetWrapper .widgetWrapper",
      //     title: 'Construction Loans Projects',
      //     intro: "Displays the details and everything related to the contractor’s construction loans projects",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".maintenanceGrantProjectWidgetWrapper .widgetWrapper",
      //     title: 'Maintenance Grants Projects',
      //     intro: "Displays the details and everything related to the contractor’s maintenance grants projects",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".financialStatusWidgetWrapper .widgetWrapper",
      //     title: 'Financial Status',
      //     intro: "Displays the financial status to contractors as per the project’s owner name",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".projectSummaryWidgetWrapper .widgetWrapper",
      //     title: 'Projects Completion Summary',
      //     intro: "Contractors can view the project’s completion percentage as per to the project number",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   }
      // ],
      // stepsAr: localStorage.getItem('userType') === "1" ? [
      //   // AR-CUSTOMER DASHBOARD
      //   {
      //     element: ".mrhe img",
      //     title: 'اهلا وسهلاً',
      //     intro: "نأخذكم في هذه الجولة الترحيبية لمساعدتكم في التعرف على محتوى الموقع وكيفية الاستفادة  مما نقدمه التالي",
      //     tooltipClass: 'custumTourTooltipLogo',
      //     highlightClass: 'highlightTour',
      //     // position: 'left'
      //   },
      //   {
      //     element: ".homeBtn",
      //     title: 'الذهاب إلى الصفحة الرئيسية',
      //     intro: "تحتوي على كل ما يلزم المتعامل من خدمات وتفاصيل لطلباتهم الاسكانية وطلبات الصيانة",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: "#ChangeLanguag",
      //     title: 'تغيير اللغة ',
      //     intro: "اللغات المتوفرة؛ العربية والانجليزية",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".userManualNav",
      //     title: 'دليل المستخدم ',
      //     intro: "للتعرف على كيفية استخدام الموقع",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".navSerchBtn",
      //     title: 'البحث',
      //     intro: "لوصول سريع إلى كل ما ترغبون به من خدمات أو أخبار",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".user",
      //     title: 'الملف الشخصي',
      //     intro: "يحتوي على كامل المعلومات الشخصية للمتعامل مع إمكانية التعديل",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".logoutBtn",
      //     title: 'تسجيل الخروج',
      //     intro: "تسجيل الخروج",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".notificationTop",
      //     title: 'الإشعارات',
      //     intro: "استقبال اشعارات لجميع التغييرات الحاصلة على الحساب الشخصي، تحديث البيانات أو الطلبات الاسكانية",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".settingsBtn",
      //     title: 'الإعدادات',
      //     intro: "لضبط استقبال الاشعارات والرسائل النصية",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".accBtn",
      //     title: 'سهولة التصفح ',
      //     intro: "للتحكم بحجم الخط والالوان المستخدمة حسب المعايير الخاصة بالمواقع الإلكترونية ",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".social-side-menu",
      //     title: 'التواصل الإجتماعي',
      //     intro: "للإطلاع على آخر منشورات ومشاركات صفحاتنا على مواقع التواصل الاجتماعي",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".customerServicesWidget .widgetWrapper",
      //     title: 'الخدمات',
      //     intro: "عرض جميع الخدمات المتاحة للمتعامل، مع خيار إظهار او اخفاء الخدمات",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".fab .msgIco",
      //     title: 'الدردشة',
      //     intro: "للتحدث مع مفتاح، مساعدكم الإفتراضي للاستفسار عن أي شيء يخص خدمات المؤسسة",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".complementaryServicesWidget .widgetWrapper",
      //     title: 'الخدمات التكميلية',
      //     intro: "والتي تشمل تغيير طلب خدمة، إلغاء طلب خدمة موافق عليها وإلغاء طلب خدمة قيد التنفيذ والمزيد من الخدمات التكميلية قريبا",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".contractorPaymentRequestWrapper .widgetWrapper",
      //     title: 'طلب دفعات المقاول',
      //     intro: "لعرض طلب دفعات المقاول المطلوبة للموافقة عليها أو رفضها",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".applicationStatusWidgetWrapper .widgetWrapper",
      //     title: 'حالة الطلبات',
      //     intro: "يتمكن المتعامل من معرفة حالة الطلبات الاسكانية وطلبات الصيانة المقدمة",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".housingRequestsWidgetWrapper .widgetWrapper",
      //     title: 'الإستمارات الإسكانية',
      //     intro: "لعرض كافة الطلبات الاسكانية التي قام بتقديمها المتعامل بجميع حالاتها",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".financialStatusGraphWidgetWrapper .widgetWrapper",
      //     title: 'مراحل تنفيذ المشروع ',
      //     intro: "المشروع (جدول)تمكن المتعامل من الاطلاع على مراحل تنفيذ قروض البناء و منح الصيانة, الدفعات الموافق عليها وصور الموقع",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".stagesProjectCompletionWidgetWrapper .widgetWrapper",
      //     title: 'مراحل تنفيذ المشروع ',
      //     intro: "المشروع (جدول)تمكن المتعامل من الاطلاع على مراحل تنفيذ قروض البناء و منح الصيانة, الدفعات الموافق عليها وصور الموقع",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".manageBookingWidgetWrapper .widgetWrapper",
      //     title: 'Manage House Booking',
      //     intro: "Manage House Booking",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".financialStatusWidgetWrapper .widgetWrapper",
      //     title: 'الحالة المالية',
      //     intro: "يتمكن المتعامل من معرفة الحالة المالية للقروض ان وجدت",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".housingProjectsWidgetWrapper .widgetWrapper",
      //     title: 'إدارة حجز المساكن',
      //     intro: "عرض كل ما يتعلق بحجوزات الاسكان للمتعامل مع قابلية التعديل",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".maintenanceRequestWidgetWrapper .widgetWrapper",
      //     title: 'المشاريع الإسكانية',
      //     intro: "عرض مواقع جميع المساكن الجاهزة التي تم بنائها المؤسسة او يتم بنائها حاليا ",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".maintenanceInsuranceWidgetWrapper .widgetWrapper",
      //     title: 'طلبات الصيانة',
      //     intro: "عرض طلبات الصيانة القائمة مع نسبة الإنجاز",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".awarenessWidgetWrapper .widgetWrapper",
      //     title: 'تأمين الصيانة',
      //     intro: "تأمين الصيانة",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   }
      // ] : [
      //   // AR-CONTRACTOR DASHBOARD
      //   {
      //     element: ".mrhe img",
      //     title: 'اهلا وسهلاً',
      //     intro: "نأخذكم في هذه الجولة الترحيبية لمساعدتكم في التعرف على محتوى الموقع وكيفية الاستفادة مما نقدمهالتالي",
      //     tooltipClass: 'custumTourTooltipLogo',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".homeBtn",
      //     title: 'الصفحة الرئيسية',
      //     intro: "تحتوي على كل ما يلزم المقاول من خدمات وتفاصيل للمشاريع القائمة",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: "#ChangeLanguag",
      //     title: 'تغيير اللغة ',
      //     intro: "اللغات المتوفرة؛ العربية والانجليزية",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".userManualNav",
      //     title: 'دليل المستخدم ',
      //     intro: "للتعرف على كيفية استخدام الموقع",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".navSerchBtn",
      //     title: 'البحث',
      //     intro: "لوصول سريع إلى كل ما ترغبون به من خدمات أو أخبار ",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".user",
      //     title: 'الملف الشخصي',
      //     intro: "يحتوي على كامل المعلومات الشخصية للمقاول مع إمكانية تعديل كلمة المرور",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".logoutBtn",
      //     title: 'تسجيل الخروج',
      //     intro: "تسجيل الخروج",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".notificationTop",
      //     title: 'الإشعارات',
      //     intro: "استقبال اشعارات لجميع تغييرات الحاصلة على الحساب الشخصي، تحديث البيانات أو دفعات المشاريع",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".settingsBtn",
      //     title: 'الإعدادات',
      //     intro: "لضبط استقبال الاشعارات والرسائل النصية",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".accBtn",
      //     title: 'سهولة التصفح ',
      //     intro: "للتحكم بحجم الخط والالوان المستخدمة حسب المعايير الخاصة بالمواقع الإلكترونية (WCAG) AA/AAA ",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".social-side-menu",
      //     title: 'التواصل الاجتماعي',
      //     intro: "للاطلاع على آخر منشورات ومشاركات صفحاتنا على مواقع التواصل الاجتماعي ",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".contractorServicesWidget .widgetWrapper",
      //     title: 'الخدمات',
      //     intro: "وتشمل طلب فتح ملف مشروع، طلب دفعة للمشروع ومحجوزات الصيانة",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".contractorProjectsWidgetWrapper .widgetWrapper",
      //     title: 'مشاريع المقاول',
      //     intro: "لإظهار ملخص لجميع مشاريع المقاول",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".fab .msgIco",
      //     title: 'الدردشة',
      //     intro: "للتحدث مع مفتاح، مساعدكم الرقمي للاستفسار عن أي شئ يخص الموقع",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".constructionLoanProjectsWidgetWrapper .widgetWrapper",
      //     title: 'مشاريع قروض البناء',
      //     intro: "لاظهار تفاصيل وكل ما يخص مشاريع قروض البناء للمقاول",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".maintenanceGrantProjectWidgetWrapper .widgetWrapper",
      //     title: 'مشاريع منح الصيانة',
      //     intro: "لاظهار تفاصيل وكل ما يخص مشاريع منح الصيانة للمقاول",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".financialStatusWidgetWrapper .widgetWrapper",
      //     title: 'الحالة المالية',
      //     intro: "يتمكن المقاول من معرفة الحالة المالية حسب اسم مالك المشروع",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   },
      //   {
      //     element: ".projectSummaryWidgetWrapper .widgetWrapper",
      //     title: 'ملخص المشاريع',
      //     intro: "يتمكن المقاول من معرفة نسبة إتمام المشاريع حسب رقم المشروع",
      //     tooltipClass: 'custumTourTooltip',
      //     highlightClass: 'highlightTour',
      //   }
      // ]
    }
    this.onChange = this.onChange.bind(this)
    this.getTooltips = this.getTooltips.bind(this)
    this.prepareWidgets = this.prepareWidgets.bind(this)
    this.handleAddClick = this.handleAddClick.bind(this)
    this.getPayload = this.getPayload.bind(this)
    this.myTheme = this.myTheme.bind(this)
    this.enter = this.enter.bind(this)
    this.showLeavePopup = this.showLeavePopup.bind(this);
    this.hideLeavePopup = this.hideLeavePopup.bind(this);
  }

  componentWillUnmount() {
    HeaderStore.removeChangeListener(this.onChange)
  }

  onChange() {
    this.LoadingBar && this.LoadingBar.complete()
    this.setState(this.prepareWidgets(HeaderStore.response))
  }

  componentDidMount() {
    local = '?local=' + this.context.state.siteLang
    if (this.context.state.siteLang === LOCALES.ENGLISH) {
      require('../.././main.css');
      require('../.././black_theme.css');
      require('../.././red_theme.css');
      require('../.././green_theme.css');
      require('../.././mediascreen.css');
      // require('intro.js/introjs.css');
    } else {
      require('../.././arabic.css');
      require('../.././black_theme.css');
      require('../.././red_theme.css');
      require('../.././green_theme.css');
      require('../.././mediascreen.css');
      // require('intro.js/introjs.css');
      // require('intro.js/introjs-rtl.css');
    }

    this.LoadingBar && this.LoadingBar.continuousStart()
    HeaderStore.addChangeListener(this.onChange)
    HeaderService.dashboardRequest(local)
  }

  getDashboardPayload() {
    return this.state.response
  }

  prepareWidgets(response) {
    if (response && response.success) {
      if (!this.isEservicesRoute() && this.settings) {
        this.settings.initSettings(response.payload.settings)
      }
      localStorage.setItem("dashboard", JSON.stringify(response))
      this.setState({ payload: response.payload, response: response, isLoading: false })
    } else {
      this.setState({ payload: null })
    }
  }

  handleAddClick() {
    if (this.isLogedIn()) {
      this.setState({
        notificationBg: !this.state.notificationBg,
        notificationBtnClick: !this.state.notificationBtnClick
      })
    }
  }

  getPayload() {
    return this.state.payload
  }

  logout = () => {
    var local = localStorage.getItem('siteLang');
    var username = localStorage.getItem('USERNAME');
    var toggle = localStorage.getItem('TOGGLEME');
    this.setState({ ISUAEPASS: localStorage.getItem('isUAEPASS') })
    localStorage.clear();
    if (local !== null) {
      localStorage.setItem('siteLang', local);
    }
    if (username != null && toggle != null) {
      localStorage.setItem('USERNAME', username);
      localStorage.setItem('TOGGLEME', toggle);
    }
    cookie.remove('chatbotInfoName', { path: '/' });
    cookie.remove('chatbotInfoMobile', { path: '/' });
    cookie.remove('chatbotInfoEmail', { path: '/' });
    cookie.remove('chatbotInfoPhoto', { path: '/' });

    this.setState({ isLogout: true })
  }

  isLogedIn() {
    return localStorage.getItem('accessToken')
  }

  redirectToHome() {
    HISTORY.push('/eservices')
  }

  componentDidUpdate() {
    if (this.state.isLogout) {
      if (this.state.ISUAEPASS) {
        window.location.href = process.env.UAEPASS_LOGOUT
      } else {
        this.redirectToHome();
      }
    }
  }

  isEservicesRoute() {
    return window.location.pathname == '/eservices' ||
      window.location.pathname == '/contractorLogin' ||
      window.location.pathname == '/chatbotWeb' ||
      window.location.pathname == '/customerLogin';
  }

  acctoggle(e) {
    e.preventDefault();
    this.setState({
      accessibilityClick: !this.state.accessibilityClick
    });
  }

  accBtnClick() {
    this.setState({
      accPopBg: !this.state.accPopBg,
      accBtnClick: !this.state.accBtnClick
    })
  }

  searchClick() {
    this.setState({
      searchClick: !this.state.searchClick
    })
  }

  myTheme(e) {
    var id = e.target.id
    if (id === "theme-green") {
      this.props.appRef.changeTheme('theme-green')
    } else if (id === "theme-red") {
      this.props.appRef.changeTheme('theme-red')
    } else if (id === "theme-black") {
      this.props.appRef.changeTheme('theme-black')
    } else {
      this.props.appRef.changeTheme('theme-normal')
    }
  }

  showLeavePopup(address) {
    this.setState({ showLeavePopup: true, urlAddress: address })
  }

  hideLeavePopup() {
    this.setState({ showLeavePopup: false, urlAddress: "" })
  }

  enter(e) {
    if (e.key === 'Enter') {
      window.location.href = '/searchResult?query=' + this.state.query
    }
  }

  getTooltips() {
    return (
      <div>
        <ReactTooltip id='Contact' className="toolTip">
          <span>{translate('contactus')}</span>
        </ReactTooltip>
        <ReactTooltip id='ContactH' place='bottom' className="toolTip">
          <span>{translate('contactus')}</span>
        </ReactTooltip>
        <ReactTooltip id='accessability' place='bottom' className="toolTip">
          <span>{translate('accessibility')}</span>
        </ReactTooltip>
        <ReactTooltip id='settings' place='bottom' className="toolTip">
          <span>{translate('settings-lbl')}</span>
        </ReactTooltip>
        <ReactTooltip id='notification' place='bottom' className="toolTip">
          <span>{translate('Notifications')}</span>
        </ReactTooltip>
        <ReactTooltip id='logout' place='bottom' className="toolTip">
          <span>{translate('Logout')}</span>
        </ReactTooltip>
        <ReactTooltip id='profile' place='bottom' className="toolTip">
          <span>{translate('profile')}</span>
        </ReactTooltip>
        <ReactTooltip id='whatsapp' className="toolTip">
          <span>{translate('whatsapp')}</span>
        </ReactTooltip>
        <ReactTooltip id='facebook' className="toolTip">
          <span>{translate('facebook')}</span>
        </ReactTooltip>
        <ReactTooltip id='twitter' className="toolTip">
          <span>{translate('twitter')}</span>
        </ReactTooltip>
        <ReactTooltip id='instagram' className="toolTip">
          <span>{translate('instagram')}</span>
        </ReactTooltip>
        <ReactTooltip id='youtube' className="toolTip">
          <span>{translate('youtube')}</span>
        </ReactTooltip>
        <ReactTooltip id='home' place='bottom' className="toolTip">
          <span>{translate('Home')}</span>
        </ReactTooltip>
        <ReactTooltip id='userManual' place='bottom' className="toolTip">
          <span>{translate('EservicesUserManual')}</span>
        </ReactTooltip>
        <ReactTooltip id='search' place='bottom' className="toolTip">
          <span>{translate('search')}</span>
        </ReactTooltip>
        <ReactTooltip id='language' place='bottom' className="toolTip">
          <span>{translate('changeLanguage')}</span>
        </ReactTooltip>
        <ReactTooltip id='tour' place='bottom' className="toolTip">
          <span>{translate('siteTour')}</span>
        </ReactTooltip>
        <ReactTooltip id='appStore' className="toolTip">
          <span>{translate('appStore')}</span>
        </ReactTooltip>
        <ReactTooltip id='playStore' className="toolTip">
          <span>{translate('playStore')}</span>
        </ReactTooltip>
        <ReactTooltip id='InternetExplorer' className="toolTip">
          <span>{translate('InternetExplorer')}</span>
        </ReactTooltip>
        <ReactTooltip id='GoogleChrome' className="toolTip">
          <span>{translate('GoogleChrome')}</span>
        </ReactTooltip>
        <ReactTooltip id='Safari' className="toolTip">
          <span>{translate('Safari')}</span>
        </ReactTooltip>
        <ReactTooltip id='Firefox' className="toolTip">
          <span>{translate('Firefox')}</span>
        </ReactTooltip>
        <ReactTooltip id='Chat' className="toolTip">
          <span>{translate('Chat')}</span>
        </ReactTooltip>

        <ReactTooltip id='mbrheTheme' className="toolTip">
          <span>{translate('MBRHETheme')}</span>
        </ReactTooltip>
        <ReactTooltip id='greenWeekness' className="toolTip">
          <span>{translate('greenWeekness')}</span>
        </ReactTooltip>
        <ReactTooltip id='redWeakness' className="toolTip">
          <span>{translate('redWeakness')}</span>
        </ReactTooltip>
        <ReactTooltip id='colourBlind' className="toolTip">
          <span>{translate('ColourBlind')}</span>
        </ReactTooltip>
        <ReactTooltip id='print' className="toolTip">
          <span>{translate('PrintPage')}</span>
        </ReactTooltip>

        <ReactTooltip id='accessibilityLink' className="toolTip">
          <span>{translate('accessibility')}</span>
        </ReactTooltip>
        <ReactTooltip id='careerLink' className="toolTip">
          <span>{translate('ejobs')}</span>
        </ReactTooltip>
        <ReactTooltip id='contactusLink' className="toolTip">
          <span>{translate('contactus')}</span>
        </ReactTooltip>
        <ReactTooltip id='customerCharterLink' className="toolTip">
          <span>{translate('CustomerCharter')}</span>
        </ReactTooltip>
        <ReactTooltip id='faqLink' className="toolTip">
          <span>{translate('faq')}</span>
        </ReactTooltip>
        <ReactTooltip id='informationPolicyLink' className="toolTip">
          <span>{translate('InformationPolicy')}</span>
        </ReactTooltip>
        <ReactTooltip id='privacypolicyLink' className="toolTip">
          <span>{translate('privacypolicy')}</span>
        </ReactTooltip>
        <ReactTooltip id='sitemapLink' className="toolTip">
          <span>{translate('sitemap')}</span>
        </ReactTooltip>
        <ReactTooltip id='termsofuseLink' className="toolTip">
          <span>{translate('termsofuse')}</span>
        </ReactTooltip>
        <ReactTooltip id='confidentiality' className="toolTip">
          <span>{translate('confidentiality')}</span>
        </ReactTooltip>
        <ReactTooltip id='services' className="toolTip">
          <span>{translate('services')}</span>
        </ReactTooltip>
        <ReactTooltip place='bottom' id='opendata' className="toolTip">
          <span>{translate('openData')}</span>
        </ReactTooltip>

        <ReactTooltip id='polls' className="toolTip">
          <span>{translate('polls')}</span>
        </ReactTooltip>
        <ReactTooltip id='iskanapp' className="toolTip">
          <span>{translate('iskanapp')}</span>
        </ReactTooltip>
        <ReactTooltip id='AIchat' className="toolTip">
          <span>{translate('AIchat')}</span>
        </ReactTooltip>
      </div>
    )
  }

  render() {


    let notificationaddClass = ["notificationSideWrapper"]
    if (this.state.notificationBtnClick) {
      notificationaddClass.push('notificationSideWrapperOpen');
    }

    let addClasNav = ["navWrapper"];
    if (this.state.notificationBtnClick) {
      addClasNav.push("navIndex");
    }

    let accClass = ["accessWrapperOpen"];
    if (this.state.accessibilityClick) {
      accClass.push('AccAllOpen');
    }

    let addClasAacc = ["accessBtn"];
    if (this.state.shareClick) {
      addClasAacc.push("accBtnZindex");
    }

    let addClasAaccPopup = ['accPopup'];
    if (this.state.accBtnClick) {
      addClasAaccPopup.push('accPopupOpen');
    }

    let searchBar = ['searchWrapper'];
    if (this.state.searchClick) {
      searchBar.push('searchWrapperOpen');
    }

    let {
      stepsEnabled,
      // steps,
      // stepsAr,
      initialStep
    } = this.state;

    return (
      <div className={addClasNav.join(' ')} id='header'>
        {/* <Steps
          enabled={stepsEnabled}
          // steps={this.context.state.siteLang === LOCALES.ENGLISH ? steps : stepsAr}
          initialStep={initialStep}
          onExit={this.onExit}
          options={{
            hideNext: false,
            hidePrev: true,
            showBullets: false
          }}
        /> */}
        {this.state.showLeavePopup ? <LeavingWeb url={this.state.urlAddress} hide={this.hideLeavePopup} /> : null}
        {this.isLogedIn() ? <LoadingBar className='loagingbar' height={0} color='#0091ff' onRef={ref => (this.LoadingBar = ref)} /> : null}

        {/* notification */}
        {this.state.notificationBg ? <div className="notificationSideBg" onClick={this.handleAddClick}></div> : null}
        {this.isLogedIn() ?
          <div className={notificationaddClass.join(' ')}>
            <div className="notificationhead">
              <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/bell2.svg'} alt='notification' />
              <span>{translate('Notifications')}</span>
            </div>
            {local ? <NotificationsComponent close={this.handleAddClick} url={CONSTANTS.notificationsEndpoint + local} /> : null}
          </div> : null}

        <div className="container-fluid">
          {/* Acc Popup */}
          {this.state.accPopBg ? <div className="PopupBk accessibilityPopupBg" onClick={this.accBtnClick.bind(this)}></div> : null}
          {/* {this.state.accBg ? <div className="PopupBk" onClick={this.accBtnClick.bind(this)}></div> : null} */}
          {/* <div className="PopupBk" id='accPopup'></div> */}
          <div className={addClasAaccPopup.join(' ')}>
            <button className="popupClose" onClick={this.accBtnClick.bind(this)}>
              <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/close_black.svg'} alt='Close' />
            </button>
            <div className="accPopupContent">
              <section>
                <strong>{translate('peopleofdetermination')}</strong>
                <ul>
                  <li><a href="https://www.mbrhe.gov.ae/People-of-Determination-Services">{translate('peopleOfDeterminationServices')}</a></li>
                  <li><a href="https://www.mbrhe.gov.ae/Facilities-and-privileges">{translate('facilitiesandprivileges')}</a></li>
                  <li><a href="https://www.mbrhe.gov.ae/Services/Charter/People-of-Determination-Charter">{translate('peopleOfDeterminationCharter')}</a></li>
                  <li><a href="https://www.mbrhe.gov.ae/People-of-determination-Initiatives">{translate('initiatives')} </a></li>
                  <li><a href="https://www.mbrhe.gov.ae/Contact--Us">{translate('contactus')}</a></li>
                </ul>
              </section>

              {/* <section>
                  <strong>{translate('screenreader')}</strong>
                  <p>{translate('listenPage')}</p>
                  <button data-tip data-for='print' className="accPrintBtn" onClick={() => window.print()}><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/print.svg'} alt='notification' /></i>{translate('Print')}</button>
                </section> */}

              <section>
                <strong>{translate('textResize')}</strong>
                <p>{translate('ifYouHaveTroubleReadingText')}</p>
                <div className="fontSizeChangeWrapper">
                  <SwitchTextSize
                    default={14} // numerical default font size value
                    step={1} // numerical quantity that increases / decreases
                    min={11} // numerical min font size value
                    max={17} // numerical max font size value
                    suffix={"px"} // string suffix property font-size css
                    store={localStorage} // object store to save current font size value
                    storeKey="SwitchTextSize" // string of store key
                  />
                </div>
              </section>

              <section>
                <strong>{translate('themeSwitch')}</strong>
                <p>{translate('useSwitchChangeTheme')}</p>

                <label htmlFor="theme-normal" data-tip data-for='mbrheTheme'>
                  <input onChange={this.myTheme} checked={localStorage.getItem("appTheme") === null || localStorage.getItem("appTheme") === "theme-normal"} id="theme-normal" type="radio" value="normal" />
                  {translate('MBRHETheme')}</label>

                <label htmlFor="theme-black" data-tip data-for='colourBlind'>
                  <input onChange={this.myTheme} checked={localStorage.getItem("appTheme") && localStorage.getItem("appTheme") === "theme-black"} id="theme-black" type="radio" value="Black" />
                  {translate('ColourBlind')}</label>

                <label htmlFor="theme-green" data-tip data-for='redWeakness'>
                  <input onChange={this.myTheme} checked={localStorage.getItem("appTheme") && localStorage.getItem("appTheme") === "theme-green"} id="theme-green" type="radio" value="Green" />
                  {translate('redWeakness')}</label>

                <label htmlFor="theme-red" data-tip data-for='greenWeekness'>
                  <input onChange={this.myTheme} checked={localStorage.getItem("appTheme") && localStorage.getItem("appTheme") === "theme-red"} id="theme-red" type="radio" value="red" />
                  {translate('greenWeekness')}</label>

              </section>
            </div>
          </div>

          <div className="social-side-menu">
            {/* <span data-tip data-for='whatsapp' onClick={() => this.showLeavePopup("https://api.whatsapp.com/send/?phone=97142029329&text=Hello%20Muftah,%20Could%20I%20know%20the%20Housing%20Application%20Status")}> */}
            <span data-tip data-for='whatsapp' onClick={() => this.showLeavePopup("https://api.whatsapp.com/send/?phone=+97142029444&text=Hello%20Muftah,%20Could%20I%20know%20the%20Housing%20Application%20Status")}>
              {/* <i className="fa fa-whatsapp"> </i> */}
              <img href="#!" src={"https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/whatsapp_left_menu.svg"} />
            </span>
            <span data-tip data-for='facebook' onClick={() => this.showLeavePopup("https://www.facebook.com/mbrhedubai")}>
              <i className="fa fa-facebook"> </i>
            </span>
            <span data-tip data-for='twitter' onClick={() => this.showLeavePopup("https://twitter.com/mbrhe_gov")}>
              {/* <i className="fa fa-twitter"> </i> */}
              <img href="#!" src={"https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/twitter_left_menu.svg"} />
            </span>
            <span data-tip data-for='instagram' onClick={() => this.showLeavePopup("https://www.instagram.com/mbrhe_gov/")}>
              {/* <i className="fa fa-instagram"> </i> */}
              <img href="#!" src={"https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/instagram_left_menu.svg"} />
            </span>
            <span data-tip data-for='youtube' onClick={() => this.showLeavePopup("https://www.youtube.com/user/mrheDubai")}>
              {/* <i className="fa fa-youtube"> </i> */}
              <img href="#!" src={"https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/youtube_left_menu.svg"} />
            </span>
          </div>

          <div className="row nav_wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <ul className="navLeft">
                    {!this.isEservicesRoute() && this.isLogedIn() ?
                      <li className="homeBtn">
                        {this.state.userType === "1" ?
                          <a data-tip data-for='home' href='/customerDashboard'><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/home1.svg'} alt='Home' /></i></a>
                          : <a data-tip data-for='home' href='/contractorDashboard'><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/home1.svg'} alt='Home' /></i></a>}
                      </li> : <li className="homeBtn">
                        <a data-tip data-for='home' href='https://www.mbrhe.gov.ae/en/'><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/home1.svg'} alt='Home' /></i></a>
                      </li>}
                    <li>
                      <a value="userManual" data-tip data-for='opendata' onClick={() => this.showLeavePopup("https://www.dubaipulse.gov.ae/category/housing")}><i></i><span>{translate('opendata')}</span></a>
                      {this.context.state.siteLang === LOCALES.ENGLISH ?
                        <a data-tip data-for='ContactH' href="https://www.mbrhe.gov.ae/en/AboutUs/Contact-Us" ><span>       </span><span>{translate('contactUS')}</span></a>
                        : <a data-tip data-for='ContactH' href="https://www.mbrhe.gov.ae/ar/AboutUs/Contact-Us" ><span>     </span><span>{translate('contactUS')}</span> </a>}
                    </li>
                  </ul>

                  <ul className="navRight">
                    {!this.isEservicesRoute() && this.isLogedIn() ? <li><button data-tip data-for='logout' className="logoutBtn" onClick={this.logout}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/logout.svg'} alt='logout' /></button></li> : null}
                    <li>
                      {!this.isEservicesRoute() && this.isLogedIn() ?
                        <Link data-tip data-for='profile' to={{ pathname: this.state.userType === "1" ? '/userProfile' : '/contractorProfile', getPayload: this.getPayload }} className="user">
                          {this.state.payload && this.state.payload.name}
                          <div className="user_image"> {this.state.payload && this.state.payload.photo ? <img src={`data:image/png;base64,${this.state.payload.photo}`} height="31" width="31" alt='User' /> : <img src='https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/user.svg' alt='User' />}</div>
                        </Link> : null}
                    </li>
                    {!this.isEservicesRoute() && this.isLogedIn() ? <li className='notificationTop' onClick={this.handleAddClick.bind(this)}><button data-tip data-for='notification'><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/notification.svg'} alt='notification' /></button><i>{this.state.payload && this.state.payload.notifications}</i></li> : null}
                    {!this.isEservicesRoute() && this.isLogedIn() ? <li className='settingsBtn'><button className="hideFont" data-tip data-for='settings'><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/settings.svg'} alt='' /></button><Settings onRef={ref => (this.settings = ref)} /></li> : null}
                    {this.isLogedIn() ? <li><button onClick={this.toggleSteps} data-tip data-for='tour'><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/sitetour.svg'} alt='Contact Us' /></button></li> : null}
                    {!this.isEservicesRoute() && this.isLogedIn() ? <li><button className="navSerchBtn" place='bottom' data-tip data-for='search' onClick={this.searchClick.bind(this)}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/search.svg'} alt='search' /></button></li> : null}
                    <li onClick={this.accBtnClick.bind(this)}><button className="hideFont" value="accessability" place='bottom' data-tip data-for='accessability'><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/accesability.svg'} alt='accessibility' /></button>

                    </li>
                    <LangSwitcher />
                    {/* <li className="navConfidentiality">{this.context.state.siteLang === LOCALES.ENGLISH ? <a data-tip data-for='confidentiality' href="https://www.mbrhe.gov.ae/en/Services/Charter/Confidentiality-of-Customers-Information-Charter/" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Confidentiality_Charter_icon.svg'} alt='Confidentiality Charter' /></a> : <a data-tip data-for='confidentiality' href="https://www.mbrhe.gov.ae/ar/Services/Charter/Confidentiality-of-Customers-Information-Charter/" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Confidentiality_Charter_icon.svg'} alt='Confidentiality Charter' /></a>}</li> */}
                  </ul>
                </div>
              </div>
            </div>

            <div className={searchBar.join(' ')}>
              <div className="container-fluid">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="searchgroup searchInputWrapper">
                        <label htmlFor="searchInput" className="hide">Search</label>
                        <input type="text" id="searchInput" className="form-control" placeholder={this.context.state.siteLang === LOCALES.ENGLISH ? "Search" : "بحث"} onChange={(e) => { this.setState({ query: e.target.value }) }} onKeyDown={this.enter} />
                        <div className="searchgroupBtn">
                          <button className="hideFont" type="button" onClick={event => window.location.href = '/searchResult?query=' + this.state.query}>.
                            <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/search_blue.svg'} alt="" />
                          </button>
                          <button className="searchCloseBtn" onClick={this.searchClick.bind(this)}>X</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        {this.getTooltips()}
      </div>
    );
  }
  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
  };
}
export default Nav;