import React from 'react'
import { AppContext } from '../Providers/context'
import LoginStore from "../ApplicationBase/Login/LoginStore"
import CONSTANTS from '../Customer/constants/dashboard-constants'

class HappinessMeter extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)

        this.state = {
            showHappiness: false,
        }

        this.happinessPopClick = this.happinessPopClick.bind(this)
        this.getHappinessMeterSettings = this.getHappinessMeterSettings.bind(this)
        this.happinessMeterSettingsSuccess = this.happinessMeterSettingsSuccess.bind(this)
    }

    componentDidMount() {
        this.getHappinessMeterSettings()
    }

    happinessPopClick() {
        this.setState({
            showHappiness: !this.state.showHappiness
        })
    }

    getHappinessMeterSettings() {
        var queryString = "?local=" + this.context.state.siteLang + "&themeColor&serviceCode=" + this.props.serviceCode + "&transactionId=" + this.props.transactionID
        var headers = CONSTANTS.authToken.length > 15 ? {
            "Authorization": CONSTANTS.authToken,
        } : {
            "consumer-key": process.env.HAPPINESS_METER_CONSUMER_KEY,
            "consumer-secret": process.env.HAPPINESS_METER_CONSUMER_SECRET,
        }
        fetch(CONSTANTS.happinessMeterSettingsEndpoint + queryString, {
            method: 'GET',
            headers: headers
        }).then(response => response.json()).then(this.happinessMeterSettingsSuccess).catch(this.loadFailed);
    }

    happinessMeterSettingsSuccess(response) {
        var payload = response.payload
        var urlString = "/happinessMeter?path=" + payload.uaepassUrl + "&json_payload=" + payload.jsonRequest + "&client_id=" + payload.clientId + "&signature=" + payload.signature + "&lang=" + payload.lang + "&timestamp=" + payload.timestamp + "&random=" + payload.random + "&nonce=" + payload.nonce
        setTimeout(function () {
            this.setState({ showHappiness: true, url: urlString })
        }.bind(this), 2000)
    }

    loadFailed(response) {
        console.log("Fail=" + response)
    }

    render() {
        return (
            <div className="container-fluid">
                {this.state.showHappiness ? <div className="PopupBk" ></div> : null}
                {this.state.showHappiness && this.state.url ? <div className="viewPopup happinessMeterWrapper">
                    <button className="popupClose" onClick={this.happinessPopClick}>X</button>
                    <iframe title="happiness meter" frameBorder="0" src={this.state.url} />
                </div> : null}
            </div>)
    }
}

export default HappinessMeter


// import React from 'react'
// import { AppContext } from '../Providers/context'
// import LoginStore from "../ApplicationBase/Login/LoginStore"
// import CONSTANTS from '../Customer/constants/dashboard-constants'

// class HappinessMeter extends React.Component {
//     static contextType = AppContext
//     constructor(props) {
//         super(props)

//         this.state = {
//             showHappiness: false,
//         }

//         this.happinessPopClick = this.happinessPopClick.bind(this)
//         this.getHappinessMeterSettings = this.getHappinessMeterSettings.bind(this)
//         this.happinessMeterSettingsSuccess = this.happinessMeterSettingsSuccess.bind(this)
//     }

//     componentDidMount() {
//         this.getHappinessMeterSettings()
//     }

//     happinessPopClick() {
//         this.setState({
//             showHappiness: !this.state.showHappiness
//         })
//     }

//     getHappinessMeterSettings() {
//         var queryString = "?local=" + this.context.state.siteLang + "&themeColor&serviceCode=" + this.props.serviceCode + "&transactionId=" + this.props.transactionID
//         var headers = CONSTANTS.authToken.length > 15 ? {
//             "Authorization": CONSTANTS.authToken,
//         } : {
//             "consumer-key": process.env.HAPPINESS_METER_CONSUMER_KEY,
//             "consumer-secret": process.env.HAPPINESS_METER_CONSUMER_SECRET,
//         }
//         fetch(CONSTANTS.happinessMeterSettingsEndpoint + queryString, {
//             method: 'GET',
//             headers: headers
//         }).then(response => response.json()).then(this.happinessMeterSettingsSuccess).catch(this.loadFailed);
//     }

//     happinessMeterSettingsSuccess(response) {
//         var payload = response.payload
//         var urlString = "/happinessMeter?path=" + payload.uaepassUrl +
//             "&json_payload=" + payload.jsonRequest +
//             "&client_id=" + payload.clientId +
//             "&userMobile=" +
//             "&signature=" + payload.signature +
//             "&lang=" + payload.lang +
//             "&spcode=MRHE" +
//             "&random=" + payload.random +
//             "&showquestion=" + true +
//             "&timestamp=" + payload.timestamp +
//             "&responseStatus=" +
//             "&showMicroApp=" + -1 +
//             "&nonce=" + payload.nonce +
//             "&voteLevel=" + 1 +
//             "&questionsCount=" + 3 +
//             "&showNonDubaiMsg=" + false +
//             "&uiProtoType=a2" +
//             "&mainQuestionKey=mainQuestion"
//         setTimeout(function () {
//             this.setState({ showHappiness: true, url: urlString })
//         }.bind(this), 1000)
//     }

//     loadFailed(response) {
//         console.log("Fail=" + response)
//     }

//     render() {
//         return (
//             <div className="container-fluid">
//                 {this.state.showHappiness ? <div className="PopupBk" ></div> : null}
//                 {this.state.showHappiness && this.state.url ? <div className="viewPopup happinessMeterWrapper">
//                     <button className="popupClose" onClick={this.happinessPopClick}>X</button>
//                     <iframe title="happiness meter" frameBorder="0" src={this.state.url} />
//                 </div> : null}
//             </div>)
//     }
// }

// export default HappinessMeter