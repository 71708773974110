import React from "react";
import './chatbot.css';
import { format } from 'date-fns';
import WelcomeMessage from './WelcomeMessageComponent';
import UserChat from './UserChatComponent';
import { AppContext } from '../Providers/context'
import { LOCALES } from '../Providers/i18n'
import cookie from 'react-cookies'
import queryString from 'query-string';
import CommonUtils from "../utils/CommonUtils";
import translate from "../Providers/i18n/Translate";

class ChatbotComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        const today = Date.now();
        this.state = {
            openChat: false,
            isSuccess: false,
            isWelcomeToggleOn: this.ChatClossedLessThanTwoHourAgo(today),
            isChatToggleOn: true,
            conversationId: '',
            faqId: '',
            locale: '',
            reqText: 'hi',
            botType: 'Bot',
            value: '',
            response: [],
            username:'',
            pwd:'',
            goTop: true,
            goBottom: false,
            date: new Date(today).toLocaleString('en-us', { weekday: 'short' }) + format(new Date(), ' MMM dd Y'),
            time: format(new Date(), 'HH:m:i'),
            is_error:false,
            apiError: '',
            errorDescription: '',
            token: ''
        }
        this.handleWelcomeBox = this.handleWelcomeBox.bind(this);
        this.handleChatBox = this.handleChatBox.bind(this);
    }
    
    componentDidMount() {
        this.parseQueryString();
    }
    
    parseQueryString() {
        if (this.props.location) {
            let url = this.props.location.search;
            let value = queryString.parse(url);
            if (value.token && value.token !== '') {
                this.setState({ 
                    isChatToggleOn: false,
                    isWelcomeToggleOn: true,
                    token: value.token
                });
            }else if (value.error && value.error !== '') {
                this.setState({ 
                    apiError: value.error == 'access_denied' ? 'cancleAuthentication' : value.error,
                    is_error: true,
                    isChatToggleOn: false,
                    isWelcomeToggleOn: true,
                });
            }else if( (value.uaePass && localStorage.getItem('isUAEPASS')) || value.openChat || this.props.location.pathname==='/muftah'){
                this.setState({ 
                    isChatToggleOn: false,
                    isWelcomeToggleOn: true,
                    openChat : true
                });
            }
            CommonUtils.getLocalParameter("/chatbotWeb", this.context.state.siteLang, this.props.location.search);
        }
    }
          
    ChatClossedLessThanTwoHourAgo = (date) => {
        var isChatClossed = true;
        const closeChatbotCookie = localStorage.getItem("closeChatbot");//cookie.load('closeChatbot');
        if (closeChatbotCookie) {
            const TwoHours = (1000 * 60 * 60) * 2;
            const TwoHourAgo = Date.now() - TwoHours;
            if (closeChatbotCookie > TwoHourAgo) {
                isChatClossed = false;
            } else {
                //cookie.remove('closeChatbot', { path: '/' });
                localStorage.removeItem("closeChatbot")
            }
        }
        return isChatClossed;
    }

    handleWelcomeBox() {
        //cookie.remove('closeChatbot', { path: '/' });
        localStorage.removeItem("closeChatbot")
        if (this.state.isWelcomeToggleOn) {
            //cookie.save('closeChatbot', Date.now(), { path: '/' });
            localStorage.setItem("closeChatbot")
        }

        this.setState(state => ({
            isWelcomeToggleOn: !state.isWelcomeToggleOn
        }));
    }

    handleChatBox(chabot_lang) {
        this.setState((state) => ({
            isChatToggleOn: !state.isChatToggleOn,
            locale: chabot_lang !== '' ? chabot_lang : state.locale
        }));
    }

    chatpopBtnClick() {
        this.setState({
          chatpopBtnClick: !this.state.chatpopBtnClick
        })
      }

    render() {
        let fcPopup = ['fc_Pop'];
        if (this.state.chatpopBtnClick || this.state.openChat) {
        fcPopup.push('fcOpen');
        }

        const locale = this.context.state.siteLang === LOCALES.ENGLISH ? LOCALES.CHATBOT_EN : LOCALES.CHATBOT_AR;
        return (
            <div className="container-fluid chatBotLoginWrapper footerChatWrapper">
                <div className="container mainSection">
                    <div className="row">
                        <div className="loginSelectorWraper">
                            <div className="loginChoose">
                                <div className="row">
                                    <div className="col">
                                        <a href="/customerLogin" className="customerLogBtn">
                                            <div className="loginSelectorBox">
                                                <div className="headerImg">
                                                    <div className="icon">
                                                        <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/customerIcon.png" alt="click to customer" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="loginChooseContant">
                                                <h2>{translate('customerLogin')}</h2>
                                                <p>{translate('toApplyLoanGrant')}</p>
                                                <span className="customerLogBtn">{translate('customerLogin')}</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col">
                                        <a href="/contractorLogin" className="contractorLogBtn" >
                                            <div className="loginSelectorBox">
                                                <div className="headerImg">
                                                    <div className="icon">
                                                        <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/contractorIcon.png" alt="contractor" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="loginChooseContant">
                                                <h2>{translate('contractorLogin')}</h2>
                                                <p>{translate('toOpenNewFile')}</p>
                                                <span className="contractorLogBtn" >{translate('contractorLogin')}</span>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* footer chat BTN */}
               {
                   !this.state.openChat? <a className="footerChatIco" data-tip data-for='Chat' href="#" onClick={this.chatpopBtnClick.bind(this)}>
                    <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/openchat.svg" alt="Clich to Chat"/>
                </a>
                :null
                    
                }
                {/* footer chat BTN Popup*/}
                <div className={fcPopup.join(' ')} id="fcPop">
                
                {
                   !this.state.openChat? <span className="fc_pop_close">
                        <img onClick={this.chatpopBtnClick.bind(this)} src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/close_black.svg" alt="Close"/>
                    </span> :null
                    
                }
                    {
                        this.state.isChatToggleOn ?
                            <WelcomeMessage handleWelcomeBox={this.handleWelcomeBox.bind(this)} handleChatBox={this.handleChatBox.bind(this)} locale={locale} siteLang={this.context.state.siteLang} />
                            :
                            <UserChat handleChatBox={this.handleChatBox.bind(this)} state={this.state} locale={this.context.state.siteLang} />
                    }
                    
                </div>
            </div>
        );
    }
}
export default ChatbotComponent;