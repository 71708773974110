import React from 'react'
import '../../reactToastify.css'
import InputComponent from '../utils/inputComponent'
import ErrorComponent from '../utils/errorComponent'
import { ToastContainer, toast } from 'react-toastify'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import CONSTANTS from '../constants/dashboard-constants'
import HappinessMeter from '../../HappinessMeter/HappinessMeter'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'

class MaintenanceService extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            problemsArray: [],
            appointmentsArray: [],
            error: '',
            problemType: '',
            appointmentId: '',
            description: '',
            mobileNo: '',
            images: [],
            files: [],
            errors: ['mobileNo'],
            success: false
        }
        this.getFile = this.getFile.bind(this)
        this.getProblems = this.getProblems.bind(this)
        this.setProblems = this.setProblems.bind(this)
        this.getAppointments = this.getAppointments.bind(this)
        this.setAppointments = this.setAppointments.bind(this)
        this.showError = this.showError.bind(this)
        this.reset = this.reset.bind(this)
        this.removeError = this.removeError.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleMobileNoChange = this.handleMobileNoChange.bind(this)
        this.requestForMaintenence = this.requestForMaintenence.bind(this)
        this.closeCallback = this.closeCallback.bind(this)
        this.successCalback = this.successCalback.bind(this)
        this.failCallback = this.failCallback.bind(this)
        this.removeClick = this.removeClick.bind(this)
    }

    componentDidMount() {
        this.getProblems()
        this.getAppointments()
    }

    getProblems() {
        if (localStorage.getItem('accessToken')) {
            this.reset()
            fetch(CONSTANTS.maintananceProblemsRequestEndPoint + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setProblems).catch(this.loadFailed);
        }
    }

    setProblems(response) {
        if (response.success) {
            var payload = response.payload
            var itemsArray = []
            payload.map((item, index) => {
                return itemsArray.push(<option key={index} value={item.id}>{item.description}</option>)
            })
            if (itemsArray.length > 0)
                this.setState({ problemType: itemsArray[0].props.value, problemsArray: itemsArray })
        }
    }

    getAppointments() {
        if (localStorage.getItem('accessToken')) {
            this.reset()
            fetch(CONSTANTS.maintananceAppointmentsRequestEndPoint + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setAppointments).catch(this.loadFailed);
        }
    }

    setAppointments(response) {
        if (response.success) {
            var payload = response.payload
            var itemsArray = []
            payload.map((item, index) => {
                return itemsArray.push(<option key={index} value={item.id}>{item.date}</option>)
            })
            if (itemsArray.length > 0) {
                this.setState({ appointmentId: itemsArray[0].props.value, appointmentsArray: itemsArray })
            } else {
                this.addError("appointment")
            }
        } else {
            this.addError("appointment")
        }
    }

    handleChange(e) {
        const { name, value } = e.target
        if (name === "appointmentId") {
            this.removeError("appointment")
        }
        this.setState({ [name]: value })
    }

    handleMobileNoChange(e, isValid) {
        this.reset()
        const { name, value } = e.target
        this.setState({ [name]: value })
        if (isValid) {
            this.removeError(name)
        } else {
            this.addError(name)
        }
    }

    requestForMaintenence(e) {
        e.preventDefault();
        this.reset()
        if (this.state.errors.length <= 0) {
            const data = new FormData()
            // var data = new URLSearchParams();
            data.append('mobileNo', this.state.mobileNo);
            data.append('appointmentId', this.state.appointmentId);
            data.append('problemType', this.state.problemType);
            data.append('description', this.state.description);
            data.append('requestImage', this.state.images);
            fetch(CONSTANTS.maintananceRequestEndPoint + "?local=" + this.context.state.siteLang, {
                method: 'POST',
                headers: {
                    'Authorization': CONSTANTS.authToken
                },
                body: data
            }).then(response => response.json()).then(this.successCalback).catch(this.failCallback);
        } else {
            this.showError(translate('requiredErrorMessage'))
        }
    }

    successCalback(response) {
        if (response.success) {
            this.setState({ success: true })
            toast.success(response.message)
        } else {
            toast.error(response.message)
        }
    }

    failCallback(response) {
        toast.error(response.message)
    }

    addError(name) {
        if (this.state.errors.indexOf(name) === -1)
            this.state.errors.push(name)
    }

    removeError(name) {
        this.setState({ errors: this.state.errors.filter(x => x !== name) })
    }

    reset() {
        this.setState({ error: null })
    }

    showError(message) {
        this.setState({ error: <ErrorComponent message={message} closeCalback={this.closeCallback} /> })
    }

    loadFailed(response) {
        this.showError(response.message)
    }

    closeCallback() {
        this.setState({ error: null })
    }

    getFile(e) {
        let filesArr = e.target.files;
        var files = [...this.state.images]
        for (var i = 0; i < filesArr.length; i++) {
            files.push(filesArr[i])
        }
        e.target.value = null
        this.setState({ images: files }, () => { this.setFiles(this.state.images) })
    }

    // getFile(e) {
    //     let file = e.currentTarget.files[0];
    // if (this.isValidFileType(file) && this.isValidFileSize(file)) {
    //     this.uploadDocument(e.currentTarget.id, file)
    // } else {
    //     toast.error(translate('fileSize'))
    // }
    //     e.currentTarget.value = null
    // }

    // isValidFileSize(file) {
    //     return file.size <= 2e6
    // }

    // isValidFileType(file) {
    //     var ext = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);
    //     return ext.toUpperCase() === "PNG" || ext.toUpperCase() === "JPG" || ext.toUpperCase() === "JPEG" || ext.toUpperCase() === "PDF"
    // }

    removeClick(e) {
        var array = [...this.state.images];
        array.splice(e.target.name, 1);
        this.setState({ images: array }, () => { this.setFiles(this.state.images) });
    }

    setFiles(files) {
        var filesArray = []
        files.map((file, index) => { filesArray.push(<FileComponent removeClick={this.removeClick} key={index} index={index} file={file} />) })
        this.setState({ files: filesArray })
    }

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={2000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('MaintenanceRequest')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        {this.state.success ? <HappinessMeter serviceCode='2602' transactionID="" /> : null}
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/ContractorPayment.svg'} alt="Contractor Payment" /></i><span>{translate('MaintenanceRequest')}</span></div>
                        </div>
                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>{translate('Category')}<span className="redStar">*</span></label>
                                        <select name="problemType" className="custom-select" onChange={this.handleChange}>
                                            {this.state.problemsArray}
                                        </select>
                                    </div>
                                    <InputComponent inputProps={{ lable: translate('mobileNumber'), name: 'mobileNo', type: 'phone', changeListener: this.handleMobileNoChange, isRequired: true }} />
                                    <div className="col-md-6">
                                        <label>{translate('AppointmentDate')}<span className="redStar">*</span></label>
                                        <select name="appointmentId" className="custom-select" onChange={this.handleChange}>
                                            {this.state.appointmentsArray}
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <label>{translate('MaintenanceDescription')}</label>
                                        <textarea name='description' onChange={this.handleChange}></textarea>
                                    </div>
                                    <div className="col-md-6">
                                        <br></br>
                                        <ul className="listDot">
                                            <li>{translate('MaintenanceNote1')}</li>
                                            <li>{translate('MaintenanceNote2')}</li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6 uploadInput">
                                        <label>{translate('Documents')}</label>
                                        <div className="uploadInputCustum">
                                            <span>{translate('Selectfiles')}</span>
                                            <input type="file" accept="image/*, application/pdf" multiple onChange={this.getFile}></input>
                                        </div>
                                        <ul>
                                            {this.state.files}
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 formSectionBtn">
                                        <button type="submit" onClick={this.requestForMaintenence}>{translate('submit')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.error}
                </div>
            </div>
        )
    }
}

function FileComponent(props) {
    return (<li>
        <span> {props.file.name} </span>
        <button name={props.index} onClick={props.removeClick}>X</button>
    </li>)
}
export default AuthenticatedComponent(MaintenanceService)