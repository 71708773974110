import React from "react"
import { PieChart, Pie, Cell } from 'recharts'

const RenderProgressbar = (props) => {
  const { cx, cy } = props
  return (
    <g><text fontSize={16} fontFamily="Dubai-Medium" x={cx} y={cy} dy={4} textAnchor="middle" >{(props.value).toFixed(0)}%</text></g>
  )
}
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#F6303F', '#0296CE', '#C224B9', '#C1CF32', '#0068AD', '#F29400'];
const min = 1;
const max = 10;
const getrandom = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const getColor = (data) => {
  if (data[0] > 0 && data[0] <= 30) {
    return 4
  } else if (data[0] > 30 && data[0] <= 50) {
    return 2
  } else if (data[0] > 50 && data[0] <= 70) {
    return 0
  } else if (data[0] > 70) {
    return 1
  } else {
    return 0
  }
}

class CircularProgressChart extends React.Component {

  render() {
    return (
      <PieChart width={100} height={100} >
        <Pie isAnimationActive={false} dataKey={"value"} activeIndex={null} activeShape={RenderProgressbar} data={this.props.data} cx={45} cy={45} innerRadius={40} outerRadius={48} fill={'#f6f6f6'} />

        {this.props.data[0].value > 0 ? <Pie textFill={'#2c3541'} dataKey={"value"} activeIndex={0} cx={45} cy={45} innerRadius={40} outerRadius={48} activeShape={RenderProgressbar} data={this.props.data} />
          : <Pie textFill={'#2c3541'} dataKey={"value"} activeIndex={0} cx={45} cy={45} innerRadius={0} outerRadius={0} activeShape={RenderProgressbar} data={this.props.data} />}

        {this.props.data[0].value > 0 ? <Pie dataKey={"value"} activeIndex={1} data={this.props.data} cx={45} cy={45} innerRadius={40} outerRadius={48}  >
          {
            this.props.data.map((entry, index) => <Cell key={`cell-${index}`} fill={index == 0 ? COLORS[getColor(this.props.data)] : '#f6f6f6'} />)
          }
        </Pie>
          : null}
      </PieChart>
    );
  }
}
export default CircularProgressChart