import ContractorLoginAction from './LoginAction';
import AuthConstants from './AuthConstants';
import request from 'reqwest';
import when from 'when';
import CommonUtils from '../../utils/CommonUtils';
import localIpUrl from 'local-ip-url';
import CryptoJS from "crypto-js";
import IP from "ip";

var apiCallType = '';
class AuthService {
  /** contractor Login via Password */
  /**Proxy Login */
  contractorLoginUsingPassword(username, password, local) {
    console.log(AuthConstants.CONTRACTOR_LOGIN_URL)
    apiCallType = 'password';
    return this.handleResponse(when(request({
      headers: {
        'crn': this.encryptString(username),
        'crp': this.encryptString(password)
      },
      url: AuthConstants.CONTRACTOR_LOGIN_URL + local,
      method: 'POST',
      crossOrigin: true,
      type: 'json'
    })));
  }

  encryptString(value) {
    return CryptoJS.AES.encrypt(JSON.stringify(value), AuthConstants.E_KEY).toString();
  }

  // decryptString(value) {
  //   return JSON.parse(CryptoJS.AES.decrypt(value, secretPass).toString(CryptoJS.enc.Utf8))
  // }

  // /**No proxy Login */
  // contractorLoginUsingPassword(username, password, local) {
  //   apiCallType = 'password';
  //   return this.handleResponse(when(request({
  //     url: AuthConstants.LOGIN_URL + local,
  //     method: 'POST',
  //     crossOrigin: true,
  //     type: 'json',
  //     data: {
  //       client_id: process.env.CLIENT_ID,
  //       grant_type: process.env.GRANT_TYPE,
  //       client_secret: process.env.CLIENT_SECRET,
  //       username: username,
  //       password: password,
  //       name: process.env.NAME,
  //       usertype: process.env.CONTRACTOR_USER_TYPE,
  //       useragent: CommonUtils.USERAGENT(),
  //       ipaddress: localIpUrl('public')
  //     }
  //   })));
  // }

  /** contractor Login via mobilenumber */
  contractorLoginUsingMobileNo(username, mobilenumber, local) {
    apiCallType = 'mobilenumber';
    return this.handleResponse(request({
      url: AuthConstants.CONTRACTOR_LOGIN_OTP_URL + username + "&mobile=" + mobilenumber + "&local=" + local,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: {
        'consumer-key': process.env.CLIENT_ID,
        'consumer-secret': process.env.CLIENT_SECRET
      }
    }));
  }

  /** contractor Login via otp code */
  contractorLoginUsingOtpNo(username, otpcode, local) {
    apiCallType = 'otpcode';
    return this.handleResponse(when(request({
      url: AuthConstants.LOGIN_URL + local,
      method: 'POST',
      crossOrigin: true,
      type: 'json',
      data: {
        client_id: process.env.CLIENT_ID,
        grant_type: process.env.GRANT_TYPE,
        client_secret: process.env.CLIENT_SECRET,
        username: username,
        otpCode: otpcode,
        usertype: process.env.CONTRACTOR_USER_TYPE,
        useragent: CommonUtils.USERAGENT(),
        ipaddress: localIpUrl('public')
      }
    })));
  }

  /** customer Login via username & Password */
  customerLoginUsingPassword(username, password, uaepass_token, local) {
    apiCallType = 'password';
    return this.handleResponse(when(request({
      url: AuthConstants.LOGIN_URL + local,
      method: 'POST',
      crossOrigin: true,
      type: 'json',
      data: {
        client_id: process.env.CLIENT_ID,
        grant_type: process.env.GRANT_TYPE,
        client_secret: process.env.CLIENT_SECRET,
        username: username,
        password: password,
        ipaddress: localIpUrl('public'),
        useragent: CommonUtils.USERAGENT(),
        usertype: process.env.CUSTOMER_USER_TYPE,
        uaepass_token: uaepass_token
      }
    })));
  }

  /** customer Login via emiratesId */
  customerLoginUsingEmiratedId(emiratesId, local) {
    apiCallType = 'emiratedId';
    return this.handleResponse(request({
      url: AuthConstants.CUSTOMER_LOGIN_OTP_URL + emiratesId + "&local=" + local,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: {
        'consumer-key': process.env.CLIENT_ID,
        'consumer-secret': process.env.CLIENT_SECRET
      }
    }));
  }

  /** customer Login via otp code */
  customerLoginUsingOtpNo(emiratesId, otpcode, local) {
    apiCallType = 'otpcode';
    return this.handleResponse(when(request({
      url: AuthConstants.LOGIN_URL + local,
      method: 'POST',
      crossOrigin: true,
      type: 'json',
      data: {
        client_id: process.env.CLIENT_ID,
        grant_type: process.env.GRANT_TYPE,
        client_secret: process.env.CLIENT_SECRET,
        idn: emiratesId,
        otpCode: otpcode,
        usertype: process.env.CUSTOMER_USER_TYPE,
        useragent: CommonUtils.USERAGENT(),
        ipaddress: localIpUrl('public')
      }
    })));
  }

  handleResponse(loginPromise) {
    return loginPromise
      .then(function (response) {
        if (apiCallType == 'password' || apiCallType == 'otpcode') {
          ContractorLoginAction.loginUserUsingPasswordOrOTP(response);
        } else if (apiCallType == 'mobilenumber' || apiCallType == 'emiratedId') {
          ContractorLoginAction.loginUserUsingMobileNoOrEmiratesId(response);
        }
        return true;
      });
  }
}

export default new AuthService();